import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { List, Set } from 'immutable';

import { AppState } from '../../app.reducer';

import { ModelService } from '../models/models.service';

import { NodeStructureAction } from './nodestructures.actions';
import { NodeStructure } from './nodestructures.models';
import * as selectors from './nodestructures.selectors';
import { Model } from '../models';
import { SharedService } from '../shared/shared.service';
import { isNullOrUndefined } from 'util';

@Injectable()
export class NodeStructureService extends SharedService {

  public constructor(protected store: Store<AppState>, protected action: NodeStructureAction, private modelService: ModelService) {
    super(store, action, selectors);
  }

  public findByModelId(id: string) {
    return this.modelService.find(id).filter(model => !!model).mergeMap((model: Model) => {
      let idSet;
      if (isNullOrUndefined(model.relationships.submodels)) {
        idSet = Set([model.id]);
      } else {
        idSet = model.relationships.submodels.map(_ => '' + _).toSet().add(model.id);
      }
      return this.all().map((nodeStructures: List<NodeStructure>) => nodeStructures
        .filter((nodeStructure: NodeStructure) => idSet.has(nodeStructure.relationships.model)).toList());
    });
  }

  public tag(subsetId: string, data: any) {
    this.store.dispatch(this.action.tag(subsetId, data));
  }

  public untag(subsetId: string, data: any) {
    this.store.dispatch(this.action.untag(subsetId, data));
  }

  public loadWithToken(id: string, includes: string[] = [], token: string): void {
    this.store.dispatch(this.action.loadWithToken(id, includes, token));
  }

  public getOnetimeAccessToken(nsId: string | string[], formNodeId?: string, actionNodeId?: string, dueDate?: string) {
    this.store.dispatch(this.action.getOneTimeToken(nsId, formNodeId, actionNodeId, dueDate));
  }

}

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { List } from 'immutable';

import { AppState } from '../../app.reducer';
import { IPayload } from '../shared';

import * as selectors from './humanresources.selectors';
import { HumanResource } from './humanresources.models';
import { HumanResourceAction } from './humanresources.actions';
import { SharedService } from '../shared/shared.service';

@Injectable()
export class HumanResourceService extends SharedService {

  public constructor(protected store: Store<AppState>, protected action: HumanResourceAction) {
    super(store, action, selectors);
  }

  public findByInstanceId(id: string) {
    return this.all().map((humanResources: List<HumanResource>) => humanResources.filter(humanResource => humanResource.relationships.instance === id).toList());
  }

  public createOnInstance(id: string, payload: IPayload | IPayload[]) {
    this.store.dispatch(this.action.create(id, payload));
  }

  public connect(id: string) {
    this.store.dispatch(this.action.connect(id));
  }

  public disconnect(id: string) {
    this.store.dispatch(this.action.disconnect(id));
  }

  public bulkUpdate(payload: IPayload[]) {
    this.store.dispatch(this.action.bulkUpdate(payload))
  }

}

import { Record, Set, Map } from 'immutable';
import { IActivity } from '../connector';
import { DataModelState } from '../shared/common.models';
import { RecordFactory } from '../shared/record-factory';
import { RequestDiffRecord } from '../shared/common.models';

const stateRecord = Record({
  ids: Set<string>(),
  entities: Map<string, Activity>(),
  relationships: Map<string, ActivityRelationships>(),
  diff: new RequestDiffRecord(),
  isBusy: 0
});

export class ActivityState extends stateRecord implements DataModelState {
  ids: Set<string>;
  entities: Map<string, Activity>;
  relationships: Map<string, ActivityRelationships>;
  diff: RequestDiffRecord;
  isBusy: number;
}

const activityRecord = Record({
  id: undefined,
  name: '',
  description: '',
  start: null,
  end: null,
  startActual: undefined,
  endActual: undefined,
  createdAt: null,
  deletedAt: null,
  reference: '',
  crossReference: '',
  type: undefined,
  status: undefined,
  responsibleId: undefined,
  node: null,
  percentageComplete: undefined,
  priority: 0,
  milestone: 0,
  budget: 0,
  budgetPlan: 0,
  benefitBudget: 0,
  benefitActual: 0,
  benefitRemaining: 0,
  costBudget: 0,
  costActual: 0,
  costRemaining: 0,
  investBudget: 0,
  investActual: 0,
  investRemaining: 0,
  riskProfileCategory: undefined,
  riskProfileAssessment: undefined,
  riskProfileCountermeasures: '',
  time: 0,
  timeDescription: '',
  cost: 0,
  costDescription: '',
  quality: 0,
  qualityDescription: '',
  executive: 0,
  executiveDescription: '',
  program: 0,
  programDescription: '',
  functional: 0,
  functionalDescription: '',
  resource: 0,
  resourceDescription: '',
  organizational: 0,
  organizationalDescription: '',
  technical: 0,
  technicalDescription: '',
  updatedAt: undefined,
  duplicate_original_id: undefined,
  sorting: 0,
  nodebucket: false,
  phantom: false,

  float_field1_a: 0,
  float_field2_a: 0,
  float_field3_a: 0,
  float_field4_a: 0,
  float_field5_a: 0,
  float_field6_a: 0,
  float_field7_a: 0,
  float_field8_a: 0,
  float_field9_a: 0,
  float_field10_a: 0,
  float_field11_a: 0,
  float_field12_a: 0,
  float_field13_a: 0,
  float_field14_a: 0,
  float_field15_a: 0,
  float_field1_b: 0,
  float_field2_b: 0,
  float_field3_b: 0,
  float_field4_b: 0,
  float_field5_b: 0,
  float_field6_b: 0,
  float_field7_b: 0,
  float_field8_b: 0,
  float_field9_b: 0,
  float_field10_b: 0,
  float_field11_b: 0,
  float_field12_b: 0,
  float_field13_b: 0,
  float_field14_b: 0,
  float_field15_b: 0,
  float_field1_c: 0,
  float_field2_c: 0,
  float_field3_c: 0,
  float_field4_c: 0,
  float_field5_c: 0,
  float_field6_c: 0,
  float_field7_c: 0,
  float_field8_c: 0,
  float_field9_c: 0,
  float_field10_c: 0,
  float_field11_c: 0,
  float_field12_c: 0,
  float_field13_c: 0,
  float_field14_c: 0,
  float_field15_c: 0,
  float_field1_d: 0,
  float_field2_d: 0,
  float_field3_d: 0,
  float_field4_d: 0,
  float_field5_d: 0,
  float_field6_d: 0,
  float_field7_d: 0,
  float_field8_d: 0,
  float_field9_d: 0,
  float_field10_d: 0,
  float_field11_d: 0,
  float_field12_d: 0,
  float_field13_d: 0,
  float_field14_d: 0,
  float_field15_d: 0,

  relationships: undefined
});

export class Activity extends activityRecord {
  id: string;
  name?: string;
  description?: string;
  start?: string;
  end?: string;
  startActual?: string;
  endActual?: string;
  createdAt?: string;
  deletedAt?: string;
  reference?: string;
  crossReference?: string;
  type?: number;
  status?: number;
  responsibleId?: number;
  node?: any;
  percentageComplete?: number;
  priority?: number;
  milestone?: number;
  budget?: number;
  budgetPlan?: number;
  benefitBudget?: number;
  benefitActual?: number;
  benefitRemaining?: number;
  costBudget?: number;
  costActual?: number;
  costRemaining?: number;
  investBudget?: number;
  investActual?: number;
  investRemaining?: number;
  riskProfileCategory?: number;
  riskProfileAssessment?: number;
  riskProfileCountermeasures?: string;
  time?: number;
  timeDescription?: string;
  cost?: number;
  costDescription?: string;
  quality?: number;
  qualityDescription?: string;
  executive?: number;
  executiveDescription?: string;
  program?: number;
  programDescription?: string;
  functional?: number;
  functionalDescription?: string;
  resource?: number;
  resourceDescription?: string;
  organizational?: number;
  organizationalDescription?: string;
  technical?: number;
  technicalDescription?: string;
  updatedAt?: number;
  duplicate_original_id?: number;
  sorting?: number;
  nodebucket?: boolean;
  phantom: boolean;

  float_field1_a?: number;
  float_field2_a?: number;
  float_field3_a?: number;
  float_field4_a?: number;
  float_field5_a?: number;
  float_field6_a?: number;
  float_field7_a?: number;
  float_field8_a?: number;
  float_field9_a?: number;
  float_field10_a?: number;
  float_field11_a?: number;
  float_field12_a?: number;
  float_field13_a?: number;
  float_field14_a?: number;
  float_field15_a?: number;

  float_field1_b?: number;
  float_field2_b?: number;
  float_field3_b?: number;
  float_field4_b?: number;
  float_field5_b?: number;
  float_field6_b?: number;
  float_field7_b?: number;
  float_field8_b?: number;
  float_field9_b?: number;
  float_field10_b?: number;
  float_field11_b?: number;
  float_field12_b?: number;
  float_field13_b?: number;
  float_field14_b?: number;
  float_field15_b?: number;

  float_field1_c?: number;
  float_field2_c?: number;
  float_field3_c?: number;
  float_field4_c?: number;
  float_field5_c?: number;
  float_field6_c?: number;
  float_field7_c?: number;
  float_field8_c?: number;
  float_field9_c?: number;
  float_field10_c?: number;
  float_field11_c?: number;
  float_field12_c?: number;
  float_field13_c?: number;
  float_field14_c?: number;
  float_field15_c?: number;

  float_field1_d?: number;
  float_field2_d?: number;
  float_field3_d?: number;
  float_field4_d?: number;
  float_field5_d?: number;
  float_field6_d?: number;
  float_field7_d?: number;
  float_field8_d?: number;
  float_field9_d?: number;
  float_field10_d?: number;
  float_field11_d?: number;
  float_field12_d?: number;
  float_field13_d?: number;
  float_field14_d?: number;
  float_field15_d?: number;

  cb_benefit_actual: number;
  cb_benefit_budget: number;
  cb_benefit_remaining: number;
  cb_cost_actual: number;
  cb_cost_budget: number;
  cb_cost_remaining: number;
  cb_invest_actual: number;
  cb_invest_budget: number;
  cb_invest_remaining: number;

  relationships?: ActivityRelationships;

  static fromJSONAPI(activity: IActivity) {
    return RecordFactory.entity.fromJSONAPI<Activity>(Activity, activity);
  }
}

const relationshipsRecord = Record({
  instances: null,
  nodedata: null,
  humanresources: null
});

export class ActivityRelationships extends relationshipsRecord {
  instances: string;
  nodedata: string;
  humanresources: string;

  static fromJSONAPI(activity: IActivity) {
    return RecordFactory.relationships.fromJSONAPI<ActivityRelationships>(ActivityRelationships, activity);
  }
}

import { NODES_TYPE_COLUMN } from './../../shared/api/nodes/nodes.models';
import { isArray, isNullOrUndefined, isNumber, isUndefined } from 'util';
import { List, Map, OrderedMap, Set } from 'immutable';
import { Injectable } from '@angular/core';
import { UUID } from 'angular2-uuid';
import * as moment from 'moment';

import { LocalStorageService } from '../../shared/services/local-storage-service';
import { Businessarea } from '../../shared/api/businessareas';
import { Model } from '../../shared/api/models';
import { HumanResource } from '../../shared/api/humanresources';
import { Node } from '../../shared/api/nodes';
import { Relationship } from '../../shared/api/relationships';
import { Activity } from '../../shared/api/activities';
import {
  CoreFilter,
  CoreMultiTransfer, CoreOptions, CoreTransfer,
  TreeActivity,
  TreeNode
} from '../interface/core.interface';
import { TreeRelationship } from '../interface/core.interface';
import { IPayload } from '../../services/payload/payload.interface';
import { FormEntry, FormResult } from '../../components/form/interface/form.interface';
import { Datum } from '../../shared/utilities/datum';
import { Group } from '../../shared/api/groups';
import { CoreService } from '../service/core.service';
import { CoreTransformer } from '../transformer/core.transformer';
import { StackNode } from '../../components/horizontal/interface/horizontal.interface';
import {
  NODES_TYPE_CHILD, NODES_TYPE_DATASOURCE, NODES_TYPE_DIRECT_CHAIN, NODES_TYPE_FILTERS,
  NODES_TYPE_NODETYPEGROUP,
  NODES_TYPE_PARENT, NODES_TYPE_VALUE
} from '../../shared/api/nodes/nodes.models';

@Injectable()
export class CoreUtilities {

  public coreService: CoreService;
  public coreTransformer: CoreTransformer;

  public constructor(private localStorageService: LocalStorageService) {}

  /**
   * Save data on local storage
   * @param key
   * @param value
   */
  public saveLocal(key: string, value: any): Promise<boolean> {
    return this.localStorageService.set(key, value);
  }

  /**
   * Load data from local storage
   * @param key
   * @param element
   */
  public loadLocal(key: string, element: any) {
    const _element = this.localStorageService.get(key);
    return !isNullOrUndefined(_element) ? _element : element;
  }

  /**
   * Has local
   * @param key
   */
  public hasLocal(key: string) {
    return !isNullOrUndefined(this.localStorageService.get(key));
  }

  /**
   * Clear data from local storage
   * @param keys
   */
  public clearLocal(...keys: Array<string>) {
    this.localStorageService.remove(...keys);
  }

  /**
   * Get the checksum of an element
   * @param element
   */
  public getCheckSum(element: Businessarea | Model | HumanResource | Node | Relationship | Activity | TreeNode | TreeRelationship | Group): string {
    return element.id + ':' + element.updatedAt;
  }

  public getOptionsCheckSum(key: string, options: CoreOptions): string {
    const checksum = [key];
    if (options.ignoreMCM) {
      checksum.push('ignoreMCM');
    }
    if (options.onlyMCM) {
      checksum.push('onlyMCM');
    }
    if (options.ignoreBusinessArea) {
      checksum.push('ignoreBusinessArea');
    }
    if (options.ignoreGlobalFilter) {
      checksum.push('ignoreGlobalFilter');
    }
    const count = options.filters.length;
    for (let i = 0; i < count; i++) {
      const filter = options.filters[i];
      checksum.push(filter.by + '-' + filter.value);
    }
    return checksum.join(':');
  }

  /**
   * Toggle an element in an array
   * @param array
   * @param value
   * @param removeTrigger
   */
  public toggleInArray(array: any[], value: any, removeTrigger?: any) {
    let set = Set<any>(array);
    if ((isNullOrUndefined(removeTrigger) && set.has(value)) || removeTrigger === true) {
      set = set.remove(value);
    } else {
      set = set.add(value);
    }
    return set.toArray();
  }

  /**
   * Get the values of a form group as map
   * @param values
   * @param entriesMap
   * @param result
   * @param dataSourceMap
   */
  public getFormGroupValuesMap(values: any, entriesMap: Map<string, FormEntry>, result = Map<string, any>(), dataSourceMap = Map<string, Map<string, any>>()): { result: Map<string, any>, dataSourceMap: Map<string, Map<string, any>> } {
    Map<string, any>(values).forEach((entry, key) => {
      if (!isNullOrUndefined(key)) {
        if (key.indexOf('header') > -1 || key.indexOf('group') > -1 || key.indexOf('unused') > -1) {
          if (entry !== '') {
            const _result = this.getFormGroupValuesMap(entry, entriesMap, result);
            result = _result.result;
            dataSourceMap = _result.dataSourceMap;
          }
        } else if (!isNullOrUndefined(key) && key !== '') {
          const formEntry = entriesMap.get(key);
          if (!isNullOrUndefined(formEntry)) {
            const dataSource = formEntry.entryNode.children.filter(child => child.nodeType === NODES_TYPE_DATASOURCE)[0];
            if (!isNullOrUndefined(dataSource) && dataSource.unfilteredChildren.length > 0) {
              const source = dataSource.unfilteredChildren.filter(child => child.id)[0];
              if (!isNullOrUndefined(source) && source.nodeType !== NODES_TYPE_PARENT && source.nodeType !== NODES_TYPE_CHILD) {
                const sourceId = source.id;
                dataSourceMap = dataSourceMap.set(sourceId, (dataSourceMap.has(sourceId) ? dataSourceMap.get(sourceId) : Map<string, any>()).set(key, entry));
                return;
              }
            }
          }
          if (isNullOrUndefined(formEntry) && (key.indexOf('-additional') !== -1 || key.indexOf('-additional-detail') !== -1 || key.indexOf('-in-budget') !== -1 || key.indexOf('-entry') !== -1)) {
            result = result.set(key, entry);
            if (key.indexOf('-in-budget') !== -1) {
              result = result.set(key.replace('-in-budget', ''), entry);
            }
          } else if (!isNullOrUndefined(formEntry)) {
            if (formEntry.controlType === 'dropdown' && entry === '') {
              entry = 0;
            }
            if (!isNullOrUndefined(formEntry.entryNode.fieldConversion) && formEntry.entryNode.fieldConversion !== '') {
              entry = this.coreTransformer.invertConvertValue(formEntry.entryNode.fieldConversion, parseFloat(entry));
            }
            if (!result.has(key + '-in-budget')) {
              result = result.set(key, entry);
            }
          }
        }
      }
    });
    return { result, dataSourceMap };
  }

  public mapFormResult(formResult: FormResult): FormResult {
    let delta = Map<string, any>();
    formResult.delta.forEach((v, k) => {
      delta = delta.set(k.split(':')[0], v);
    });
    formResult.delta = delta;
    return formResult;
  }

  /**
   * Get the delta between two elements
   * @param newElement
   * @param oldElement
   * @param flatten
   */
  public getDelta(newElement: any, oldElement: any, flatten = true): Map<string, any> {
    newElement = (newElement instanceof Map) ? newElement : Map<string, any>(newElement);
    oldElement = (oldElement instanceof Map) ? oldElement : Map<string, any>(oldElement);
    let delta = Map<string, any>();
    newElement.forEach((v, k) => {
      const ok = '' + k;
      k = k.split(':')[0];
      if (( !oldElement.has(k) || oldElement.get(k) !== v) && !isUndefined(v)) {
        if (k === 'children' && (isNullOrUndefined(v) || v === 'null' || oldElement.get('childIds').map(c => '' + c).indexOf(v) !== -1)) {
        } else if (k === 'parents' && (isNullOrUndefined(v) || v === 'null' || oldElement.get('parentIds').map(p => '' + p).indexOf(v) !== -1)) {
        } else if (k === 'relationships' && flatten) {
          delta = delta.merge(this.getDelta(newElement.get(k), oldElement.get(k), false));
        } else if (v === '' && oldElement.get(k) === null) {
        } else {
          delta = delta.set(ok, v);
        }
      }
    });
    return delta;
  }

  /**
   * Sort tree nodes by nodeType
   * @param types
   * @param treeNodes
   */
  public sortTreeNodesByTypes(types: number[], treeNodes: TreeNode[]): TreeNode[] {
    let typeMap = Map<number, TreeNode[]>();
    let unusedTypes = Set<number>();
    const count = treeNodes.length;
    for (let i = 0; i < count; i++) {
      const treeNode = treeNodes[i];
      if (types.indexOf(treeNode.nodeType) === -1) {
        unusedTypes = unusedTypes.add(treeNode.nodeType);
      }
      const array = (typeMap.has(treeNode.nodeType) ? typeMap.get(treeNode.nodeType) : []);
      array.push(treeNode);
      typeMap = typeMap.set(treeNode.nodeType, array);
    }
    types = types.concat(unusedTypes.toArray());
    return this.flatArray(types.map(num => typeMap.get(num)).filter(num => !!num));
  }

  /**
   * Flatten array
   * @param arr
   * @param result
   */
  public flatArray(arr: any[], result = []) {
    if (!isArray(arr)) {
      return arr;
    }
    arr.forEach(_ => {
      if (isArray(_)) {
        result = this.flatArray(_, result);
      } else {
        result.push(_);
      }
    });
    return result;
  }

  /**
   * Unescape a string the HTML way
   * @param escapedString
   */
  public unescapeHtml(escapedString: string): string {
    const doc = new DOMParser().parseFromString(escapedString, 'text/html');
    return doc.documentElement.textContent;
  }

  public makeImmutable(element: any): any {
    return Object.assign({}, element);
  }

  public deepImmutable(treeNodes: TreeNode[], processed = Map<string, TreeNode>()) {
    return { treeNodes: treeNodes.map(treeNode => {
        /* If the element has already being processed */
        if (processed.has(treeNode.id)) {
          return processed.get(treeNode.id);
        }
        /* Make element immutable */
        treeNode = <TreeNode> Map(treeNode).toJS();
        processed = processed.set(treeNode.id, treeNode);

        /* Iterate over children */
        const children = this.deepImmutable(treeNode.children, processed);
        treeNode.children = children.treeNodes;
        processed = children.processed;

        /* Iterate over unfiltered children */
        const unfilteredChildren = this.deepImmutable(treeNode.unfilteredChildren, processed);
        treeNode.unfilteredChildren = unfilteredChildren.treeNodes;
        processed = unfilteredChildren.processed;

        return treeNode;

      }), processed: processed };
  }


  /**
   * Convert an immutable list to an ordered map
   * @param list
   * @param id
   * @param transformer
   */
  public convertListToOrderedMap(list: List<any>, transformer?: Function, id = 'id'): OrderedMap<string, any> {
    let map = OrderedMap<string, any>();
    list.forEach(a => map = map.set(a[id], (!!transformer ? transformer(a) : a)));
    return map;
  }

  /**
   * Flatten a form group
   * @param values
   * @param result
   */
  public flatFormGroup(values: Map<string, any>, result = Map<string, any>()): Map<string, any> {
    values.forEach((entry, key) => {
      if (key.indexOf('header') > -1 || key.indexOf('group') > -1 || key.indexOf('unused') > -1) {
        if (entry !== '') {
          result = this.flatFormGroup(Map<string, any>(entry), result);
        }
      } else {
        result = result.set(key, entry);
      }
    });
    return result;
  }

  /**
   * Get a randomly generated hex color code
   */
  public randomHexGenerator(): string {
    let hex = Math.floor(Math.random() * 16777215).toString(16);
    const count = hex.length;
    for (let i = 0; i < 6 - count; i++) {
      hex += 'f';
    }
    return '#' + hex;
  }

  /**
   * Get the opposite color
   * @param hex
   * @param blackAndWhite
   */
  public invertColor(hex: string, blackAndWhite = false) {
    if (hex.indexOf('#') === 0) {
      hex = hex.slice(1);
    }
    if (hex.length === 3) {
      hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    if (hex.length !== 6) {
      hex = '000000';
    }
    let r: string | number = parseInt(hex.slice(0, 2), 16);
    let g: string | number = parseInt(hex.slice(2, 4), 16);
    let b: string | number = parseInt(hex.slice(4, 6), 16);
    if (blackAndWhite) {
      return (r * 0.299 + g * 0.587 + b * 0.114) > 186 ? '#000000' : '#FFFFFF';
    }
    r = (255 - r).toString(16);
    g = (255 - g).toString(16);
    b = (255 - b).toString(16);
    return '#' + this.padZero(r) + this.padZero(g) + this.padZero(b);
  }

  /**
   * Get the opposite color key
   * @param hex
   */
  public getInvertedColorKey(hex: string) {
    if (isNullOrUndefined(hex)) {
      return '#575761';
    }
    if (hex.indexOf('#') === 0) {
      hex = hex.slice(1);
    }
    if (hex.length === 3) {
      hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    if (hex.length !== 6) {
      throw new Error('Invalid HEX color.');
    }
    const r: string | number = parseInt(hex.slice(0, 2), 16);
    const g: string | number = parseInt(hex.slice(2, 4), 16);
    const b: string | number = parseInt(hex.slice(4, 6), 16);
    return (r * 0.299 + g * 0.587 + b * 0.114) > 186 ? '#575761' : 'white';
  }

  /**
   * Random RGB generator
   */
  public randomRGBGenerator() {
    const o = Math.round, r = Math.random, s = 255;
    return 'rgb(' + o(r() * s) + ',' + o(r() * s) + ',' + o(r() * s) + ')';
  }

  /**
   * Random unique RGB generator
   * @param unique
   */
  public randomUniqueRGBGenerator(unique = []) {
    const rgb = this.randomRGBGenerator();
    return unique.indexOf(rgb) === -1 ? rgb : this.randomUniqueRGBGenerator(unique);
  }

  /**
   * Sort versions
   * @param elementA
   * @param elementB
   */
  public sortVersions(elementA: string, elementB: string) {
    const splitA = elementA.split('.').map(a => parseInt(a));
    const splitB = elementB.split('.').map(b => parseInt(b));

    const count = Math.max(splitA.length, splitB.length);
    for (let i = 0; i < count; i++) {
      const a = splitA[i];
      const b = splitB[i];
      if (a === undefined || a === null) {
        return -1;
      }
      if (b === undefined || b === null) {
        return 1;
      }
      if (a !== b) {
        return a - b;
      }
    }
    return 0;
  }

  /**
   * Sort function
   * @param a
   * @param b
   * @param insensitive
   */
  public sort (a, b, insensitive = true) {
    return isNullOrUndefined(a) || isNullOrUndefined(a.localeCompare) ? 0 : a.localeCompare(b);
  }

  /**
   * Pad zero helper
   * @param str
   * @param len
   */
  public padZero(str: string, len?: number) {
    len = len || 2;
    const zeros = [len].join('0');
    return (zeros + str).slice(-len);
  }

  /**
   * Filter and sort a node by node type array
   * @param nodeTypes
   * @param treeNodes
   * @param parentNode
   * @param nodeTypePlacement
   * @param childField
   * @param nodeTypeNodes
   */
  public filterAndSortTreeNodesByNodeTypeArray(nodeTypes: number[], treeNodes: TreeNode[], parentNode?: TreeNode, nodeTypePlacement = 0, childField = 'unfilteredChildren', nodeTypeNodes?: TreeNode[], childStoreField = 'children') {
    let filteredNodes = [];
    if (nodeTypes[nodeTypePlacement] === NODES_TYPE_COLUMN && !isNullOrUndefined(nodeTypeNodes)) {
      const columnNodeTypeNodes = nodeTypeNodes[nodeTypePlacement].unfilteredChildren;
      const count = columnNodeTypeNodes.length;
      for (let i = 0; i < count; i++) {
        const columnNodeTypeNode = columnNodeTypeNodes[i];
        let filteredTreeNodes = this.filterAndSortTreeNodesByNodeType(columnNodeTypeNode.nodeType, treeNodes);
        const restriction = columnNodeTypeNode.unfilteredChildren.filter(c => c.nodeType === NODES_TYPE_FILTERS)[0];
        if (!isNullOrUndefined(restriction)) {
          filteredTreeNodes = this.filterBy(filteredTreeNodes, this.getFiltersBy(restriction.children, []))
        }
        filteredNodes = filteredNodes.concat(filteredTreeNodes);
      }
    } else {
      filteredNodes = this.filterAndSortTreeNodesByNodeType(nodeTypes[nodeTypePlacement], treeNodes);
    }
    const count = filteredNodes.length;
    for (let i = 0; i < count; i++) {
      const filteredNode: TreeNode = filteredNodes[i];
      const children = isNullOrUndefined(filteredNode[childField]) ? filteredNode.unfilteredChildren : filteredNode[childField];
      filteredNode[childStoreField] = this.filterAndSortTreeNodesByNodeTypeArray(nodeTypes, children, filteredNode, nodeTypePlacement + 1, childField, nodeTypeNodes);
    }
    return filteredNodes;
  }

  /**
   * Filter and sort a node by node type
   * @param nodeType
   * @param treeNodes
   * @param nodeTypeNode
   */
  public filterAndSortTreeNodesByNodeType(nodeType: number, treeNodes: TreeNode[]) {
    const filteredNodes: TreeNode[] = [];
    const count = treeNodes.length;
    for (let i = 0; i < count; i++) {
      const treeNode = treeNodes[i];
      if (treeNode.nodeType === nodeType) {
        filteredNodes.push(treeNode);
      }
    }
    filteredNodes.sort((a, b) => new Datum(a.startDate).timestamp - new Datum(b.startDate).timestamp || this.sort(a.name, b.name));
    return filteredNodes;
  }

  /**
   * Flatten the hierarchy
   * @param treeNodes
   * @param subLevel
   * @param result
   */
  public flatHierarchy(treeNodes: TreeNode[], subLevel = 0, result: TreeNode[] = []): TreeNode[] {
    if (isNullOrUndefined(treeNodes)) {
      return result;
    }
    const count = treeNodes.length;
    for (let i = 0; i < count; i++) {
      const treeNode = treeNodes[i];
      treeNode.subLevel = subLevel;
      result.push(treeNode);
      result = this.flatHierarchy(treeNode.children, subLevel + 1, result);
    }
    return result;
  }

  /**
   * Get the children of a tree node
   * @param treeNodes
   * @param filtered
   * @param original
   * @param withParents
   * @param result
   * @param ids
   */
  public getChildren(treeNodes: TreeNode[], filtered = false, withParents = false, original = false, result: TreeNode[] = [], ids = []): TreeNode[] {
    if (isNullOrUndefined(treeNodes)||treeNodes.length===0) {
      return result;
    }
    const count = treeNodes.length;
    for (let i = 0; i < count; i++) {
      const treeNode = treeNodes[i];
      if (!isNullOrUndefined(treeNode) && ids.indexOf(treeNode.id) === -1) {
        if (isNullOrUndefined(treeNode.visible) || treeNode.visible['global'] || !filtered ) {
          ids.push(treeNode.id);
          result.push(treeNode);
        }
        if ( (!filtered && !isNullOrUndefined(treeNode.unfilteredChildren) || (filtered && !isNullOrUndefined(treeNode.children)))) {
          result = this.getChildren(original && !isNullOrUndefined(treeNode.originalChildren) ? treeNode.originalChildren : (filtered ? treeNode.children : treeNode.unfilteredChildren), filtered, withParents, original, result, ids);
        }
        if ( withParents && (!filtered && !isNullOrUndefined(treeNode.unfilteredParents) || (filtered && !isNullOrUndefined(treeNode.parents)))) {
          result = this.getParents(original && !isNullOrUndefined(treeNode.originalParents) ? treeNode.originalParents : (filtered ? treeNode.parents : treeNode.unfilteredParents), filtered, original, result, ids);
        }
      }
    }
    return result;
  }

  /**
   * Get the children of a tree node
   * @param treeNodes
   * @param idKey
   * @param filtered
   * @param ids
   */
  public getChildrenIds(treeNodes: TreeNode[], idKey = 'id', filtered = false, ids = []): string[] {
    if (isNullOrUndefined(treeNodes)) {
      return ids;
    }
    const count = treeNodes.length;
    for (let i = 0; i < count; i++) {
      const treeNode = treeNodes[i];
      if (ids.indexOf(treeNode[idKey]) === -1) {
        ids.push(treeNode[idKey]);
        if ( (!filtered && !isNullOrUndefined(treeNode.unfilteredChildren) || (filtered && !isNullOrUndefined(treeNode.children)))) {
          ids = this.getChildrenIds(filtered ? treeNode.children : treeNode.unfilteredChildren, idKey, filtered, ids);
        }
      }
    }
    return ids;
  }
  
  /**
   * Get the parents of a tree node
   * @param treeNodes
   * @param filtered
   * @param original
   * @param result
   * @param ids
   * @param filter
   */
  public getParents(treeNodes: TreeNode[], filtered = false, original = false, result: TreeNode[] = [], ids = [], filter?: string[]): TreeNode[] {
    if (isNullOrUndefined(treeNodes)) {
      return result;
    }
    const count = treeNodes.length;
    for (let i = 0; i < count; i++) {
      const treeNode = treeNodes[i];
      if (ids.indexOf(treeNode.id) === -1) {
        ids.push(treeNode.id);
        if (filter === undefined || filter.indexOf('' + treeNode.id) !== -1) {
          result.push(treeNode);
          const parents = original && !isNullOrUndefined(treeNode.originalParents) ? treeNode.originalParents : (filtered ? treeNode.parents : treeNode.unfilteredParents);
          if (!isNullOrUndefined(parents)) {
            result = this.getParents(parents, filtered, original, result, ids, filter);
          }
        }
      }
    }
    return result;
  }

  public anyParentByFilter(treeNode: TreeNode, filters: CoreFilter[]): boolean {
    /* Check if node is visible */
    const visible = this.coreService.isNodeVisible(treeNode, filters);
    if (visible) {
      return true;
    }
    /* If node is not visible check if any parent is visible */
    return treeNode.parents.filter(parentTreeNode => this.anyParentByFilter(parentTreeNode, filters)).length > 0;
  }

  public getHierarchyByNodeTypes(treeNodes: TreeNode[], unfilteredNodeTypes: number[], nodeTypes: number[], nodeTypePosition = 0): TreeNode[] {
    const result = [];
    const count = treeNodes.length;
    for (let i = 0; i < count; i++) {
      const treeNode = treeNodes[i];
      /* Check if tree node is allowed */
      const visible = nodeTypes.indexOf(treeNode.nodeType) !== -1;
      /* If the node is visible add to children */
      if (visible) {
        /* Remove parent */
        treeNode.parents = treeNode.parents.filter(parent => this.anyParentByFilter(parent, [{ by: 'nodeType', value: nodeTypes }]));
        /* Attach to result */
        result.push(treeNode);
      }
    }
    return result;
  }

  public getTransferFromTreeNode(treeNode: TreeNode, modelId?: string, parentNode?: TreeNode): CoreTransfer {
    const transfer = <CoreTransfer> { nodes: [treeNode], relationships: [], activities: [] };
    if (!isNullOrUndefined(modelId)) {
      transfer.modelId = modelId;
    }
    if (!isNullOrUndefined(parentNode) && parentNode.id !== '0') {
      (<TreeRelationship[]> transfer.relationships).push(<TreeRelationship> { id: UUID.UUID(), parentId: parentNode.id, childId: treeNode.id, weight: 1 });
    }
    if (!isNullOrUndefined(treeNode['dynamic-parents'])) {
      for (let key in treeNode['dynamic-parents']) {
        const data = treeNode['dynamic-parents'][key];
        const count = data.selected.length;
        for (let i = 0; i < count; i++) {
          const selected = data.selected[i];
          (<TreeRelationship[]> transfer.relationships).push(<TreeRelationship> { id: UUID.UUID(), parentId: selected.id, childId: treeNode.id, weight: 1 });
        }
      }
    }
    if (!isNullOrUndefined(treeNode['dynamic-children'])) {
      for (let key in treeNode['dynamic-children']) {
        const data = treeNode['dynamic-children'][key];
        const count = data.selected.length;
        for (let i = 0; i < count; i++) {
          const selected = data.selected[i];
          (<TreeRelationship[]> transfer.relationships).push(<TreeRelationship> { id: UUID.UUID(), childId: selected.id, parentId: treeNode.id, weight: 1 });
        }
      }
    }
    return transfer;
  }

  /**
   * Merge two transfers
   * @param transfer1
   * @param transfer2
   */
  public mergeTransfers(transfer1: CoreMultiTransfer, transfer2: CoreMultiTransfer) {
    /* Map update */
    const updateNodes = this.arrayToMap(transfer1.update.nodes, Map<string, IPayload>());
    const updateNodeStructures = this.arrayToMap(!isNullOrUndefined(transfer1.update.nodeStructures) ? transfer1.update.nodeStructures : [], Map<string, IPayload>());
    const updateRelationships = this.arrayToMap(transfer1.update.relationships, Map<string, IPayload>());
    const updateActivities = this.arrayToMap(transfer1.update.activities, Map<string, IPayload>());
    /* Map create */
    const createNodes = this.arrayToMap(transfer1.create.nodes, Map<string, IPayload>());
    const createRelationships = this.arrayToMap(transfer1.create.relationships, Map<string, IPayload>());
    const createActivities = this.arrayToMap(transfer1.create.activities, Map<string, IPayload>());

    /* Update */
    transfer1.update.nodes = this.mergePayloadsMapWithPayload(<IPayload[]> transfer2.update.nodes, updateNodes).toArray();
    transfer1.update.nodeStructures = this.mergePayloadsMapWithPayload(<IPayload[]> transfer2.update.nodeStructures, updateNodeStructures).toArray();
    transfer1.update.relationships = this.mergePayloadsMapWithPayload(<IPayload[]> transfer2.update.relationships, updateRelationships).toArray();
    transfer1.update.activities = this.mergePayloadsMapWithPayload(<IPayload[]> transfer2.update.activities, updateActivities).toArray();
    /* Create */
    transfer1.create.nodes = this.mergePayloadsMapWithPayload(<IPayload[]> transfer2.create.nodes, createNodes).toArray();
    transfer1.create.relationships = this.mergePayloadsMapWithPayload(<IPayload[]> transfer2.create.relationships, createRelationships).toArray();
    transfer1.create.activities = this.mergePayloadsMapWithPayload(<IPayload[]> transfer2.create.activities, createActivities).toArray();
    /* Delete */
    transfer1.delete.nodes = (<TreeNode[]> transfer1.delete.nodes).concat((<TreeNode[]> transfer2.delete.nodes));
    transfer1.delete.relationships = (<TreeNode[]> transfer1.delete.relationships).concat((<TreeNode[]> transfer2.delete.relationships));
    transfer1.delete.activities = (<TreeNode[]> transfer1.delete.activities).concat((<TreeNode[]> transfer2.delete.activities));
    /* Return */
    return transfer1;
  }

  /**
   * Concat two transfers
   * @param transfer1
   * @param transfer2
   */
  public concatTransfers(transfer1: CoreMultiTransfer, transfer2: CoreMultiTransfer) {
    transfer1.create.nodes = (<TreeNode[]> transfer1.create.nodes).concat((<TreeNode[]> transfer2.create.nodes));
    transfer1.update.nodes = (<TreeNode[]> transfer1.update.nodes).concat((<TreeNode[]> transfer2.update.nodes));
    transfer1.delete.nodes = (<TreeNode[]> transfer1.delete.nodes).concat((<TreeNode[]> transfer2.delete.nodes));

    transfer1.create.relationships = (<TreeRelationship[]> transfer1.create.relationships).concat((<TreeNode[]> transfer2.create.relationships));
    transfer1.update.relationships = (<TreeRelationship[]> transfer1.update.relationships).concat((<TreeNode[]> transfer2.update.relationships));
    transfer1.delete.relationships = (<TreeRelationship[]> transfer1.delete.relationships).concat((<TreeNode[]> transfer2.delete.relationships));

    return transfer1;
  }

  /**
   * Convert array to map
   * @param a
   * @param m
   */
  public arrayToMap(a: any[], m: Map<any, any>) {
    const count = a.length;
    for (let i = 0; i < count; i++) {
      const e = a[i];
      m = m.set(e.id, e);
    }
    return m;
  }

  /**
   * Merge payloads map
   * @param payloads
   * @param map
   */
  public mergePayloadsMapWithPayload(payloads: IPayload[], map: Map<string, IPayload>): Map<string, IPayload> {
    if (!isNullOrUndefined(payloads)) {
      const count = payloads.length;
      for (let i = 0; i < count; i++) {
        const payload = payloads[i];
        if (!map.has(payload.id)) {
          map = map.set(payload.id, payload);
        } else {
          const _payload = map.get(payload.id);
          _payload.data = _payload.data.merge(payload.data);
          map = map.set(_payload.id, _payload);
        }
      }
    }
    return map;
  }

  /**
   * Insert a token at a specific position
   * @param arr
   * @param token
   * @param n
   * @param m
   * @param fromEnd
   */
  public insertTokenEveryN(arr: any[], token: any, n: number, m: number, fromEnd: boolean) {
    const a = arr.slice(0);
    const count = a.length + 1;
    let idx = fromEnd ? count - n : n;
    while ((fromEnd ? idx >= 1 : idx <= count)) {
      for (let i = 0; i < m; i++) {
        a.splice(idx, 0, token);
      }
      idx = (fromEnd  ? idx - n : idx + n + 1);
    }
    return a;
  }

  /**
   * Insert a token at every pace
   * @param arr
   * @param token
   * @param pace
   * @param tokenCount
   */
  public insertTokensEveryPace(arr: any[], token: any, pace: number, tokenCount: number) {
    const inputArray = [...arr];
    const outputArray = [];
    for (let i = 0; i < inputArray.length; i += pace) {
      outputArray.push(inputArray[i]);
      for (let j = 0; j < tokenCount; j++) {
        outputArray.push(token);
      }
    }
    return outputArray;
  }

  /**
   * Insert tokens based on children
   * @param treeNodes
   * @param token
   * @param maxCount
   * @param stackNode
   * @param nodeTypes
   */
  public insertTokenByChildren(treeNodes: TreeNode[], token: any, maxCount: number, stackNode?: StackNode, nodeTypes?: Set<number>) {
    /* Clone array */
    treeNodes = [...treeNodes];
    /* First run to figure out the proportions */
    let children = Map<string, number>();
    const count = treeNodes.length;
    let childrenCount = 0;
    if (isNullOrUndefined(stackNode) || !stackNode.lastAnchor) {
      for (let i = 0; i < count; i++) {
        const treeNode = treeNodes[i];
        const originalArray  = (!isNullOrUndefined(treeNode.unfilteredChildren) && treeNode.unfilteredChildren.length > 0) ? [...treeNode.unfilteredChildren] : [];
        let uniqueArray = Map<string, TreeNode>();
        originalArray.forEach(elem => {
          if (!uniqueArray.has(elem.id)) {
            uniqueArray = uniqueArray.set(elem.id, elem);
          }
        });
        if (!isNullOrUndefined(nodeTypes)) {
          uniqueArray = <Map<string, TreeNode>> uniqueArray.filter(n => nodeTypes.has(n.nodeType));
        }
        const childrenLength = isNullOrUndefined(treeNode.unfilteredChildren) ? 0 : uniqueArray.count();
        childrenCount += childrenLength;
        children = children.set(treeNode.id, childrenLength);
      }
    } else if (!isNullOrUndefined(stackNode) && !isNullOrUndefined(stackNode.dataTargetCount)) {
      childrenCount = stackNode.dataTargetCount * count;
      for (let i = 0; i < count; i++) {
        const treeNode = treeNodes[i];
        children = children.set(treeNode.id, stackNode.dataTargetCount);
      }
    }
    /* Now calculate the percentage */
    const percentage = childrenCount === 0 ? 0 : (maxCount - count) / childrenCount;
    let unequal = count * Math.round(percentage) + childrenCount > maxCount;

    /* Re iterate over tree nodes to set the token */
    if (percentage > 0) {
      for (let i = count; i >= 0; i--) {
        const treeNode = treeNodes[i];
        if (!isNullOrUndefined(treeNode)) {
          const childCount = children.get(treeNode.id);
          let tokensCount = unequal ? Math.floor(percentage * childCount) : Math.round(percentage * childCount);
          /* Test */
          const sub = (treeNodes.length + tokensCount) - maxCount;
          if (sub > 0) {
            tokensCount = tokensCount - sub;
          }
          unequal = false;
          for (let i2 = 0; i2 < tokensCount; i2++) {
            treeNodes.splice(i + 1, 0, token);
          }
        }
      }
    }
    return treeNodes;
  }

  /**
   * Count nulls in array
   * @param array
   * @param start
   * @param result
   */
  public countNulls(array: any[], start: number, result = 0) {
    const count = array.length;
    for (let i = start; i < count; i++) {
      if (array[i] === null) {
        result++;
      } else {
        break;
      }
    }
    return result;
  }

  /**
   * Unify array
   * @param array
   * @param identifier
   */
  public unique(array: any[], identifier = 'id') {
    const ids = [];
    const result = [];
    const count = array.length;
    for (let i = 0; i < count; i++) {
      const item = array[i];
      const id = isNullOrUndefined(identifier) ? item : item[identifier];
      if (ids.indexOf(id) === -1) {
        result.push(item);
        ids.push(id);
      }
    }
    return result;
  }

  /**
   * Find attribute in element chain
   * @param element
   * @param attribute
   * @param allowEmpty
   */
  public findAttribute(element: any, attribute: string, allowEmpty = true) {
    if (!isNullOrUndefined(element.hasAttribute) && element.hasAttribute(attribute) && (allowEmpty || !allowEmpty && element.getAttribute(attribute) !== '')) {
      return element.getAttribute(attribute);
    } else if (isNullOrUndefined(element.parentNode)) {
      return null;
    } else {
      return this.findAttribute(element.parentNode, attribute, allowEmpty);
    }
  }

  /**
   * Generate text icon
   * @param text
   * @param size
   */
  public generateTextIcon(text: string, size: number) {
    text = this.getCapitalLetters(text);
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    context.font = size + 'px Arial';
    const measure = context.measureText(text);
    context.canvas.width = measure.width;
    context.canvas.height = size - 7;
    context.font = size + 'px Arial';
    context.fillText(text, 0, (size / 2) + 7);
    // const image = document.createElement('img');
    return canvas.toDataURL();
  }

  /**
   * Set the first letter of a word into capital letters
   * @param text
   */
  public getCapitalLetters(text: string) {
    let words = text.split(' ');
    if (words.length === 1) {
      words = text.slice(0, 2).split('');
    }
    return words.map(word => word.slice(0, 1).toUpperCase()).slice(0, 3).join('');
  }

  /**
   * Remove the stored versions from models
   * @param models
   */
  public removeVersionsFromModels(models: Model[]) {
    let ids = Set<string>();
    const count = models.length;
    for (let i = 0; i < count; i++) {
      const model = models[i];
      ids = ids.add(model.id);
    }
    return models.filter(model => !ids.has('' + model.duplicate_original_id));
  }

  public sortByHierarchy(treeNodes: TreeNode[]): TreeNode[] {
    /* Get the top most nodes first and sort them by x position */
    const tm = treeNodes.filter(treeNode => treeNode.parents.length === 0).sort((a, b) => a.positionX - b.positionX);
    /* Now deep iterate over tree and add the children to result */
    return this.getChildren(tm);
  }

  public sortByHierarchyFiltered(treeNodes: TreeNode[]): TreeNode[] {
    /* Get the top most nodes first and sort them by x position */
    const tm = treeNodes.filter(treeNode => treeNode.parents.length === 0).sort((a, b) => a.positionX - b.positionX);
    /* Now deep iterate over tree and add the children to result */
    return this.getChildren(tm, true);
  }

  public sortDeepByX(treeNodes: TreeNode[]): TreeNode[] {
    return treeNodes.sort((a, b) => a.positionX - b.positionX).map(treeNode => {
      if (!isNullOrUndefined(treeNode.children) && treeNode.children.length > 0) {
        treeNode.children = this.sortDeepByX(treeNode.children);
      }
      return treeNode;
    });
  }

  public sortDeepByName(treeNodes: TreeNode[]): TreeNode[] {
    return treeNodes.sort((a, b) => this.sort(a.name, b.name)).map(treeNode => {
      if (!isNullOrUndefined(treeNode) && !isNullOrUndefined(treeNode.children) && treeNode.children.length > 0) {
        treeNode.children = this.sortDeepByName(treeNode.children);
      }
      return treeNode;
    });
  }

  public validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  public drawConfirmation(yes: string, no: string) {
    return '<div class="buttons buttons-confirmation"><div class="btn-toolbar justify-content-between" role="toolbar">' +
      '<button type="button" rel="cancel" class="button button-two">' + no + '</button>' +
      '<button type="submit" rel="submit" [disabled]="!formGroup.valid" class="button button-one">' + yes + '</button>' +
      '</div></div>';
  }

  public getDirectChain(treeNodes: TreeNode[], filtered = false, onlyIds = false): { nodes: TreeNode[], ids: string[] } {
    const ids = [];
    const result = [];
    const parents = this.getParents(treeNodes, filtered);
    let count = parents.length;
    for (let i = 0; i < count; i++) {
      const parent = parents[i];
      if (ids.indexOf(parent.id) === -1) {
        result.push(parent);
        ids.push(parent.id);
      }
    }
    const children = this.getChildren(treeNodes, filtered);
    count = children.length;
    for (let i = 0; i < count; i++) {
      const child = children[i];
      if (ids.indexOf(child.id) === -1) {
        result.push(child);
        ids.push(child.id);
      }
    }
    return { nodes: result, ids: ids };
  }

  public getDirectChainDown(treeNodes: TreeNode[], filtered = false, onlyIds = false): { nodes: TreeNode[], ids: string[] } {
    const ids = [];
    const result = [];
    const children = this.getChildren(treeNodes, filtered);
    const count = children.length;
    for (let i = 0; i < count; i++) {
      const child = children[i];
      if (ids.indexOf(child.id) === -1) {
        result.push(child);
        ids.push(child.id);
      }
    }
    return { nodes: result, ids: ids };
  }

  public getDirectChainUp(treeNodes: TreeNode[], filtered = false, onlyIds = false): { nodes: TreeNode[], ids: string[] } {
    const ids = [];
    const result = [];
    const parents = this.getParents(treeNodes, filtered);
    const count = parents.length;
    for (let i = 0; i < count; i++) {
      const parent = parents[i];
      if (ids.indexOf(parent.id) === -1) {
        result.push(parent);
        ids.push(parent.id);
      }
    }
    return { nodes: result, ids: ids };
  }

  public getDirectChainEdge(treeNodes: TreeNode[], filtered = false, onlyIds = false): { nodes: TreeNode[], ids: string[] } {
    /* First get all nodes downwards */
    const down = this.getDirectChainDown(treeNodes, filtered);
    /* Now all up of the downwards */
    const downUp = this.getDirectChainUp(down.nodes, filtered);
    /* Now get all nodes upwards */
    const up = this.getDirectChainUp(treeNodes, filtered);
    /* Now all up of the downwards */
    const upDown = this.getDirectChainDown(up.nodes, filtered);
    /* Merge nodes */
    const mergedNodes = down.nodes.concat(downUp.nodes.concat(up.nodes.concat(upDown.nodes)));
    const mergedIds = down.ids.concat(downUp.ids.concat(up.ids.concat(upDown.ids)));
    return { nodes: this.unique(mergedNodes), ids: this.unique(mergedIds, null) };
  }

  public getNodeTypes(treeNode: TreeNode) {
    let nodeTypes = Set<number>();
    if (!isNullOrUndefined(treeNode) && !isNullOrUndefined(treeNode.children)) {
      const count = treeNode.children.length;
      for (let i = 0; i < count; i++) {
        const node = treeNode.children[i];
        nodeTypes = nodeTypes.add(node.nodeType);
      }
    }
    return nodeTypes.toArray();
  }

  public getFilterKey(filters: CoreFilter[]) {
    return filters.map(filter => filter.by + '-' + filter.value).join(';');
  }

  public getParentTreeNodeFromBucket(treeNode: TreeNode) {
    if (!treeNode.formBucket || treeNode.formFieldEditable) {
      return treeNode;
    }
    const parent = treeNode.parents.filter(p => !p.formBucket)[0];
    return !isNullOrUndefined(parent) ? parent : treeNode;
  }

  public setSubLevel(treeNodes: (TreeNode | TreeActivity)[], ids: string[]) {
    if (treeNodes.length === 0 || treeNodes[0].internalType === 'treeActivity') {
      return treeNodes;
    }
    treeNodes = (<TreeNode[]> treeNodes).map(treeNode => {
      treeNode.subLevel = this.getSubLevel(treeNode, ids);
      return treeNode;
    });

    return treeNodes;
  }

  public getSubLevel(treeNode: TreeNode, ids: string[], subLevel = 0) {
    const parents = isNullOrUndefined(treeNode.parents) ? [] : treeNode.parents.filter(parent => ids.indexOf(parent.id) !== -1);
    const count = parents.length;
    const __subLevel = subLevel + 1;
    for (let i = 0; i < count; i++) {
      const _subLevel = this.getSubLevel(parents[i], ids, __subLevel);
      if (_subLevel > subLevel) {
        subLevel = _subLevel;
      }
    }
    return subLevel;
  }

  public shiftMap(map: OrderedMap<number, any>, position: number, shifts: number) {
    let result = OrderedMap<number, any>();
    map.filter((v, k) => k >= position).forEach((v, k) => {
      result = result.set(k + shifts, v);
    });
    return result;
  }

  public getParentChildPairs(treeNodes: TreeNode[], parent?: TreeNode, pairs = Map<number, number[]>()): Map<number, number[]> {
    const count = treeNodes.length;
    for (let i = 0; i < count; i++) {
      const treeNode = treeNodes[i];
      if (!isNullOrUndefined(parent)) {
        pairs = pairs.set(parent.nodeType, List(pairs.has(parent.nodeType) ? pairs.get(parent.nodeType) : []).push(treeNode.nodeType).toArray());
      }
      pairs = this.getParentChildPairs(treeNode.children, treeNode, pairs);
    }
    return pairs;
  }

  public getNumberWithOrdinal(n) {
    const s = ['th', 'st', 'nd', 'rd'], v = n % 100;
    return n + (s[(v - 20) % 10] || s[v] || s[0]);
  }

  public getDateKey(datum: Datum, key: string) {
    switch (key) {
      case 'day':
        return { key: datum.toDateString(), label: datum.toEuropeanDateString() };
      case 'week':
        return { key: datum.week + '-' + datum.year, label: 'KW ' + datum.week };
      case 'month':
        return { key: datum.month + '-' + datum.year, label: datum.getMonthText() };
      case 'quarter':
        return { key: datum.quarter + '-' + datum.year, label: 'Q' + datum.quarter };
      case 'year':
        return { key: '' + datum.year, label: '' + datum.year };
    }
  }

  public getVirtualRelationships(treeNode: TreeNode, nodeTypes: number[], child?: TreeNode, relationships = []): TreeRelationship[] {
    const count = treeNode.parents.length;
    for (let i = 0; i < count; i++) {
      const parent = treeNode.parents[i];
      const position = nodeTypes.indexOf(parent.nodeType);
      if (position > nodeTypes.indexOf(treeNode.nodeType)) {
        child = !!child ? child : treeNode;
        relationships.push(<TreeRelationship> { id: UUID.UUID(), category: 0, condition: 0, parentId: parent.id, childId: child.id, weight: 1, internalType: 'treeRelationship', phantom: false, source: parent.id, target: child.id, type: 0 });
      } else {
        relationships = this.getVirtualRelationships(parent, nodeTypes, treeNode, relationships);
      }
    }
    return relationships;
  }

  /**
   * Get coordinates relative to body
   * @param elem
   */
  public getCoords(elem) {
    const box = elem.getBoundingClientRect();
    const width = box.width;
    const body = document.body;
    const docEl = document.documentElement;
    const scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
    const scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft;
    const clientTop = docEl.clientTop || body.clientTop || 0;
    const clientLeft = docEl.clientLeft || body.clientLeft || 0;
    const top  = box.top +  scrollTop - clientTop;
    const left = box.left + scrollLeft - clientLeft;
    return { top: Math.round(top), left: Math.round(left), width: Math.round(width) };
  }

  /**
   * Flag tree nodes
   * @param treeNodes
   * @param flag
   * @param map
   */
  public flagTreeNodesByHierarchy(treeNodes: TreeNode[], flag = '', map = Map<string, TreeNode>()) {
    treeNodes = treeNodes.map(treeNode => {
      treeNode.flag = flag + (flag === '' ? '' : '-') + treeNode.id;
      map = map.set(treeNode.flag, treeNode);
      const children = this.flagTreeNodesByHierarchy(treeNode.children, treeNode.flag, map);
      treeNode.children = children.treeNodes;
      map = children.map;
      return treeNode;
    })
    return { treeNodes: treeNodes, map: map };
  }

  /**
   * Get relationship id
   * @param treeRelationship
   */
  public getRelationshipId(treeRelationship: TreeRelationship) {
    return treeRelationship.parentId + '-' + treeRelationship.childId;
  }

  /**
   * Get map of relationships with relationship id
   * @param treeRelationships
   */
  public getRelationshipIdMap(treeRelationships: TreeRelationship[]) {
    let result = Map<string, TreeRelationship>();
    const count = treeRelationships.length;
    for (let i = 0; i < count; i++) {
      const treeRelationship = treeRelationships[i];
      result = result.set(this.getRelationshipId(treeRelationship), treeRelationship);
    }
    return result;
  }

  /**
   * Map children deep
   * @param callback
   * @param treeNodes
   * @param immutable
   */
  public mapChildrenDeep(callback: Function, treeNodes: TreeNode[], immutable = false): any[] {
    if (isNullOrUndefined(treeNodes)) {
      return treeNodes;
    }
    return treeNodes.map(treeNode => {
      treeNode = callback(immutable ? Map(treeNode).toJS() : treeNode);
      treeNode.children = this.mapChildrenDeep(callback, treeNode.children, immutable);
      return treeNode;
    });
  }

  /**
   * Merge arrays
   * @param array1
   * @param array2
   * @param remove
   */
  public mergeArrays(array1: TreeNode[], array2: TreeNode[], remove = false) {
    let arrayMap = OrderedMap<string, TreeNode>();
    let count = array1.length;
    for (let i = 0; i < count; i++) {
      const a1 = array1[i];
      arrayMap = arrayMap.set(a1.id, a1);
    }
    count = array2.length;
    for (let i = 0; i < count; i++) {
      const a2 = array2[i];
      if (remove) {
        arrayMap = arrayMap.remove(a2.id);
      } else {
        arrayMap = arrayMap.set(a2.id, a2);
      }
    }
    return arrayMap.toArray();
  }

  /**
   * Set children by node type hierarchy
   * @param treeNodes
   * @param nodeTypeHierarchy
   */
  public setChildrenByNodeTypeHierarchy(treeNodes: TreeNode[], nodeTypeHierarchy: Map<number, number[]>) {
    const count = treeNodes.length;
    for (let i = 0; i < count; i++) {
      const treeNode = treeNodes[i];
      if (nodeTypeHierarchy.has(treeNode.nodeType)) {
        const nodeTypes = nodeTypeHierarchy.get(treeNode.nodeType);
        treeNode.children = treeNode.unfilteredChildren.map(child => this.setChildrenByNodeTypeHierarchyAction(child, nodeTypes))
      }
    }
    return treeNodes;
  }

  private setChildrenByNodeTypeHierarchyAction(treeNode: TreeNode, nodeTypes: number[]) {
    const nodeType = nodeTypes.shift();
    if (nodeTypes.length > 0) {
      treeNode.children = this.getChildren(treeNode.children).filter(child => child.nodeType === nodeType).map(child => {
        child.children = child.unfilteredChildren.map(c => this.setChildrenByNodeTypeHierarchyAction(c, nodeTypes));
        return child;
      });
    }
    return treeNode;
  }

  public getNodesSkipLevel(level: string, treeNodes: TreeNode[]): TreeNode[] {
    let result = Map<string, TreeNode>();
    const count = treeNodes.length;
    for (let i = 0; i < count; i++) {
      const treeNode = treeNodes[i];
      const count2 = treeNode[level].length;
      for (let i2 = 0; i2 < count2; i2++) {
        const levelNode = treeNode[level][i2];
        result = result.set(levelNode.id, levelNode);
      }
    }
    return result.toArray();
  }

  public getTreeByTreeStructure(structureTreeNode: TreeNode, treeNodes: TreeNode[], direction = 'unfilteredChildren', flat = false) {
    let result: TreeNode[] = [];
    if (isNullOrUndefined(structureTreeNode)) {
      return result;
    }
    const next = structureTreeNode.children[0];
    switch (structureTreeNode.nodeType) {
      case NODES_TYPE_NODETYPEGROUP:
        /* The start */
        if (!isNullOrUndefined(next)) {
          result = this.getTreeByTreeStructure(next, treeNodes, direction, flat)
        }
        break;
      case NODES_TYPE_PARENT:
      case NODES_TYPE_CHILD:
        if (!isNullOrUndefined(next)) {
          direction = structureTreeNode.nodeType === NODES_TYPE_PARENT ? 'unfilteredParents' : 'unfilteredChildren';
          result = result.concat(this.getTreeByTreeStructure(next, direction === 'unfilteredChildren' ? this.getChildren(this.getNodesSkipLevel('unfilteredChildren', treeNodes)) : this.getParents(this.getNodesSkipLevel('unfilteredParents', treeNodes)), direction, flat));
        }
        break;

      default:
        const filteredTreeNodes = treeNodes.filter(treeNode => (structureTreeNode.fullscreen) ? (treeNode.children.length === 0 && treeNode.nodeType === structureTreeNode.nodeType) : treeNode.nodeType === structureTreeNode.nodeType);
        if (structureTreeNode.hideWidget) {
          result = result.concat(this.getTreeByTreeStructure(next, filteredTreeNodes, direction, flat));
        } else {
          if (flat) {
            result = result.concat(filteredTreeNodes);
            if (!isNullOrUndefined(next)) {
              result = result.concat(this.getTreeByTreeStructure(next, treeNodes, direction, flat));
            }
          } else {
            result = result.concat(filteredTreeNodes.map(treeNode => {
              let _treeNodes = !isNullOrUndefined(next) ? this.getTreeByTreeStructure(next, treeNode[direction === 'unfilteredChildren' ? 'children' : 'parents'], direction, flat) : [];
              return ((!isNullOrUndefined(next)) ? Map(treeNode).set((direction === 'unfilteredChildren' ? 'children' : 'parents'), _treeNodes).set((direction === 'unfilteredChildren' ? 'parents' : 'children'), []) : Map(treeNode).set('children', []).set('parents', [])).toJS();
            }));
          }
        }
    }
    return result;
  }

  /**
   * Get filters
   * @private
   */
  public getFiltersBy(byNodes: TreeNode[], filters: CoreFilter[], sourceNodeType?: number) {
    const byCount = byNodes.length;
    for (let i = 0; i < byCount; i++) {
      const byNode = byNodes[i];
      let by = byNode.formFieldId;
      if (by === '') {
        by = byNode.name;
      }
      let values = Set();
      const valueNodes = byNode.children.filter(child => (child.nodeType === NODES_TYPE_VALUE || by === 'id' || by === 'dataId' || by === 'parent' || by === 'child') && child.nodeType !== NODES_TYPE_DIRECT_CHAIN);
      const directChainNode = byNode.children.filter(child => child.nodeType === NODES_TYPE_DIRECT_CHAIN)[0];
      const valueCount = valueNodes.length;
      for (let j = 0; j < valueCount; j++) {
        const valueNode = valueNodes[j];
        let value = valueNode[by];
        if (by === 'parent' || by === 'child') {
          value = valueNode.dataId;
        }
        if (by === 'dateRange') {
          const from = !isNullOrUndefined(valueNode.startDate) ? 'startDate' : 'actualStartDate';
          const to = !isNullOrUndefined(valueNode.targetDate) ? 'targetDate' : 'actualDate';
          values = values.add({ from: from, fromValue: valueNode[from], to: to, toValue: valueNode[to] });
        } else {
          /* Check if it's a default value */
          if (new Node()[by] === value && valueNode.formId !== '') {
            value = valueNode.formId;
          }
          if (!isNullOrUndefined(value)) {
            values = values.add(value);
          }
        }
      }
      filters.push(<CoreFilter> { by: by, value: values.toArray(), nodeType: sourceNodeType, directChain: directChainNode });
    }
    return filters;
  }

  public filterBy(treeNodes: TreeNode[], filters: CoreFilter[], filtered = true, turned = false) {
    const count = filters.length;
    return treeNodes.filter(treeNode => {
      let visible = true;
      for (let i = 0; i < count; i++) {
        const filter = filters[i];
        switch (filter.by) {
          case 'dataId':
            if (!isNullOrUndefined(filter.directChain)) {
              visible = this.getDirectChain([treeNode], filtered, false).nodes.filter(node => filter.value.indexOf(node.dataId) !== -1).length > 0;
            } else {
              visible = treeNode[filtered ? 'parents' : 'unfilteredParents'].filter(node => filter.value.indexOf(node.dataId) !== -1).length > 0;
            }
            break;
          case 'dateRange':
            const data: { from: string, fromValue: string, to: string, toValue: string } = filter.value[0];
            if (isNullOrUndefined(data)) {
              visible = true;
              break;
            }
            const filterFrom = isNullOrUndefined(data.fromValue) ? data.fromValue : moment(data.fromValue).unix();
            const filterTo = isNullOrUndefined(data.toValue) ? data.toValue : moment(data.toValue).unix();
            if (isNullOrUndefined(filterFrom) && isNullOrUndefined(filterTo)) {
              visible = true;
              break;
            }
            let from = treeNode[data.from];
            if (!isNullOrUndefined(from)) {
              from = moment(from).unix();
            }
            let to = treeNode[data.to];
            if (!isNullOrUndefined(to)) {
              to = moment(to).unix();
            }
            const fromVisible = !isNullOrUndefined(filterTo) && !isNullOrUndefined(from) && from <= filterTo;
            const toVisible = !isNullOrUndefined(filterFrom) && !isNullOrUndefined(to) && to >= filterFrom;
            visible = fromVisible && toVisible;
            break;
          case 'targetDate':
            const targetDate = filter.value[0].split(' ');
            visible = moment().add(targetDate[0], targetDate[1]).isAfter(isNullOrUndefined(treeNode.targetDate) ? moment() : moment(treeNode.targetDate));
            break;
          default:
            visible = filter.value.indexOf(treeNode[filter.by]) !== -1;
            break;
        }
        if (visible === false) {
          break;
        }
      }
      return turned ? !visible : visible;
    });
  }

  public restrict(treeNodes: TreeNode[], restrictionNode: TreeNode, filtered = true) {
    let filters: CoreFilter[] = [];
    const count = restrictionNode.children.length;
    for (let i = 0; i < count; i++) {
      const { by, values } = this.getRestrictions(restrictionNode.children[i]);
      filters.push({ by: by, value: values });
    }
    return this.filterBy(treeNodes, filters, filtered);
  }

  public getRestrictions(byNode: TreeNode) {
    let by = byNode.formFieldId;
    if (by === '') {
      by = byNode.name;
    }
    const values = [];
    const valueNodes = byNode.children.filter(child => (child.nodeType === NODES_TYPE_VALUE || by === 'id' || by === 'dataId' || by === 'parent' || by === 'child') && child.nodeType !== NODES_TYPE_DIRECT_CHAIN);
    const valueCount = valueNodes.length;
    for (let j = 0; j < valueCount; j++) {
      const valueNode = valueNodes[j];
      let value = valueNode[by];
      if (by === 'parent' || by === 'child') {
        value = valueNode.dataId;
      }
      if (by === 'dateRange') {
        const from = !isNullOrUndefined(valueNode.startDate) ? 'startDate' : 'actualStartDate';
        const to = !isNullOrUndefined(valueNode.targetDate) ? 'targetDate' : 'actualDate';
        value = { from: from, fromValue: valueNode[from], to: to, toValue: valueNode[to] };
      }
      /* Check if it's a default value */
      if (new Node()[by] === value && valueNode.formId !== '') {
        value = valueNode.formId;
      }
      if (!isNullOrUndefined(value)) {
        values.push(value);
      }
    }
    return { by, values };
  }

  public addChildTo(id: string, child: TreeNode, treeNodes: TreeNode[]) {
    return treeNodes.map(treeNode => {
      if (treeNode.id === id) {
        if (treeNode.unfilteredChildren.filter(c => c.id === child.id).length === 0) {
          treeNode.unfilteredChildren.push(child);
        }
      } else {
        treeNode.children = this.addChildTo(id, child, treeNode.children);
        treeNode.unfilteredChildren = this.addChildTo(id, child, treeNode.unfilteredChildren);
      }
      return treeNode;
    });
  }

}

import { JSONAPIResourceObject } from '../connector';
import { Set } from 'immutable';
import { APIResourceObject } from '../connector/jsonapi/jsonapi-resource-object.model';
import { isNullOrUndefined } from 'util';

class EntityRecordFactory {

  static fromJSONAPI<T>(entity: { new(data: any): T ; }, data: JSONAPIResourceObject) {
    if (isNullOrUndefined(data)) {
      return new entity({ id: null });
    }
    return new entity(Object.assign({}, {id: data.id}, data.attributes));
  }

  static fromAPI<T>(entity: any, data: APIResourceObject) {
    return new entity(Object.assign({}, { id: data.id, dataId: data.dataId, origin: data.origin }, data.data));
  }
}

class RelationshipsRecordFactory {

  static fromJSONAPI<T>(entity: { new(data: any): T ; }, data: JSONAPIResourceObject) {
    let parsed = {};
    if (!isNullOrUndefined(data) && data.relationships) {
      parsed = Object.keys(data.relationships).reduce((relationships, relationshipName) => {
        if (!isNullOrUndefined(data.relationships[relationshipName])) {
          if (Array.isArray(data.relationships[relationshipName].data)) {
            relationships[relationshipName] = Set<string>(data.relationships[relationshipName].data.map(entry => entry.id));
          } else if (data.relationships[relationshipName].data && data.relationships[relationshipName].data.id) {
            relationships[relationshipName] = data.relationships[relationshipName].data.id;
          }
        }
        return relationships;
      }, {});
    }

    return new entity(parsed);
  }

  static fromAPI<T>(relationship: any, data: APIResourceObject) {
    return new relationship(data.relationships);
  }
}

const RecordFactory = {
  entity: EntityRecordFactory,
  relationships: RelationshipsRecordFactory
};

export { RecordFactory };

import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { GoAPI, INodeStructure, JSONAPIResponse } from '../connector';
import { OneTimeTokenAction } from './onetimetoken.actions';
import { Action } from '../shared/common.action';
import { Observable } from 'rxjs/Observable';
import { CommonEffects } from '../shared';

@Injectable()
export class OneTimeTokenEffects {

  @Effect() fetchByToken$ = this.actions$
    .pipe(ofType(OneTimeTokenAction.LOAD_BY_TOKEN))
    .mergeMap((action: Action) => this.api.byToken(action.token).findAllWithToken(action.token)
      .mergeMap((response: JSONAPIResponse<INodeStructure[]>) => {
        return this.commonEffects.getObservables(this.oneTimeTokenAction.loadSuccess, ['nodestructures', 'nodedata', 'relationships'], response);
      })
    ).catch((error: any) => Observable.of(this.oneTimeTokenAction.loadFail(error)));

  @Effect() updateByToken$ = this.actions$
    .pipe(ofType(OneTimeTokenAction.UPDATE_BY_TOKEN))
    .mergeMap((action: Action) => this.api.byToken(action.token).updateByToken(action.token, action.payload)
      .mergeMap((response: JSONAPIResponse<INodeStructure[]>) => Observable.from([
        this.oneTimeTokenAction.updateByTokenSuccess(response.included)
      ]))
    ).catch((error: any) => Observable.of(this.oneTimeTokenAction.updateByTokenFail(error)));

  constructor(private api: GoAPI,
              private actions$: Actions,
              private commonEffects: CommonEffects,
              private oneTimeTokenAction: OneTimeTokenAction) {}
}

import { Injectable } from '@angular/core';
import { INodeStructure } from '../connector';

import { IPayload } from '../shared/common.models';
import { IAction, IFailAction, ISuccessAction } from '../shared/common.interfaces';
import { isArray } from 'util';
import { Action } from '../shared/common.action';

@Injectable()
export class NodeStructureAction {
  static key = 'NodeStructures';

  static LOAD = `[${NodeStructureAction.key}] Load`;
  static LOAD_SUCCESS = `[${NodeStructureAction.key}] Load success`;
  static LOAD_FAIL = `[${NodeStructureAction.key}] Load fail`;
  static LOAD_WITH_TOKEN = `[${NodeStructureAction.key}] Load with token`;

  static CREATE = `[${NodeStructureAction.key}] Create`;
  static CREATE_GO = `[${NodeStructureAction.key}] Create Go`;
  static CREATE_SUCCESS = `[${NodeStructureAction.key}] Create success`;
  static CREATE_FAIL = `[${NodeStructureAction.key}] Create Fail`;

  static REMOVE = `[${NodeStructureAction.key}] Remove`;
  static REMOVE_GO = `[${NodeStructureAction.key}] Remove Go`;
  static REMOVE_SUCCESS = `[${NodeStructureAction.key}] Remove success`;
  static REMOVE_FAIL = `[${NodeStructureAction.key}] Remove fail`;

  static UPDATE = `[${NodeStructureAction.key}] Update`;
  static UPDATE_GO = `[${NodeStructureAction.key}] Update Go`;
  static MASS = `[${NodeStructureAction.key}] Mass`;
  static UPDATE_SUCCESS = `[${NodeStructureAction.key}] Update success`;
  static UPDATE_FAIL = `[${NodeStructureAction.key}] Update fail`;

  static TAG = `[${NodeStructureAction.key}] Tag`;
  static UNTAG = `[${NodeStructureAction.key}] Untag`;

  static REASSIGN = `[${NodeStructureAction.key}] Reassign`;
  static REASSIGN_SUCCESS = `[${NodeStructureAction.key}] Reassign success`;
  static REASSIGN_FAIL = `[${NodeStructureAction.key}] Reassign fail`;

  static GET_TOKEN = `[${NodeStructureAction.key}] Get One time access Token`;

  public load(id: string): IAction {
    return {
      type: NodeStructureAction.LOAD,
      payload: {
        id: id
      }
    };
  }

  public loadSuccess(response: any): ISuccessAction {
    if (!Array.isArray(response)) {
      response = [response];
    }
    return {
      type: NodeStructureAction.LOAD_SUCCESS,
      payload: {
        request: null,
        response: response
      }
    };
  }

  public loadFail(error: any): IFailAction {
    return {
      type: NodeStructureAction.LOAD_FAIL,
      payload: {
        request: null,
        response: error
      }
    };
  }

  public create(id: string, data: IPayload | IPayload[]): IAction {
    if (!Array.isArray(data)) {
      data = [data];
    }
    return {
      type: NodeStructureAction.CREATE,
      payload: {
        id: id,
        data: data
      }
    };
  }

  public createGo(id: string, data: IPayload | IPayload[]): IAction {
    if (!Array.isArray(data)) {
      data = [data];
    }
    const z = JSON.stringify(data);
    let outputData = JSON.parse(z);
    outputData = outputData.map(i => {
      i.data.level = Number(i.data.level);
      return i;
    });

    return {
      type: NodeStructureAction.CREATE_GO,
      payload: {
        id: id,
        data: outputData
      }
    };
  }

  public createSuccess(payload: IPayload, response: INodeStructure[]): ISuccessAction {
    return {
      type: NodeStructureAction.CREATE_SUCCESS,
      payload: {
        request: payload,
        response: response
      }
    };
  }

  public createFail(payload: IPayload, error: any): IFailAction {
    return {
      type: NodeStructureAction.CREATE_FAIL,
      payload: {
        request: payload,
        response: error
      }
    };
  }

  public update(data: IPayload | IPayload[]): IAction {
    if (!Array.isArray(data)) {
      data = [data];
    }
    return {
      type: NodeStructureAction.UPDATE,
      payload: {
        data: data
      }
    };
  }

  public updateGo(data: IPayload | IPayload[]): IAction {
    if (!Array.isArray(data)) {
      data = [data];
    }
    const z = JSON.stringify(data);
    let outputData = JSON.parse(z);
    outputData = outputData.map(i => {
      if (i.data.level) {
        i.data.level = Number(i.data.level);
      }
      return i;
    });

    return {
      type: NodeStructureAction.UPDATE_GO,
      payload: {
        data: outputData
      }
    };
  }

  public updateSuccess(payload: IPayload, response: INodeStructure[]): ISuccessAction {
    return {
      type: NodeStructureAction.UPDATE_SUCCESS,
      payload: {
        request: payload,
        response: response
      }
    };
  }

  public updateFail(payload: IPayload, error: any): IFailAction {
    return {
      type: NodeStructureAction.UPDATE_FAIL,
      payload: {
        request: payload,
        response: error
      }
    };
  }

  public remove(ids: string | string[]): IAction {
    if (!Array.isArray(ids)) {
      ids = [ids];
    }
    return {
      type: NodeStructureAction.REMOVE,
      payload: {
        data: ids
      }
    };
  }

  public removeGo(ids: string | string[]): IAction {
    if (!Array.isArray(ids)) {
      ids = [ids];
    }
    return {
      type: NodeStructureAction.REMOVE_GO,
      payload: {
        data: ids
      }
    };
  }

  public removeSuccess(payload: IPayload, response: INodeStructure[]): ISuccessAction {
    return {
      type: NodeStructureAction.REMOVE_SUCCESS,
      payload: {
        request: payload,
        response: response
      }
    };
  }

  public removeFail(payload: IPayload, error: any): IFailAction {
    return {
      type: NodeStructureAction.REMOVE_FAIL,
      payload: {
        request: payload,
        response: error
      }
    };
  }

  public tag(subsetId: string, data: IPayload | IPayload[]) {
    if (!Array.isArray(data)) {
      data = [data];
    }
    return {
      type: NodeStructureAction.TAG,
      payload: {
        subset: subsetId,
        data: data
      }
    };
  }

  public untag(subsetId: string, data: IPayload | IPayload[]) {
    if (!Array.isArray(data)) {
      data = [data];
    }
    return {
      type: NodeStructureAction.UNTAG,
      payload: {
        subset: subsetId,
        data: data
      }
    };
  }

  public reassign(data: IPayload[]) {
    return {
      type: NodeStructureAction.REASSIGN,
      payload: data
    };
  }

  public reassignSuccess(payload: IPayload, response): ISuccessAction {
    return {
      type: NodeStructureAction.REASSIGN_SUCCESS,
      payload: {
        request: payload,
        response: response
      }
    };
  }

  public reassignFail(payload: IPayload, error: any): IFailAction {
    return {
      type: NodeStructureAction.REASSIGN_FAIL,
      payload: {
        request: payload,
        response: error
      }
    };
  }

  public autorefreshUpdate(data: any) {
    return {
      type: NodeStructureAction.UPDATE_SUCCESS,
      payload: data
    };
  }

  public autorefreshCreate(data: any) {
    return {
      type: NodeStructureAction.CREATE_SUCCESS,
      payload: data
    };
  }

  public autorefreshRemove(data: any) {
    return {
      type: NodeStructureAction.REMOVE_SUCCESS,
      payload: data
    };
  }

  public loadWithToken(id: string, includes: string[] = [], token: string): Action {
    return {
      type: NodeStructureAction.LOAD_WITH_TOKEN,
      token: token,
      payload: {
        id: id,
        includes: includes
      }
    };
  }

  public getOneTimeToken(id: string | string[], formNodeId?: string, actionNodeId?: string, dueDate?: string): Action {
    return {
      type: NodeStructureAction.GET_TOKEN,
      payload: {
        id: id,
        formNodeId: formNodeId,
        actionNodeId: actionNodeId,
        dueDate: dueDate
      }
    };
  }

}

import { Map, Set } from 'immutable';
import { isNullOrUndefined } from 'util';
import { Injectable } from '@angular/core';

import { Node, NodeCreate } from '../../shared/api/nodes';
import { Activity } from '../../shared/api/activities';
import { CoreAudit, CoreGroup, CoreHumanResource, CoreModel, CoreNodeTypes, CoreUser, TreeActivity } from '../interface/core.interface';
import { Relationship, RelationshipCreate } from '../../shared/api/relationships';
import { TreeRelationship } from '../interface/core.interface';
import { TreeNode } from '../interface/core.interface';
import { Hierarchy } from '../../shared/api/hierarchy';
import { CoreAction, CoreInstance, CoreModule } from '../interface/core.interface';
import { CoreUtilities } from '../utilities/core.utilities';
import { NodeGrouping, NODES_TYPE_CCM, NODES_TYPE_CWM, NODES_TYPE_DEFAULT, NODES_TYPE_GROUP, NODES_TYPE_HUMANRESOURCE, NODES_TYPE_LIBRARY, NODES_TYPE_MCM, NODES_TYPE_PROJECTPORTFOLIO, NODES_TYPE_SCM, NODES_TYPE_STRATEGYMAP, NODES_TYPE_UCIM } from '../../shared/api/nodes/nodes.models';
import {
  Model,
  MODEL_TYPE_CCM,
  MODEL_TYPE_CWM,
  MODEL_TYPE_DEFAULT,
  MODEL_TYPE_LIBRARY,
  MODEL_TYPE_MCM,
  MODEL_TYPE_PROJECTPORTFOLIO,
  MODEL_TYPE_SCM,
  MODEL_TYPE_STRATEGYMAP,
  MODEL_TYPE_UCIM,
  ModelRelationships
} from '../../shared/api/models/models.models';
import { HumanResource } from '../../shared/api/humanresources';
import { Group } from '../../shared/api/groups';
import { PermissionsInstance } from '../../widgets/permissions/interface/permissions.widget.interface';
import { User } from '../../shared/api/user';
import { environment } from '../../../environments/environment';
import { Datum } from '../../shared/utilities/datum';
import { Audit } from '../../shared/api/audits';
import { AppGlobal } from '../../app.global';
import { Businessarea, BusinessareaRelationships, NodeData, NodeStructure } from '../../shared';
import { HumanResourceRelationships } from '../../shared/api/humanresources/humanresources.models';
import { GroupRelationships } from '../../shared/api/groups/groups.models';

@Injectable()
export class CoreTransformer {

  public constructor(private coreUtilities: CoreUtilities) {
    this.coreUtilities.coreTransformer = this;
  }

  public nodeToTreeNode(node: Node): TreeNode {
    return Map(<TreeNode> {
      id: node.id,
      dataId: node.relationships.nodedata,
      internalType: 'treeNode',
      /* Tree information */
      parents: [],
      children: [],
      parentsByKey: {},
      childrenByKey: {},
      unfilteredParents: [],
      unfilteredChildren: [],
      parentIds: [],
      activities: [],
      subLevel: 0,
      level: node.level,
      /* Relationships */
      originalId: node.relationships.original,
      modelId: node.relationships.model,
      parentModel: node.relationships.parentModel,
      subsets: node.relationships.subsets,
      sidesteps: node.relationships.sidesteps,
      instance: node.relationships.instance,
      templates: node.relationships.templates,
      models: node.relationships.models,
      nodestructures: node.relationships.nodestructures,
      businessarea: node.relationships.businessarea,
      created: node.relationships.created,
      relatedChildren: node.relationships.relatedChildren,
      relatedParents: node.relationships.relatedParents,
      /* Node structure */
      active: node.active,
      positionX: node.positionX,
      autoPositionX: node.autoPositionX,
      autoPositionUncrossed: node.autoPositionUncrossed,
      updatedAt: node.updatedAt,
      isSidestep: node.isSidestep,
      isInput: node.isInput,
      /* Node data */
      name: node.name,
      reference: node.reference,
      crossReference: node.crossReference,
      color: node.color,
      invertedColor: (!isNullOrUndefined(node.color) && node.color !== '' ? this.coreUtilities.invertColor(node.color, true) : node.color),
      createdAt: node.createdAt,
      description: node.description,
      description1: node.description1,
      description2: node.description2,
      description3: node.description3,
      description4: node.description4,
      documentUri: node.documentUri,
      upload_uri: node.upload_uri,
      status: node.status,
      responsibleId: node.responsibleId,
      groupId: node.groupId,
      targetDate: node.targetDate,
      actualDate: node.actualDate,
      aggregationKPI: node.aggregationKPI,
      KPI: node.KPI,
      businessBenefit: node.businessBenefit,
      commercialStatus: node.commercialStatus,
      complexity: node.complexity,
      uncertainty: node.uncertainty,
      risk: node.risk,
      sizeType: node.sizeType,
      budget: node.budget,
      budgetActual: node.budgetActual,
      budgetRemaining: node.budgetRemaining,
      budgetCalculated: node.budgetCalculated,
      benefitBudget: node.benefitBudget,
      benefitActual: node.benefitActual,
      benefitRemaining: node.benefitRemaining,
      costBudget: node.costBudget,
      costActual: node.costActual,
      costRemaining: node.costRemaining,
      investBudget: node.investBudget,
      investActual: node.investActual,
      investRemaining: node.investRemaining,
      businesscalculation: node.businesscalculation,
      creationDate: node.creationDate,
      lastUpdated: node.lastUpdated,
      currency: node.currency,
      startDate: node.startDate,
      actualStartDate: node.actualStartDate,
      percentageComplete: node.percentageComplete,
      priority: node.priority,
      sorting: node.sorting,
      riskProfileCategory: node.riskProfileCategory,
      riskProfileAssessment: node.riskProfileAssessment,
      riskProfileCountermeasures: node.riskProfileCountermeasures,
      time: node.time,
      timeDescription: node.timeDescription,
      cost: node.cost,
      costDescription: node.costDescription,
      quality: node.quality,
      qualityDescription: node.qualityDescription,
      executive: node.executive,
      executiveDescription: node.executiveDescription,
      program: node.program,
      programDescription: node.programDescription,
      functional: node.functional,
      functionalDescription: node.functionalDescription,
      resource: node.resource,
      resourceDescription: node.resourceDescription,
      organizational: node.organizational,
      organizationalDescription: node.organizationalDescription,
      technical: node.technical,
      technicalDescription: node.technicalDescription,
      storypoints: node.storypoints,
      costtype: node.costtype,
      onreport: node.onreport,
      nodeType: node.nodeType,
      linkToParent: node.linkToParent,
      alsoPublishedAs: node.alsoPublishedAs,
      cpc: node.cpc,
      cpc4: node.cpc4,
      ipc: node.ipc,
      ipc4: node.ipc4,
      applicants: node.applicants,
      techAreas: node.techAreas,
      techFields: node.techFields,
      authorities: node.authorities,
      workFlowModel: node.workFlowModel,
      workFlowOperation: node.workFlowOperation,
      workFlowIfClause: node.workFlowIfClause,
      workFlowId: node.workFlowId,
      workFlowFormId: node.workFlowFormId,
      workFlowFormParams: node.workFlowFormParams,
      workFlowLink: node.workFlowLink,
      workFlowTabId: node.workFlowTabId,
      formId: node.formId,
      formFieldId: node.formFieldId,
      formFieldType: node.formFieldType,
      formFieldControlType: node.formFieldControlType,
      formFieldDropdownValue: node.formFieldDropdownValue,
      formFieldEditable: node.formFieldEditable,
      formFieldSortable: node.formFieldSortable,
      formFieldFilterable: node.formFieldFilterable,
      formFieldFixed: node.formFieldFixed,
      formFieldSearchable: node.formFieldSearchable,
      formFieldWidth: node.formFieldWidth,
      formFieldCalculation: node.formFieldCalculation,
      formFieldShape: node.formFieldShape,
      formBucket: node.formBucket,
      formFieldBucketId: node.formFieldBucketId,
      form_default_value: node.form_default_value,
      fullscreen: node.fullscreen,
      printable: node.printable,
      hideWidget: node.hideWidget,
      ai_type: node.ai_type,
      ai_preset: node.ai_preset,
      structure_duplicate_original_id: node.structure_duplicate_original_id,
      data_duplicate_original_id: node.data_duplicate_original_id,
      widget: node.widget,
      icon: node.icon,
      obligatory: node.obligatory,
      dashboardCols: node.dashboardCols,
      dashboardRows: node.dashboardRows,
      dashboardX: node.dashboardX,
      dashboardY: node.dashboardY,
      fieldConversion: node.fieldConversion,
      fieldCreateType: node.fieldCreateType,
      fieldSkipIfExists: node.fieldSkipIfExists,
      colorLabelProvider: node.colorLabelProvider,
      sync: node.sync,
      /* Additional parameter for in app usage */
      phantom: false,
      createdAtReadable: new Datum(node.createdAt).toEuropeanDateString()
    }).toJS();
  }

  public treeToBusinessArea(businessArea: any): Businessarea {
    return <Businessarea> new Businessarea(businessArea).set('relationships', new BusinessareaRelationships({
      instance: '' + businessArea.instanceId
    }));
  }

  public treeToModel(model: any): Model {
    return <Model> new Model(model).set('relationships', new ModelRelationships({
      businessarea: model.businessareaId
    }));
  }

  public treeToHumanResource(humanResource: any): CoreHumanResource {
    return this.humanResourceToCoreHumanResource(<HumanResource> new HumanResource(humanResource).set('relationships', new HumanResourceRelationships({
      instance: '' + humanResource.instanceId
    })).set('name', humanResource.first_name + ' ' + humanResource.last_name));
  }

  public treeToGroup(group: any): CoreGroup {
    return this.groupToCoreGroup(<Group> new Group(group).set('relationships', new GroupRelationships({
      instance: group.instanceId
    })));
  }

  public treeToTreeNode(node: any): TreeNode {
    const treeNode = <TreeNode> Object.assign(this.getDynamicTree(), this.getNodeToTreeNode(node));
    if (!isNullOrUndefined(node.model)) {
      treeNode.modelId = node.model;
    }
    if (node instanceof NodeData) {} else {
      treeNode.reference = isNullOrUndefined(node.reference) || node.reference === 0 || node.reference === '' ? node.id : node.reference;
    }
    return treeNode;
  }

  protected getNodeToTreeNode(node: any): TreeNode {
    return <TreeNode> {
      id: '' + node.id,
      dataId: '' + node.dataId,
      parentIds: Set(node.parents).toArray(),
      childIds: Set(node.children).toArray(),
      unfilteredParentIds: Set(node.parents).toArray(),
      unfilteredChildIds: Set(node.children).toArray(),
      activityIds: node.activities,
      parentsByKey: {},
      childrenByKey: {},
      subLevel: node.subLevel,
      level: node.level,
      visible: {},
      businessarea: '' + node.businessArea,
      /* Relationships */
      originalId: node.original,
      // parentModel: node.relationships.parentModel,
      // subsets: node.relationships.subsets,
      // sidesteps: node.relationships.sidesteps,
      // instance: node.relationships.instance,
      // templates: node.relationships.templates,
      models: !isNullOrUndefined(node.versionIds) ? node.versionIds.split(',') : [],
      nodestructures: !isNullOrUndefined(node.nodestructures) ? node.nodestructures.split(',') : [],
      // created: node.relationships.created,
      // relatedChildren: node.relationships.relatedChildren,
      // relatedParents: node.relationships.relatedParents,
      /* Node structure */
      active: node.active,
      positionX: node.positionX,
      autoPositionX: node.autoPositionX,
      autoPositionUncrossed: node.autoPositionUncrossed,
      updatedAt: node.updatedAt,
      // isSidestep: node.isSidestep,
      // isInput: node.isInput,
      /* Node data */
      name: node.name,
      crossReference: node.crossReference,
      color: node.color,
      invertedColor: (!isNullOrUndefined(node.color) && node.color !== '' ? this.coreUtilities.invertColor(node.color, true) : node.color),
      createdAt: node.createdAt,
      description: node.description,
      description1: node.description1,
      description2: node.description2,
      description3: node.description3,
      description4: node.description4,
      documentUri: node.documentUri,
      upload_uri: node.upload_uri,
      status: node.status,
      responsibleId: node.responsibleId,
      groupId: node.groupId,
      targetDate: node.targetDate,
      actualDate: node.actualDate,
      businessBenefit: node.businessBenefit,
      commercialStatus: node.commercialStatus,
      complexity: node.complexity,
      uncertainty: node.uncertainty,
      risk: node.risk,
      sizeType: node.sizeType,
      budget: node.budget,
      budgetActual: node.budgetActual,
      budgetRemaining: node.budgetRemaining,
      budgetCalculated: node.budgetCalculated,
      benefitBudget: node.benefitBudget,
      benefitActual: node.benefitActual,
      benefitRemaining: node.benefitRemaining,
      costBudget: node.costBudget,
      costActual: node.costActual,
      costRemaining: node.costRemaining,
      investBudget: node.investBudget,
      investActual: node.investActual,
      investRemaining: node.investRemaining,
      businesscalculation: node.businesscalculation,
      creationDate: node.creationDate,
      lastUpdated: node.lastUpdated,
      currency: node.currency,
      startDate: node.startDate,
      actualStartDate: node.actualStartDate,
      percentageComplete: node.percentageComplete,
      priority: node.priority,
      sorting: node.sorting,
      riskProfileCategory: node.riskProfileCategory,
      riskProfileAssessment: node.riskProfileAssessment,
      riskProfileCountermeasures: node.riskProfileCountermeasures,
      time: node.time,
      timeDescription: node.timeDescription,
      cost: node.cost,
      costDescription: node.costDescription,
      quality: node.quality,
      qualityDescription: node.qualityDescription,
      executive: node.executive,
      executiveDescription: node.executiveDescription,
      program: node.program,
      programDescription: node.programDescription,
      functional: node.functional,
      functionalDescription: node.functionalDescription,
      resource: node.resource,
      resourceDescription: node.resourceDescription,
      organizational: node.organizational,
      organizationalDescription: node.organizationalDescription,
      technical: node.technical,
      technicalDescription: node.technicalDescription,
      storypoints: node.storypoints,
      costtype: node.costtype,
      onreport: node.onreport,
      nodeType: node.nodeType,
      linkToParent: node.linkToParent,
      alsoPublishedAs: node.alsoPublishedAs,
      cpc: node.cpc,
      cpc4: node.cpc4,
      ipc: node.ipc,
      ipc4: node.ipc4,
      applicants: node.applicants,
      techAreas: node.techAreas,
      techFields: node.techFields,
      authorities: node.authorities,
      workFlowModel: node.workFlowModel,
      workFlowOperation: node.workFlowOperation,
      workFlowIfClause: node.workFlowIfClause,
      workFlowId: node.workFlowId,
      workFlowFormId: node.workFlowFormId,
      workFlowFormParams: node.workFlowFormParams,
      workFlowLink: node.workFlowLink,
      workFlowTabId: node.workFlowTabId,
      formId: node.formId,
      formFieldId: node.formFieldId,
      formFieldType: node.formFieldType,
      formFieldControlType: node.formFieldControlType,
      formFieldDropdownValue: node.formFieldDropdownValue,
      formFieldEditable: node.formFieldEditable,
      formFieldSortable: node.formFieldSortable,
      formFieldFilterable: node.formFieldFilterable,
      formFieldFixed: node.formFieldFixed,
      formFieldSearchable: node.form_field_searchable,
      formFieldWidth: node.formFieldWidth,
      formFieldCalculation: node.formFieldCalculation,
      formFieldShape: node.formFieldShape,
      formBucket: node.formBucket,
      formFieldBucketId: node.formFieldBucketId,
      form_default_value: node.form_default_value,
      fullscreen: node.fullscreen,
      printable: node.printable,
      hideWidget: node.hideWidget,
      ai_type: node.ai_type,
      ai_preset: node.ai_preset,
      structure_duplicate_original_id: node.structure_duplicate_original_id,
      data_duplicate_original_id: node.data_duplicate_original_id,
      widget: node.widget,
      icon: node.icon,
      obligatory: node.obligatory,
      dashboardCols: node.dashboardCols,
      dashboardRows: node.dashboardRows,
      dashboardX: node.dashboardX,
      dashboardY: node.dashboardY,
      fieldConversion: node.fieldConversion,
      fieldCreateType: node.fieldCreateType,
      fieldSkipIfExists: node.fieldSkipIfExists,
      colorLabelProvider: node.colorLabelProvider,
      sync: node.sync,
      numberField1: node.numberField1,
      numberField2: node.numberField2,
      numberField3: node.numberField3,
      numberField4: node.numberField4,
      numberField5: node.numberField5,
      numberField6: node.numberField6,
      numberField7: node.numberField7,
      numberField8: node.numberField8,
      numberField9: node.numberField9,
      numberField10: node.numberField10,
      textField1: node.textField1,
      textField2: node.textField2,
      textField3: node.textField3,
      textField4: node.textField4,
      textField5: node.textField5,
      textField6: node.textField6,
      textField7: node.textField7,
      textField8: node.textField8,
      textField9: node.textField9,
      textField10: node.textField10,
      /* Additional parameter for in app usage */
      createdAtReadable: new Datum(node.createdAt).toEuropeanDateString()
    }
  }

  public getNodeDataToTreeNode(node: NodeData): TreeNode {
    return <TreeNode> {
      name: node.name,
      crossReference: node.crossReference,
      color: node.color,
      invertedColor: (!isNullOrUndefined(node.color) && node.color !== '' ? this.coreUtilities.invertColor(node.color, true) : node.color),
      createdAt: node.createdAt,
      description: node.description,
      description1: node.description1,
      description2: node.description2,
      description3: node.description3,
      description4: node.description4,
      documentUri: node.documentUri,
      upload_uri: node.upload_uri,
      status: node.status,
      responsibleId: node.responsibleId,
      groupId: node.groupId,
      targetDate: node.targetDate === '0000-00-00T00:00:00' ? null : node.targetDate,
      actualDate: node.actualDate === '0000-00-00T00:00:00' ? null : node.actualDate,
      businessBenefit: node.businessBenefit,
      commercialStatus: node.commercialStatus,
      complexity: node.complexity,
      uncertainty: node.uncertainty,
      risk: node.risk,
      sizeType: node.sizeType,
      budget: node.budget,
      budgetActual: node.budgetActual,
      budgetRemaining: node.budgetRemaining,
      budgetCalculated: node.budgetCalculated,
      benefitBudget: node.benefitBudget,
      benefitActual: node.benefitActual,
      benefitRemaining: node.benefitRemaining,
      costBudget: node.costBudget,
      costActual: node.costActual,
      costRemaining: node.costRemaining,
      investBudget: node.investBudget,
      investActual: node.investActual,
      investRemaining: node.investRemaining,
      businesscalculation: node.businesscalculation,
      creationDate: node.creationDate,
      lastUpdated: node.lastUpdated,
      currency: node.currency,
      startDate: node.startDate === '0000-00-00T00:00:00' ? null : node.startDate,
      actualStartDate: node.actualStartDate === '0000-00-00T00:00:00' ? null : node.actualStartDate,
      percentageComplete: node.percentageComplete,
      priority: node.priority,
      riskProfileCategory: node.riskProfileCategory,
      riskProfileAssessment: node.riskProfileAssessment,
      riskProfileCountermeasures: node.riskProfileCountermeasures,
      time: node.time,
      timeDescription: node.timeDescription,
      cost: node.cost,
      costDescription: node.costDescription,
      quality: node.quality,
      qualityDescription: node.qualityDescription,
      executive: node.executive,
      executiveDescription: node.executiveDescription,
      program: node.program,
      programDescription: node.programDescription,
      functional: node.functional,
      functionalDescription: node.functionalDescription,
      resource: node.resource,
      resourceDescription: node.resourceDescription,
      organizational: node.organizational,
      organizationalDescription: node.organizationalDescription,
      technical: node.technical,
      technicalDescription: node.technicalDescription,
      storypoints: node.storypoints,
      costtype: node.costtype,
      onreport: node.onreport,
      nodeType: node.nodeType,
      alsoPublishedAs: node.alsoPublishedAs,
      cpc: node.cpc,
      cpc4: node.cpc4,
      ipc: node.ipc,
      ipc4: node.ipc4,
      applicants: node.applicants,
      techAreas: node.techAreas,
      techFields: node.techFields,
      authorities: node.authorities,
      workFlowModel: node.workFlowModel,
      workFlowOperation: node.workFlowOperation,
      workFlowIfClause: node.workFlowIfClause,
      workFlowId: node.workFlowId,
      workFlowFormId: node.workFlowFormId,
      workFlowFormParams: node.workFlowFormParams,
      workFlowLink: node.workFlowLink,
      workFlowTabId: node.workFlowTabId,
      formId: node.formId,
      formFieldId: node.formFieldId,
      formFieldType: node.formFieldType,
      formFieldControlType: node.formFieldControlType,
      formFieldDropdownValue: node.formFieldDropdownValue,
      formFieldEditable: node.formFieldEditable,
      formFieldSortable: node.formFieldSortable,
      formFieldFilterable: node.formFieldFilterable,
      formFieldFixed: node.formFieldFixed,
      formFieldWidth: node.formFieldWidth,
      formFieldCalculation: node.formFieldCalculation,
      formFieldShape: node.formFieldShape,
      formBucket: node.formBucket,
      formFieldBucketId: node.formFieldBucketId,
      form_default_value: node.form_default_value,
      fullscreen: node.fullscreen,
      printable: node.printable,
      hideWidget: node.hideWidget,
      ai_type: node.ai_type,
      ai_preset: node.ai_preset,
      widget: node.widget,
      icon: node.icon,
      obligatory: node.obligatory,
      dashboardCols: node.dashboardCols,
      dashboardRows: node.dashboardRows,
      dashboardX: node.dashboardX,
      dashboardY: node.dashboardY,
      fieldConversion: node.fieldConversion,
      fieldCreateType: node.fieldCreateType,
      fieldSkipIfExists: node.fieldSkipIfExists,
      colorLabelProvider: node.colorLabelProvider,
      sync: node.sync,
      numberField1: node.numberField1,
      numberField2: node.numberField2,
      numberField3: node.numberField3,
      numberField4: node.numberField4,
      numberField5: node.numberField5,
      numberField6: node.numberField6,
      numberField7: node.numberField7,
      numberField8: node.numberField8,
      numberField9: node.numberField9,
      numberField10: node.numberField10,
      textField1: node.textField1,
      textField2: node.textField2,
      textField3: node.textField3,
      textField4: node.textField4,
      textField5: node.textField5,
      textField6: node.textField6,
      textField7: node.textField7,
      textField8: node.textField8,
      textField9: node.textField9,
      textField10: node.textField10,
      /* Additional parameter for in app usage */
      createdAtReadable: new Datum(node.createdAt).toEuropeanDateString()
    }
  }

  protected getDynamicTree(): TreeNode {
    return <TreeNode> {
      internalType: 'treeNode',
      get parents() {
        return !this.parentIds ? [] : [...this.parentIds.map(id => AppGlobal.treeNodes.get('' + id)).filter(d => !!d)];
      },
      set parents(parentNodes: TreeNode[]) {
        this.parentIds = parentNodes.map(parentNode => parentNode.id);
      },
      get children() {
        return !this.childIds ? [] :  [...this.childIds.map(id => AppGlobal.treeNodes.get('' + id)).filter(d => !!d)];
      },
      set children(childNodes: TreeNode[]) {
        this.childIds = childNodes.map(childNode => childNode.id);
      },
      get unfilteredParents() {
        return !this.unfilteredParentIds ? [] :  [...this.unfilteredParentIds.map(id => AppGlobal.treeNodes.get('' + id)).filter(d => !!d)];
      },
      set unfilteredParents(parentNodes: TreeNode[]) {
        this.parentIds = parentNodes.map(parentNode => parentNode.id);
      },
      get unfilteredChildren() {
        return !this.unfilteredChildIds ? [] :  [...this.unfilteredChildIds.map(id => AppGlobal.treeNodes.get('' + id)).filter(d => !!d)];
      },
      set unfilteredChildren(childNodes: TreeNode[]) {
        this.childIds = childNodes.map(childNode => childNode.id);
      },
      get activities() {
        return !this.activityIds ? [] :  [...this.activityIds.map(id => AppGlobal.treeActivities.get('' + id)).filter(d => !!d)];
      },
      set activities(activities: TreeActivity[]) {
        this.activityIds = activities.map(activity => activity.id);
      },
      /* Additional parameter for in app usage */
      phantom: false,
    };
  }

  public nodeStructureToTreeNode(nodeStructure: NodeStructure): TreeNode {
    return <TreeNode> Object.assign(this.getDynamicTree(), {
      id: '' + nodeStructure.id,
      level: nodeStructure.level,
      modelId: '' + nodeStructure.relationships.model,
      active: nodeStructure.active,
      positionX: nodeStructure.positionX,
      autoPositionX: nodeStructure.autoPositionX,
      autoPositionUncrossed: nodeStructure.autoPositionUncrossed,
      updatedAt: nodeStructure.updatedAt,
      sorting: nodeStructure.sorting,
      structure_duplicate_original_id: nodeStructure.duplicate_original_id,
    });
  }

  public treeActivityToTreeActivity(activity: Activity): TreeActivity {
    return Map(<TreeActivity> {
      /* Tree */
      id: activity.id,
      phantom: activity.phantom,
      nodebucket: activity.nodebucket,
      internalType: 'treeActivity',
      activitable_id: isNullOrUndefined(activity.relationships) ? undefined : activity.relationships.nodedata,
      /* Data */
      name: activity.name,
      description: activity.description,
      start: activity.start,
      end: activity.end,
      startActual: activity.startActual,
      endActual: activity.endActual,
      reference: activity.reference,
      crossReference: activity.crossReference,
      type: activity.type,
      status: activity.status,
      responsibleId: activity.responsibleId,
      percentageComplete: activity.percentageComplete,
      priority: activity.priority,
      milestone: activity.milestone,
      budget: activity.budget,
      budgetPlan: activity.budgetPlan,
      benefitBudget: activity.cb_benefit_budget !== undefined ? activity.cb_benefit_budget : activity.benefitBudget,
      benefitActual: activity.cb_benefit_actual !== undefined ? activity.cb_benefit_actual : activity.benefitActual,
      benefitRemaining: activity.cb_benefit_remaining !== undefined ? activity.cb_benefit_remaining : activity.benefitRemaining,
      costBudget: activity.cb_cost_budget !== undefined ? activity.cb_cost_budget : activity.costBudget,
      costActual: activity.cb_cost_actual !== undefined ? activity.cb_cost_actual : activity.costActual,
      costRemaining: activity.cb_cost_remaining !== undefined ? activity.cb_cost_remaining : activity.costRemaining,
      investBudget: activity.cb_invest_budget !== undefined ? activity.cb_invest_budget : activity.investBudget,
      investActual: activity.cb_invest_actual !== undefined ? activity.cb_invest_actual : activity.investActual,
      investRemaining: activity.cb_invest_remaining !== undefined ? activity.cb_invest_remaining : activity.investRemaining,
      riskProfileCategory: activity.riskProfileCategory,
      riskProfileAssessment: activity.riskProfileAssessment,
      riskProfileCountermeasures: activity.riskProfileCountermeasures,
      time: activity.time,
      timeDescription: activity.timeDescription,
      cost: activity.cost,
      costDescription: activity.costDescription,
      quality: activity.quality,
      qualityDescription: activity.qualityDescription,
      executive: activity.executive,
      executiveDescription: activity.executiveDescription,
      program: activity.program,
      programDescription: activity.programDescription,
      functional: activity.functional,
      functionalDescription: activity.functionalDescription,
      resource: activity.resource,
      resourceDescription: activity.resourceDescription,
      organizational: activity.organizational,
      organizationalDescription: activity.organizationalDescription,
      technical: activity.technical,
      technicalDescription: activity.technicalDescription,
      updatedAt: activity.updatedAt,
      duplicate_original_id: activity.duplicate_original_id,
      sorting: activity.sorting,
    }).toJS();
  }

  public nodeDataToTreeNode(node: NodeData, create = false): TreeNode {
    const treeNode = create ? this.treeToTreeNode(node) : this.getNodeDataToTreeNode(node);
    treeNode.dataId = node.id;
    treeNode.businessarea = '' + node.relationships.businessarea;
    treeNode.models = node.relationships.models;
    treeNode.nodestructures = node.relationships.nodestructures;
    return treeNode;
  }

  public treeToTreeRelationship(relationship: any): TreeRelationship {
    return <TreeRelationship> {
      /* Ids */
      id: relationship.id,
      parentId: '' + relationship.parentId,
      childId: '' + relationship.childId,
      modelId: relationship.versionId,
      internalType: 'treeRelationship',
      /* Data */
      phantom: false,
      weight: parseFloat(parseFloat(relationship.weight).toFixed(2)),
      condition: relationship.condition,
      category: relationship.category,
      type: relationship.type
    };
  }

  public nodeCreateToTreeNode(node: NodeCreate): TreeNode {
    return Map(<TreeNode> {
      id: node.id,
      internalType: 'treeNode',
      /* Tree information */
      parents: [],
      children: [],
      unfilteredParents: [],
      unfilteredChildren: [],
      activities: [],
      subLevel: 0,
      level: node.level,
      /* Relationships */
      modelId: node.modelId,
      /* Node structure */
      active: node.active,
      positionX: node.positionX,
      isSidestep: node.isSidestep,
      /* Node data */
      name: node.name,
      crossReference: node.crossReference,
      color: node.color,
      invertedColor: (!isNullOrUndefined(node.color) && node.color !== '' ? this.coreUtilities.invertColor(node.color, true) : node.color),
      description: node.description,
      description1: node.description1,
      description2: node.description2,
      description3: node.description3,
      description4: node.description4,
      documentUri: node.documentUri,
      upload_uri: node.upload_uri,
      status: node.status,
      responsibleId: node.responsibleId,
      groupId: node.groupId,
      targetDate: node.targetDate,
      actualDate: node.actualDate,
      aggregationKPI: node.aggregationKPI,
      KPI: node.KPI,
      businessBenefit: node.businessBenefit,
      commercialStatus: node.commercialStatus,
      complexity: node.complexity,
      uncertainty: node.uncertainty,
      risk: node.risk,
      sizeType: node.sizeType,
      budget: node.budget,
      budgetActual: node.budgetActual,
      budgetRemaining: node.budgetRemaining,
      budgetCalculated: node.budgetCalculated,
      benefitBudget: node.benefitBudget,
      benefitActual: node.benefitActual,
      benefitRemaining: node.benefitRemaining,
      costBudget: node.costBudget,
      costActual: node.costActual,
      costRemaining: node.costRemaining,
      investBudget: node.investBudget,
      investActual: node.investActual,
      investRemaining: node.investRemaining,
      businesscalculation: node.businesscalculation,
      currency: node.currency,
      startDate: node.startDate,
      actualStartDate: node.actualStartDate,
      percentageComplete: node.percentageComplete,
      priority: node.priority,
      sorting: node.sorting,
      riskProfileCategory: node.riskProfileCategory,
      riskProfileAssessment: node.riskProfileAssessment,
      riskProfileCountermeasures: node.riskProfileCountermeasures,
      time: node.time,
      timeDescription: node.timeDescription,
      cost: node.cost,
      costDescription: node.costDescription,
      quality: node.quality,
      qualityDescription: node.qualityDescription,
      executive: node.executive,
      executiveDescription: node.executiveDescription,
      program: node.program,
      programDescription: node.programDescription,
      functional: node.functional,
      functionalDescription: node.functionalDescription,
      resource: node.resource,
      resourceDescription: node.resourceDescription,
      organizational: node.organizational,
      organizationalDescription: node.organizationalDescription,
      technical: node.technical,
      technicalDescription: node.technicalDescription,
      storypoints: node.storypoints,
      costtype: node.costtype,
      onreport: node.onreport,
      nodeType: parseInt('' + node.nodeType),
      linkToParent: node.linkToParent,
      alsoPublishedAs: node.alsoPublishedAs,
      cpc: node.cpc,
      cpc4: node.cpc4,
      ipc: node.ipc,
      ipc4: node.ipc4,
      applicants: node.applicants,
      techAreas: node.techAreas,
      techFields: node.techFields,
      authorities: node.authorities,
      workFlowModel: node.workFlowModel,
      workFlowOperation: node.workFlowOperation,
      workFlowIfClause: node.workFlowIfClause,
      workFlowId: node.workFlowId,
      workFlowFormId: node.workFlowFormId,
      workFlowFormParams: node.workFlowFormParams,
      workFlowLink: node.workFlowLink,
      workFlowTabId: node.workFlowTabId,
      formId: node.formId,
      formFieldId: node.formFieldId,
      formFieldType: node.formFieldType,
      formFieldControlType: node.formFieldControlType,
      formFieldDropdownValue: node.formFieldDropdownValue,
      formFieldEditable: node.formFieldEditable,
      formFieldSortable: node.formFieldSortable,
      formFieldFilterable: node.formFieldFilterable,
      formFieldFixed: node.formFieldFixed,
      formFieldSearchable: node.formFieldSearchable,
      formFieldWidth: node.formFieldWidth,
      formFieldCalculation: node.formFieldCalculation,
      formFieldShape: node.formFieldShape,
      formBucket: node.formBucket,
      formFieldBucketId: !isNullOrUndefined(node.formFieldBucketId) && node.formFieldBucketId !== '' ? node.formFieldBucketId : 'Y', // TODO add fields to api to store the id as well
      fullscreen: node.fullscreen,
      printable: node.printable,
      hideWidget: node.hideWidget,
      structure_duplicate_original_id: node.structure_duplicate_original_id,
      data_duplicate_original_id: node.data_duplicate_original_id,
      widget: node.widget,
      icon: node.icon,
      obligatory: node.obligatory,
      dashboardCols: node.dashboardCols,
      dashboardRows: node.dashboardRows,
      dashboardX: node.dashboardX,
      dashboardY: node.dashboardY,
      fieldConversion: node.fieldConversion,
      fieldCreateType: node.fieldCreateType,
      fieldSkipIfExists: node.fieldSkipIfExists,
      colorLabelProvider: node.colorLabelProvider,
      /* Additional parameter for in app usage */
      phantom: false
    }).toJS();
  }

  public  relationshipToTreeRelationship(relationship: Relationship): TreeRelationship {
    return Map(<TreeRelationship> {
      /* Ids */
      id: relationship.id,
      parentId: relationship.relationships.parent,
      childId: relationship.relationships.child,
      modelId: relationship.relationships.model,
      internalType: 'treeRelationship',
      updatedAt: relationship.updatedAt,
      /* Data */
      phantom: false,
      weight: parseFloat(parseFloat(relationship.weight).toFixed(2)),
      condition: relationship.condition,
      category: relationship.category,
      type: relationship.type,
      startDate: relationship.startDate,
      endDate: relationship.endDate
    }).toJS();
  }

  public activityToTreeActivity(activity: Activity): TreeActivity {
    return Map(<TreeActivity> {
      /* Tree */
      id: activity.id,
      phantom: activity.phantom,
      nodebucket: activity.nodebucket,
      internalType: 'treeActivity',
      /* Relationships */
      humanresources: activity.relationships.humanresources,
      instance: activity.relationships.instances,
      nodeData: activity.relationships.nodedata,
      /* Data */
      name: activity.name,
      description: activity.description,
      start: activity.start,
      end: activity.end,
      startActual: activity.startActual,
      endActual: activity.endActual,
      reference: activity.reference,
      crossReference: activity.crossReference,
      type: activity.type,
      status: activity.status,
      responsibleId: activity.responsibleId,
      percentageComplete: activity.percentageComplete,
      priority: activity.priority,
      milestone: activity.milestone,
      budget: activity.budget,
      budgetPlan: activity.budgetPlan,
      benefitBudget: activity.benefitBudget,
      benefitActual: activity.benefitActual,
      benefitRemaining: activity.benefitRemaining,
      costBudget: activity.costBudget,
      costActual: activity.costActual,
      costRemaining: activity.costRemaining,
      investBudget: activity.investBudget,
      investActual: activity.investActual,
      investRemaining: activity.investRemaining,
      riskProfileCategory: activity.riskProfileCategory,
      riskProfileAssessment: activity.riskProfileAssessment,
      riskProfileCountermeasures: activity.riskProfileCountermeasures,
      time: activity.time,
      timeDescription: activity.timeDescription,
      cost: activity.cost,
      costDescription: activity.costDescription,
      quality: activity.quality,
      qualityDescription: activity.qualityDescription,
      executive: activity.executive,
      executiveDescription: activity.executiveDescription,
      program: activity.program,
      programDescription: activity.programDescription,
      functional: activity.functional,
      functionalDescription: activity.functionalDescription,
      resource: activity.resource,
      resourceDescription: activity.resourceDescription,
      organizational: activity.organizational,
      organizationalDescription: activity.organizationalDescription,
      technical: activity.technical,
      technicalDescription: activity.technicalDescription,
      updatedAt: activity.updatedAt,
      duplicate_original_id: activity.duplicate_original_id,
      sorting: activity.sorting,
      float_field1_a: activity.float_field1_a,
      float_field2_a: activity.float_field2_a,
      float_field3_a: activity.float_field3_a,
      float_field4_a: activity.float_field4_a,
      float_field5_a: activity.float_field5_a,
      float_field6_a: activity.float_field6_a,
      float_field7_a: activity.float_field7_a,
      float_field8_a: activity.float_field8_a,
      float_field9_a: activity.float_field9_a,
      float_field10_a: activity.float_field10_a,
      float_field11_a: activity.float_field11_a,
      float_field12_a: activity.float_field12_a,
      float_field13_a: activity.float_field13_a,
      float_field14_a: activity.float_field14_a,
      float_field15_a: activity.float_field15_a,
      float_field1_b: activity.float_field1_b,
      float_field2_b: activity.float_field2_b,
      float_field3_b: activity.float_field3_b,
      float_field4_b: activity.float_field4_b,
      float_field5_b: activity.float_field5_b,
      float_field6_b: activity.float_field6_b,
      float_field7_b: activity.float_field7_b,
      float_field8_b: activity.float_field8_b,
      float_field9_b: activity.float_field9_b,
      float_field10_b: activity.float_field10_b,
      float_field11_b: activity.float_field11_b,
      float_field12_b: activity.float_field12_b,
      float_field13_b: activity.float_field13_b,
      float_field14_b: activity.float_field14_b,
      float_field15_b: activity.float_field15_b,
      float_field1_c: activity.float_field1_c,
      float_field2_c: activity.float_field2_c,
      float_field3_c: activity.float_field3_c,
      float_field4_c: activity.float_field4_c,
      float_field5_c: activity.float_field5_c,
      float_field6_c: activity.float_field6_c,
      float_field7_c: activity.float_field7_c,
      float_field8_c: activity.float_field8_c,
      float_field9_c: activity.float_field9_c,
      float_field10_c: activity.float_field10_c,
      float_field11_c: activity.float_field11_c,
      float_field12_c: activity.float_field12_c,
      float_field13_c: activity.float_field13_c,
      float_field14_c: activity.float_field14_c,
      float_field15_c: activity.float_field15_c,
      float_field1_d: activity.float_field1_d,
      float_field2_d: activity.float_field2_d,
      float_field3_d: activity.float_field3_d,
      float_field4_d: activity.float_field4_d,
      float_field5_d: activity.float_field5_d,
      float_field6_d: activity.float_field6_d,
      float_field7_d: activity.float_field7_d,
      float_field8_d: activity.float_field8_d,
      float_field9_d: activity.float_field9_d,
      float_field10_d: activity.float_field10_d,
      float_field11_d: activity.float_field11_d,
      float_field12_d: activity.float_field12_d,
      float_field13_d: activity.float_field13_d,
      float_field14_d: activity.float_field14_d,
      float_field15_d: activity.float_field15_d,
    }).toJS();
  }

  public modelToCoreModel(model: Model): CoreModel {
    return Map(<CoreModel> {
      id: model.id,
      name: model.name,
      versionname: isNullOrUndefined(model.versionname) || model.versionname === '' ? model.name : model.versionname,
      reference: model.reference,
      crossReference: model.crossReference,
      description: model.description,
      color: model.color,
      date: model.date,
      startDate: model.startDate,
      status: model.status,
      commercialStatus: model.commercialStatus,
      updatedAt: model.updatedAt,
      template: model.template,
      settings: model.settings,
      humanresource: model.humanresource,
      targetpicture: model.targetpicture,
      hypothesis: model.hypothesis,
      abbreviation: model.abbreviation,
      duplicate_original_id: model.duplicate_original_id,
      sorting: model.sorting,
      onstrategyradar: model.onstrategyradar,
      onreport: model.onreport,
      onprogramm: model.onprogramm,
      parentModel: model.parentModel,
      active: model.active,
      type: model.type,
      icon: model.icon,
    }).toJS();
  }

  public humanResourceToCoreHumanResource(humanResource: HumanResource): CoreHumanResource {
    return Map(<CoreHumanResource> {
      id: humanResource.id,
      email: humanResource.email,
      name: humanResource.name,
      first_name: humanResource.first_name,
      last_name: humanResource.last_name,
      color: humanResource.color,
      can_login: humanResource.can_login,
      foreign_id: humanResource.foreign_id,
      updatedAt: humanResource.updatedAt,
      permissions: humanResource.permissions,
      notification_settings: humanResource.notification_settings,
      storypoints: humanResource.storypoints,
      image: humanResource.image,
      instanceId: !!humanResource.relationships ? humanResource.relationships.instance : 0
    }).toJS();
  }

  public groupToCoreGroup(group: Group): CoreGroup {
    return Map(<CoreGroup> {
      id: '' + group.id,
      name: group.name,
      humanResources: group.relationships.humanresources.toArray()
    }).toJS();
  }

  public coreGroupToGroup(coreGroup: CoreGroup): Group {
    return new Group(coreGroup);
  }

  public treeActivityToActivity(treeActivity: TreeActivity): Activity {
    return new Activity(treeActivity);
  }

  public treeNodeToNodeCreate(treeNode: TreeNode): NodeCreate {
    return new NodeCreate(treeNode);
  }

  public treeNodeToNodeGrouping(treeNode: TreeNode): NodeGrouping {
    const groupingNode = new NodeGrouping(treeNode);
    return <NodeGrouping> groupingNode.set('nodeDataId', treeNode.dataId);
  }

  public treeRelationshipToRelationshipCreate(treeRelationship: TreeRelationship): RelationshipCreate {
    const relationship = new RelationshipCreate(treeRelationship);
    return <RelationshipCreate> relationship.set('parent', treeRelationship.parentId).set('child', treeRelationship.childId).set('model', treeRelationship.modelId);
  }

  public coreHumanResourceToHumanResource(coreHumanResource: CoreHumanResource): HumanResource {
    return new HumanResource(coreHumanResource);
  }

  public hierarchyToCoreInstance(instance: Hierarchy): CoreInstance {
    return {
      id: instance.id,
      name: instance.name,
      type: instance.instanceType
    };
  }

  public actionToCoreAction(action: any): CoreAction {
    const classes = [];
    if (this.coreUtilities.getInvertedColorKey(isNullOrUndefined(action.color) || action.color === '' ? '#ffffff' : action.color) === 'white') {
      classes.push('inverted');
    }
    const coreAction = <CoreAction> {
      id: action.id,
      name: action.name,
      description: action.description,
      icon: action.icon,
      type: 'action',
      background: action.color,
      className: classes.join(', ')
    };
    if (!isNullOrUndefined(coreAction.background)) {
      coreAction.color = this.coreUtilities.invertColor(isNullOrUndefined(coreAction.background) || coreAction.background === '' ? '#ffffff' : coreAction.background, true);
    }
    return coreAction;
  }

  public businessAreaToModule(businessArea: any): CoreModule {
    return <CoreModule> {
      id: businessArea.id,
      name: businessArea.name,
      type: 'module',
      icon: businessArea.icon === '' ? this.coreUtilities.generateTextIcon(businessArea.name, 30) : businessArea.icon,
      actions: isNullOrUndefined(businessArea.actions) ? [] : businessArea.actions.map(action => this.actionToCoreAction(action)).sort((a, b) => this.coreUtilities.sort(a.name, b.name, true))
    };
  }

  public userToCoreUser(user: User): CoreUser {
    return <CoreUser> {
      id: '' + user.id,
      name: user.name,
      role: user.is_superuser ? 'CORE.USER.ROLE.SUPERUSER' : 'CORE.USER.ROLE.REGULARUSER',
      permissions: user.permissions,
      image: environment.imageUrl + user.id,
      humanResources: user.relationships.humanresources.toArray(),
      settings: user.settings,
      superUser: user.is_superuser
    };
  }

  public nodeTypeToModelType(nodeType: number) {
    switch (nodeType) {
      case NODES_TYPE_CCM:
        return MODEL_TYPE_CCM;
      case NODES_TYPE_SCM:
        return MODEL_TYPE_SCM;
      case NODES_TYPE_UCIM:
        return MODEL_TYPE_UCIM;
      case NODES_TYPE_CWM:
        return MODEL_TYPE_CWM;
      case NODES_TYPE_MCM:
        return MODEL_TYPE_MCM;
      case NODES_TYPE_LIBRARY:
        return MODEL_TYPE_LIBRARY;
      case NODES_TYPE_STRATEGYMAP:
        return MODEL_TYPE_STRATEGYMAP;
      case NODES_TYPE_PROJECTPORTFOLIO:
        return MODEL_TYPE_PROJECTPORTFOLIO;
      default:
        return MODEL_TYPE_DEFAULT;
    }
  }

  public modelTypeToNodeType(modelType: number) {
    switch (modelType) {
      case MODEL_TYPE_CCM:
        return NODES_TYPE_CCM;
      case MODEL_TYPE_SCM:
        return NODES_TYPE_SCM;
      case MODEL_TYPE_UCIM:
        return NODES_TYPE_UCIM;
      case MODEL_TYPE_CWM:
        return NODES_TYPE_CWM;
      case MODEL_TYPE_MCM:
        return NODES_TYPE_MCM;
      case MODEL_TYPE_LIBRARY:
        return NODES_TYPE_LIBRARY;
      case MODEL_TYPE_STRATEGYMAP:
        return NODES_TYPE_STRATEGYMAP;
      case MODEL_TYPE_PROJECTPORTFOLIO:
        return NODES_TYPE_PROJECTPORTFOLIO;
      default:
        return NODES_TYPE_DEFAULT;
    }
  }

  public readableNumber(number: number, thousandsSeparator: boolean | string = false, showDecimals = false): string {
    if (number === 0) {
      return '' + number;
    }
    /* Make decimal string */
    let decimalString = parseFloat('' + number).toFixed(2);
    if (parseInt('' + number) === number && !showDecimals) {
      decimalString = '' + number;
    }
    /* Now convert the first part and separate if the setting is set to true */
    if (thousandsSeparator !== false) {
      const decimalSplit = decimalString.split('.');
      let result = decimalSplit[0].replace(/\B(?=(\d{3})+(?!\d))/g, <string> thousandsSeparator);
      if (parseInt(decimalSplit[1]) > 0) {
        result += ',' + decimalSplit[1];
      }
      decimalString = result;
    }
    return decimalString;
  }

  public nodeTypeToText(nodeType: number) {
    const count = CoreNodeTypes.length;
    let text = '';
    for (let i = 0; i < count; i++) {
      const coreNodeType = CoreNodeTypes[i];
      if (coreNodeType.key === nodeType) {
        text = coreNodeType.label;
        break;
      }
    }
    return text;
  }

  public coreHumanResourceToTreeNode(humanResource: CoreHumanResource) {
    return {
      id: humanResource.id,
      nodeType: NODES_TYPE_HUMANRESOURCE,
      name: humanResource.first_name === '' && humanResource.last_name === '' && humanResource.name !== '' ? humanResource.name : humanResource.first_name + ' ' + humanResource.last_name,
      email: humanResource.email,
      responsibleId: humanResource.id
    };
  }

  public coreGroupToTreeNode(coreGroup: CoreGroup) {
    return {
      id: coreGroup.id,
      nodeType: NODES_TYPE_GROUP,
      name: coreGroup.name,
      groupId: coreGroup.id
    };
  }

  public coreHumanResourceDeltaToTreeNodeDelta(delta: Map<string, any>, coreHumanResource: CoreHumanResource) {
    let transformed = Map<string, any>();
    delta.forEach((value, key) => {
      switch (key) {
        case 'first_name':
          transformed = transformed.set('name', value + ' ' + coreHumanResource.last_name);
          break;
        case 'last_name':
          transformed = transformed.set('name', coreHumanResource.first_name + ' ' + value);
          break;
      }
    });
    return transformed;
  }

  public hierarchyToPermissionsInstance(hierarchy: Hierarchy): PermissionsInstance {
    /* Modules & Actions */
    const modules = [];
    const count = (<any[]> hierarchy.children).length;
    for (let i = 0; i < count; i++) {
      const businessArea = (<any[]> hierarchy.children)[i];
      if (!isNullOrUndefined(businessArea.modules)) {
        const baModules = businessArea.modules.sort((a, b) => this.coreUtilities.sort(a.name, b.name, true));
        const count2 = baModules.length;
        for (let i2 = 0; i2 < count2; i2++) {
          const module = baModules[i2];
          module.actions = isNullOrUndefined(businessArea.actions) ? [] : businessArea.actions.sort((a, b) => this.coreUtilities.sort(a.name, b.name, true));
          modules.push(module);
        }
      }
    }
    /* Human resources */
    const humanResources = hierarchy.humanResources.map(humanResource => this.humanResourceToCoreHumanResource(humanResource));
    /* Return instance */
    return <PermissionsInstance> {
      id: hierarchy.id,
      name: hierarchy.name,
      humanResources: humanResources,
      modules: modules
    };
  }

  public auditToCoreAudit(audit: Audit): CoreAudit {
    const datum = new Datum(audit.updatedAt);
    return <CoreAudit> {
      id: '' + audit.id,
      event: audit.event,
      before: audit.before,
      after: audit.after,
      type: audit.type,
      elementId: audit.elementId,
      userId: '' + audit.userId,
      responsibleId: '' + audit.responsibleId,
      updatedAt: datum,
      readableUpdatedAt: datum.toEuropeanString()
    };
  }

  public convertValue(type: string, value: any) {
    if (isNullOrUndefined(value)) {
      return value;
    }
    switch (type) {
      case 'fte-md':
        return value * 17;
      case 'md-fte':
        return value / 17;
    }
    return value;
  }

  public invertConvertValue(type: string, value: any) {
    if (isNullOrUndefined(value)) {
      return value;
    }
    switch (type) {
      case 'fte-md':
        return value / 17;
      case 'md-fte':
        return value * 17;
    }
    return value;
  }

  public prepareDeltaForGoApi(delta: Map<string, any>): Map<string, any> {
    let _delta = Map<string, any>();
    delta.forEach((value, key) => {
      switch (key) {
        case 'crossReference':
          _delta = _delta.set('cross_reference', value);
          break;
        case 'documentUri':
          _delta = _delta.set('document_uri', value);
          break;
        case 'status':
          _delta = _delta.set('status_type', value);
          break;
        case 'businessBenefit':
          _delta = _delta.set('business_benefit_type', value);
          break;
        case 'commercialStatus':
          _delta = _delta.set('commercial_status_type', value);
          break;
        case 'complexity':
          _delta = _delta.set('complexity_type', value);
          break;
        case 'sizeType':
          _delta = _delta.set('size_type', value);
          break;
        case 'uncertainty':
          _delta = _delta.set('uncertainty_type', value);
          break;
        case 'risk':
          _delta = _delta.set('risk_type', value);
          break;
        case 'responsibleId':
          _delta = _delta.set('responsible_id', value);
          break;
        case 'budgetCalculated':
          _delta = _delta.set('budget_calculated', value);
          break;
        case 'budgetActual':
          _delta = _delta.set('budget_actual', value);
          break;
        case 'budgetRemaining':
          _delta = _delta.set('budget_remaining', value);
          break;
        case 'benefitBudget':
          _delta = _delta.set('cb_benefit_budget', value);
          break;
        case 'benefitActual':
          _delta = _delta.set('cb_benefit_actual', value);
          break;
        case 'benefitRemaining':
          _delta = _delta.set('cb_benefit_remaining', value);
          break;
        case 'costBudget':
          _delta = _delta.set('cb_cost_budget', value);
          break;
        case 'costActual':
          _delta = _delta.set('cb_cost_actual', value);
          break;
        case 'costRemaining':
          _delta = _delta.set('cb_cost_remaining', value);
          break;
        case 'investBudget':
          _delta = _delta.set('cb_invest_budget', value);
          break;
        case 'investActual':
          _delta = _delta.set('cb_invest_actual', value);
          break;
        case 'investRemaining':
          _delta = _delta.set('cb_invest_remaining', value);
          break;
        case 'targetDate':
          _delta = _delta.set('target_date', value);
          break;
        case 'actualDate':
          _delta = _delta.set('target_date_actual', value);
          break;
        case 'startDate':
          _delta = _delta.set('start_date', value);
          break;
        case 'actualStartDate':
          _delta = _delta.set('start_date_actual', value);
          break;
        case 'riskProfileCategory':
          _delta = _delta.set('risk_profile_category', value);
          break;
        case 'riskProfileAssessment':
          _delta = _delta.set('risk_profile_assessment', value);
          break;
        case 'riskProfileCountermeasures':
          _delta = _delta.set('risk_profile_countermeasures', value);
          break;
        case 'percentageComplete':
          _delta = _delta.set('percComplete', value);
          break;
        default:
          _delta = _delta.set(key, value);
          break;
      }
    });
    return _delta;
  }
}

import { List, Map, Set } from 'immutable';
import { isString, isNullOrUndefined, isFunction, isArray } from 'util';
import { User } from '../user';
import { Action } from './common.action';
import { RequestDiffRecord } from './common.models';
import {HttpErrorResponse} from '@angular/common/http';
import { Node } from '../node/node.models';

export class Utils {

  public static currentUser: User;

  public static incrementIsBusy(action: Action, isBusy: number) {
    if (isNullOrUndefined(action.payload)) { return ++isBusy; }
    const payload = isArray(action.payload) ? action.payload[0] : action.payload;
    const addition = isNullOrUndefined(payload) ? 0 : (isArray(payload.data) ? payload.data.length : (isArray(action.payload) ? action.payload.length : 1));
    return isBusy + (addition === 0 ? 1 : addition);
  }

  public static decrementIsBusy(action: Action, isBusy: number) {
    let update = isBusy - (isArray(action.payload.response) && action.payload.response.length > 0 ? action.payload.response.length : 1);
    if (action.payload.response instanceof HttpErrorResponse) {
      update = isBusy - (isNullOrUndefined(action.payload.request) ? 1 : isArray(action.payload.request.data) && action.payload.request.data.length > 0 ? action.payload.request.data.length : 1);
    }
    return !isNullOrUndefined(action.payload.initiator) ? isBusy : (update <= 0 ? 0 : update);
  }

  public static mergeIfChanged(entities: Map<any, any>, force = false) {
    return (e: Map<any, any>) => {
      if (force) {
        return entities;
      }
      return !isNullOrUndefined(e) && !isFunction(e) ? e.mergeWith(this.ifChanged(), entities) : this.ifChanged();
    };
  }

  public static mergeEntities(entities: Map<any, any>) {
    return (e: Map<any, any>) => {
      e = <Map<any, any>> e.map((entity, id) => {
        if (entities.has(id)) {
          const newEntity = entities.get(id);
          if (newEntity instanceof Node) {
            return newEntity;
          }
          return !!entity.mergeWith ? entity.mergeWith(newEntity) : newEntity;
        }
        return entity;
      });
      entities.forEach((entity, id) => {
        if (!e.has(id)) {
          e = e.set(id, entity);
        }
      });
      return e;
    };
  }

  public static updateDiff<T extends Map<any, any>>(state: T, action ?: Action): T {
    let initiator = null;
    if (!isNullOrUndefined(action.payload) && !isNullOrUndefined(action.payload.initiator)) {
      initiator = action.payload.initiator.data;
    }
    return <T>state.withMutations(s => s
      .updateIn(['diff'], (diff: RequestDiffRecord) => diff
        .set('id', Math.random())
        .set('action', action.type)
        .set('payload', !isNullOrUndefined(action.payload) ? action.payload.request : undefined)
        .set('response', !isNullOrUndefined(action.payload) ? action.payload.response : undefined)
        .set('initiator', initiator)
      )
    );
  }

  public static updateState<T extends Map<any, any>>(state: T, entities: Map<any, any>, relationships: Map<any, any> | undefined, action ?: Action, force = false): T {
    if (!isNullOrUndefined(action) &&
        !isNullOrUndefined(action.payload) &&
        !isNullOrUndefined(action.payload.initiator) &&
        !isNullOrUndefined(Utils.currentUser) &&
        '' + action.payload.initiator.data.id === '' + Utils.currentUser.id &&
        !action.payload.fromWorker /*To perform autorefresh when the initiator is the same as the current user, but the update here is due to aggregations*/
    ) {
      return state;
    }
    state = <T>state.withMutations(s => s
      .updateIn(['entities'], Utils.mergeIfChanged(Utils.includeRelationships(Utils.mapDate(entities), relationships), force))
      .updateIn(['relationships'], Utils.mergeIfChanged(relationships, force))
      .updateIn(['ids'], (ids: Set<number>) => ids.union(Set<number>(entities.keys())))
    );
    if (!isNullOrUndefined(relationships)) {
      state = <T>state.withMutations(s => s
        .updateIn(['relationships'], Utils.mergeIfChanged(relationships, force))
      );
    }
    if (action === undefined) {
      return state;
    } else {
      return Utils.updateDiff(state, action);
    }
  }

  public static updateGeneralState<T extends Map<any, any>>(state: T, key: string, values: any): T {
    return <T>state.withMutations(s => s
      .updateIn([key], () => values)
    );
  }

  public static updateGoState<T extends Map<any, any>>(state: T, entities: Map<any, any>, relationships: Map<any, any>, secondaryCosts: Map<string, List<any>>): T {
    return <T>state.withMutations(s => s
      .updateIn(['entities'], Utils.mergeEntities(Utils.includeRelationships(Utils.mapDate(entities), relationships)))
      .updateIn(['relationships'], Utils.mergeEntities(relationships))
      .updateIn(['secondaryCosts'], Utils.mergeEntities(secondaryCosts))
    );
  }

  public static updateStateNoRel<T extends Map<any, any>>(state: T, entities: Map<any, any>, action ?: Action, force = false): T {
    state = <T>state.withMutations(s => s
      .updateIn(['entities'], Utils.mergeIfChanged(Utils.mapDate(entities), force))
      .updateIn(['ids'], (ids: Set<number>) => ids.union(Set<number>(entities.keys())))
    );
    if (action === undefined) {
      return state;
    } else {
      return Utils.updateDiff(state, action);
    }
  }

  public static updateStateVanilla<T extends Map<any, any>>(state: T, entities: any[], action ?: Action): T {
    state = <T>state.withMutations(s => s
      .updateIn(['entities'], _ => entities)
    );
    if (action === undefined) {
      return state;
    } else {
      return Utils.updateDiff(state, action);
    }
  }

  public static updateStateWithoutMerge<T extends Map<any, any>>(state: T, entities: Map<any, any>, action ?: Action, force = false): T {
    state = <T>state.withMutations(s => s
      .updateIn(['entities'], d => entities)
    );
    if (action === undefined) {
      return state;
    } else {
      return Utils.updateDiff(state, action);
    }
  }

  public static updateStateDirect<T extends Map<any, any>>(state: T, entities: Map<any, any>, action ?: Action, force = false): T {
    state = <T>state.withMutations(s => s
      .updateIn(['entities'], Utils.mergeIfChanged(entities, force))
      .updateIn(['ids'], (ids: Set<number>) => ids.union(Set<number>(entities.keys())))
    );
    if (action === undefined) {
      return state;
    } else {
      return Utils.updateDiff(state, action);
    }
  }

  public static deleteFromState<T extends Map<any, any>>(state: T, entities: Map<any, any>, relationships: Map<any, any> | undefined, action ?: Action): T {
    if (!isNullOrUndefined(action) && !isNullOrUndefined(action.payload) && !isNullOrUndefined(action.payload.initiator) && !isNullOrUndefined(Utils.currentUser) && '' + action.payload.initiator.data.id === '' + Utils.currentUser.id) {
      return state;
    }
    let entitiesMap: Map<any, any> = (<any> state).entities;
    let relationshipsMap: Map<any, any> = (<any> state).relationships;
    entities.forEach(entity => {
      entitiesMap = entitiesMap.remove(entity.id);
      relationshipsMap = relationshipsMap.remove(entity.id);
    });
    state = <T>state.withMutations(s => s
      .updateIn(['entities'], () => Utils.includeRelationships(Utils.mapDate(entitiesMap), relationshipsMap))
      .updateIn(['relationships'], () => relationshipsMap)
      .updateIn(['ids'], (ids: Set<number>) => ids.filter(id => !entities.has(id)))
    );
    if (action === undefined) {
      return state;
    } else {
      return Utils.updateDiff(state, action);
    }
  }

  private static ifChanged() {
    return (oldEntry: Map<any, any>, newEntry: Map<any, any>) => isNullOrUndefined(oldEntry.equals) ? newEntry : (oldEntry.equals(newEntry) ? oldEntry : Utils.merge(oldEntry, newEntry));
  }

  private static merge(oldEntry: any, newEntry: any) {
    oldEntry.keySeq().forEach(key => {
      const value = newEntry.get(key);
      if (!(value === undefined)
        && (value !== oldEntry.get(key)
        && (typeof value !== 'object' || value === null || oldEntry.get(key) === undefined || oldEntry.get(key) === null || oldEntry.get(key).equals === undefined || (typeof value === 'object' && !oldEntry.get(key).equals(newEntry.get(key)))))) {
        oldEntry = oldEntry.set(key, value);
      }
    });
    return oldEntry;
  }

  private static includeRelationships(entities, relationships) {
    return entities.map(entity => (entity.has('relationships') && relationships.has('' + entity.id) ? entity.set('relationships', relationships.get('' + entity.id)) : entity));
  }

  private static mapDate(entities) {
    return entities.map(entity => {
      List(['date', 'targetDate', 'actualDate', 'start', 'end', 'startActual', 'endActual', 'startDate', 'actualStartDate'])
        .forEach(field => entity = Utils.convert(entity, field));
      return entity;
    }).toMap();
  }

  private static convert(entity: any, field: string) {
    if (entity.has(field) && isString(entity.get(field))) {
      const split = entity.get(field).split(' ');
      if (split.length > 1) {
        entity = entity.set(field, split[0] + 'T' + split[1]);
      }
    }
    return entity;
  }


}

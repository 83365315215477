import { indexOf } from 'typescript-collections/dist/lib/arrays';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { isArray, isNullOrUndefined, isObject, isString } from 'util';
import { List, Map, OrderedMap, Set } from 'immutable';
import { EventEmitter, Injectable } from '@angular/core';
import { UUID } from 'angular2-uuid';

import {
  BusinessareaService,
  ModelService,
  HumanResourceService,
  NodeService,
  RelationshipService,
  Businessarea,
  Model,
  HumanResource,
  Node,
  Relationship,
  ActivityService,
  Activity,
  Subset,
  NodeCreate,
  RelationshipCreate,
  RelationshipAction,
  NodeStructureService,
  NodeStructureAction,
  NodeDataService,
  BUSINESSAREA_TYPE_MODULE,
  ModelAction,
  UserService,
  User,
  BusinessareaAction,
  NodeDataAction,
  RequestDiffRecord,
  ActivityAction,
  HumanResourceAction,
  GroupService,
  GroupAction,
  Group,
  OneTimeTokenService,
  Email,
  EmailService, EmailAction, OneTimeTokenAction, NodeStructure, NodeData
} from '../../shared/api';
import { SubscriptionService } from '../../shared/utilities/subscription';
import {
  TreeRelationship,
  TreeNode,
  TreeActivity,
  CoreNodeTypes,
  CoreLabelProvider,
  CoreModel,
  CoreHumanResource,
  CoreMultiTransfer,
  CoreGroup,
  CoreSocketData,
  CoreExportOptions,
  CoreAudit,
  CoreWidgetFilter,
  CoreWidgetFilterCheckbox,
  CoreGlobalFilter,
  ExportExcel
} from '../interface/core.interface';
import { CoreTransformer } from '../transformer/core.transformer';
import { CoreActionGroup, CoreFilter, CoreLegend, CoreModule, CoreNodeType, CoreOptions, CoreTransfer, CoreTransferResult, CoreUser } from '../interface/core.interface';
import { ColorLabelProvider } from '../../services/colorlabelprovider/colorlabelprovider.service';
import { ColorLabelProviderAbstract } from '../../services/colorlabelprovider/providers/colorlabelprovider.service.abstract';
import { RELATIONSHIP_TYPE_DEFAULT } from '../../shared/api/relationships/relationships.models';
import {
  NodeGrouping,
  NODES_TYPE_ACTION,
  NODES_TYPE_ACTIONGROUP,
  NODES_TYPE_ADD, NODES_TYPE_CHILD, NODES_TYPE_COLORLABELPROVIDER,
  NODES_TYPE_CONNECT,
  NODES_TYPE_DELETE,
  NODES_TYPE_DISCONNECT,
  NODES_TYPE_FIELD,
  NODES_TYPE_FIELDS,
  NODES_TYPE_FORM,
  NODES_TYPE_FORM_TAB,
  NODES_TYPE_GROUP,
  NODES_TYPE_HOMEACTION,
  NODES_TYPE_HUMANRESOURCE, NODES_TYPE_LINE,
  NODES_TYPE_METHODOLOGY,
  NODES_TYPE_MODEL,
  NODES_TYPE_MODULECONFIGURATION,
  NODES_TYPE_NEXT,
  NODES_TYPE_PARENT,
  NODES_TYPE_SETUPACTION,
  NODES_TYPE_UPDATE,
  NODES_TYPE_WORKFLOW
} from '../../shared/api/nodes/nodes.models';
import { CoreUtilities } from '../utilities/core.utilities';
import { PayloadFactory } from '../../shared/api/shared/payload-factory';
import { Hierarchy, HierarchyAction, HierarchyService } from '../../shared/api/hierarchy';
import { INSTANCE_TYPE_LIBRARY } from '../../shared/api/instances/instances.models';
import { MODEL_TYPE_MCM } from '../../shared/api/models/models.models';
import { IPayload } from '../../shared/api/shared';
import { WorkflowService } from '../../shared/api/workflow';
import { FormService as FormApiService } from '../../shared/api/form';
import { FormService } from '../../components/form/service/form.service';
import { Version, VersionAction, VersionService } from '../../shared/api/versions';
import { ExportService } from '../../shared/utilities/export-service';
import { FormScreenModalComponent } from '../../screens/form/modal/form.screen.modal';
import { AuditService } from '../../shared/api/audits';
import { CardModalComponent } from '../../components/card/modal/card.modal';
import { FormResult } from '../../components/form/interface/form.interface';
import { EisenhowerService } from '../../components/eisenhower/service/eisenhower.service';
import { NodesAndRelationshipsService } from '../../shared/api/nodes-relationships';
import { Datum } from '../../shared/utilities/datum';
import { AppGlobal } from '../../app.global';
import { GuardianAction, GuardianData, GuardianService } from '../../shared/api/guardian';
import { GuardianResponseData } from '../../shared/api/guardian/guardian.models';
import { HttpErrorResponse } from '@angular/common/http';
import { MyAction, MyService } from '../../shared/api/my';
import { AiAction, AiService } from '../../shared/api/ai';
import { Similarity } from '../../ai/interface/ai.interface';
import { TreeService } from '../../shared/api/tree';
import isSet = Set.isSet;
import { BarDataRangeInterface } from '../../components/bar/interface/bar.interface';
import * as moment from 'moment';

@Injectable()
export class CoreService {

  private loadFilters: any;

  public useGo = true;
  public useTreeNodes = true;
  public goUpdate = false;

  public constructor(private businessAreaService: BusinessareaService,
                     private modelService: ModelService,
                     private humanResourceService: HumanResourceService,
                     private nodeService: NodeService,
                     private relationshipService: RelationshipService,
                     private nodesAndRelationshipService: NodesAndRelationshipsService,
                     private activityService: ActivityService,
                     private nodeStructureService: NodeStructureService,
                     private nodeDataService: NodeDataService,
                     private colorLabelProviderService: ColorLabelProvider,
                     private hierarchyService: HierarchyService,
                     private userService: UserService,
                     private groupService: GroupService,
                     private workflowService: WorkflowService,
                     private formApiService: FormApiService,
                     private formService: FormService,
                     private coreUtilities: CoreUtilities,
                     private coreTransformer: CoreTransformer,
                     private versionService: VersionService,
                     private oneTimeTokenService: OneTimeTokenService,
                     private exportService: ExportService,
                     private emailService: EmailService,
                     private eisenhowerService: EisenhowerService,
                     private auditService: AuditService,
                     private appGlobal: AppGlobal,
                     private guardianService: GuardianService,
                     private myService: MyService,
                     private treeService: TreeService,
                     private aiService: AiService) {
    this.formService.coreService = this;
    this.coreUtilities.coreService = this;
    this.formService.eisenhowerService = this.eisenhowerService;
  }

  readonly minX = 70;
  readonly nodeMargin = 100;

  /* Public event emitters for global events */
  public openModal = new EventEmitter<string>();

  /* The event emitters to register services on */
  private businessArea = new BehaviorSubject<Businessarea>(undefined);
  private models = new BehaviorSubject<OrderedMap<string, Model>>(undefined);
  private subModels = new BehaviorSubject<OrderedMap<string, Model>>(undefined);
  private subSets = new BehaviorSubject<OrderedMap<string, Subset>>(undefined);
  private humanResources = new BehaviorSubject<OrderedMap<string, CoreHumanResource>>(undefined);
  private groups = new BehaviorSubject<OrderedMap<string, CoreGroup>>(undefined);
  private nodes = new BehaviorSubject<OrderedMap<string, Node>>(undefined);
  private relationships = new BehaviorSubject<OrderedMap<string, Relationship>>(undefined);
  private activities = new BehaviorSubject<OrderedMap<string, Activity>>(undefined);
  private treeNodes = new BehaviorSubject<OrderedMap<string, TreeNode>>(undefined);
  private treeRelationships = new BehaviorSubject<OrderedMap<string, TreeRelationship>>(undefined);
  private treeActivities = new BehaviorSubject<OrderedMap<string, TreeActivity>>(undefined);
  private hierarchy = new BehaviorSubject<OrderedMap<string, TreeNode>>(undefined);
  private colorLabelProviderKey = new BehaviorSubject<string>('');
  private modals = new BehaviorSubject<Map<string, any>>(undefined);

  private legend: CoreLegend[] = [];
  private filteredNodeTypes: number[] = [];

  /* Business area id */
  private businessAreaId: string;

  /* MCM */
  private mcm: string;

  /* Checksums */
  private businessAreaCheckSum = undefined;
  private modelsCheckSum = Map<string, string>();
  private humanResourcesCheckSum = undefined;
  private groupsCheckSum = undefined;
  private nodesCheckSum = undefined;
  private relationshipsCheckSum = undefined;
  private activitiesCheckSum = undefined;

  /* Color label provider */
  private colorLabelProvider: ColorLabelProviderAbstract;
  private selectedColorLabelProvider: string;

  /* Positions */
  private positions = Map<number, { min: number, max: number }>();

  /* Toggled legends */
  private toggledLegends: string[] = [];

  /* Filtered node ids */
  private filteredNodeIds = Map<string, boolean>();

  /* Used node types */
  private nodeTypes: CoreNodeType[] = [];

  /* Subscription service */
  private subscriptionService = new SubscriptionService();

  /* Already called requests */
  private knownRequests = {};

  private isCreatedMap = Map<string, Function>();

  public clearFilter = new EventEmitter<any>();

  /* Global filters */
  public globalFilters = OrderedMap<string, CoreGlobalFilter>();
  private globalFilterKeys = Set<string>();
  private globalFilterIds: string[];
  private globalFilterIdsEmitter = new BehaviorSubject<string[]>([]);

  /* Color label providers */
  private colorLabelProviders: { key: string, label: string }[] = [
    { key: '', label: 'RADIAL.DEFAULT.DISPLAY.COLOR.NOTAPPLICABLE' },
    { key: 'heatmap', label: 'Heatmap' },
    { key: 'importance', label: 'RADIAL.DEFAULT.DISPLAY.COLOR.IMPORTANCE' },
    { key: 'nodetypes', label: 'RADIAL.DEFAULT.DISPLAY.COLOR.NODETYPES' },
    { key: 'planned', label: 'LEGEND.PLANNED.PLANNED' },
    { key: 'projectsavailable', label: 'Projects available' },
    { key: 'responsible', label: 'RADIAL.DEFAULT.DISPLAY.COLOR.RESPONSIBLE' },
    { key: 'risk', label: 'RADIAL.DEFAULT.DISPLAY.COLOR.RISK' },
    { key: 'status', label: 'RADIAL.DEFAULT.DISPLAY.COLOR.STATUS' },
    { key: 'targetDate', label: 'RADIAL.DEFAULT.DISPLAY.COLOR.TARGETDATE' },
    { key: 'coloredRelations', label: 'RADIAL.DEFAULT.DISPLAY.COLOR.COLOREDRELATIONS' },
    { key: 'statusField', label: 'Status field' }
  ];

  /* Cache */
  private cache = Map<string, Map<string, TreeNode[]>>();

  /* User */
  public currentUser: CoreUser;

  private clearCache(filter = false) {
    if (filter) {
      AppGlobal.filterCache = [];
    }
    AppGlobal.filterNodesCache = {};
  }
  /**
   * Load the business area
   * @param businessAreaId
   */
  public load(businessAreaId: string) {
    if (this.businessAreaId !== businessAreaId) {
      /* Store the business area id */
      this.businessAreaId = businessAreaId;
      /* Initialise */
      this.initialise();
      /* Bind the listeners */
      this.bindTreeNodesListener();
      /* Mass load the business area */
      this.treeService.loadByBusinessArea(parseInt(businessAreaId));
    }
  }

  public loadBusinessArea() {
    return new Promise(resolve => {
      this.subscriptionService.add('load-business-area', this.businessAreaService.diff.subscribe(diff => {
        if (!!diff.action && (diff.action === BusinessareaAction.MASSLOAD_SUCCESS || diff.action === BusinessareaAction.LOAD_FAIL)) {
          this.subscriptionService.remove('load-business-area');
          resolve();
        }
      }));
      if (this.useGo) {
        this.businessAreaService.massGo(this.businessAreaId);
      } else {
        this.businessAreaService.mass(this.businessAreaId);
      }
    });
  }

  /**
   * Clear the module
   */
  public clearModule(): CoreService {
    this.businessAreaId = undefined;
    this.businessAreaCheckSum = undefined;
    this.modelsCheckSum = this.modelsCheckSum.clear();
    this.humanResourcesCheckSum = undefined;
    this.groupsCheckSum = undefined;
    this.nodesCheckSum = undefined;
    this.relationshipsCheckSum = undefined;
    this.activitiesCheckSum = undefined;
    this.subscriptionService.remove();
    return this;
  }

  /**
   * Destroy all listeners and put everything into garbage collection
   */
  public destroy() {
    this.subscriptionService.remove();
  }

  /**
   * Get the configuration node + it's child tree
   */
  public getConfiguration(): Observable<Array<TreeNode>> {
    return this.getNodes({ onlyMCM: true, ignoreGlobalFilter: true }).map(treeNodes => this.filterNodes(treeNodes, [{ by: 'nodeType', value: NODES_TYPE_MODULECONFIGURATION }, { by: 'businessarea', value: '' + this.businessAreaId }], false));
  }

  /**
   * Get all modules aka business areas with a specific business area type
   */
  public getModules(): Observable<CoreModule[]> {
    return this.getBusinessAreasByHierarchy().map(hierarchies => hierarchies.sort((a, b) => this.coreUtilities.sort(a.name, b.name, true)).map(hierarchy => this.coreTransformer.businessAreaToModule(hierarchy)));
  }

  /**
   * Get actions
   */
  public getActions(): Observable<TreeNode[]> {
    return this.getNodes({ filters: [{ by: 'nodeType', value: NODES_TYPE_ACTION }], onlyMCM: true }).map(actions => actions.sort((a, b) => this.coreUtilities.sort(a.name, b.name, true)));
  }

  /**
   * Get all actions
   */
  public getAllActions(): Observable<TreeNode[]> {
    return this.getNodes({ onlyMCM: true }).map(treeNodes => this.filterNodes(treeNodes, [{ by: 'nodeType', value: [NODES_TYPE_ACTIONGROUP, NODES_TYPE_SETUPACTION, NODES_TYPE_HOMEACTION] }], false));
  }

  /**
   * Get tree nodes map
   */
  public getTreeNodesMap() {
    return this.treeNodes.getValue();
  }

  /**
   * Get nodes with a set of options
   * @param options
   * @param key
   *    filter      (string)  a filter key either a field of TreeRelationship or a custom filter defined
   *    filterValue (any)     The value to check the filter against
   */
  public getNodes(options?: CoreOptions, key?: string): Observable<Array<TreeNode>> {
    return this.getNodesBy(this.treeNodes.map(treeNodes => {
      if (isNullOrUndefined(treeNodes) || (!isNullOrUndefined(options) && options.ignoreBusinessArea === true)) {
        return treeNodes;
      }
      if (!isNullOrUndefined(options) && !isNullOrUndefined(this.mcm) && (options.ignoreMCM === true || options.onlyMCM === true)) {
        return <OrderedMap<string, TreeNode>>treeNodes.filter(treeNode => {
          return '' + treeNode.businessarea === '' + this.businessAreaId && (options.ignoreMCM ? treeNode.modelId !== this.mcm : treeNode.modelId === this.mcm);
        });
      }
      return <OrderedMap<string, TreeNode>>treeNodes.filter(treeNode => '' + treeNode.businessarea === '' + this.businessAreaId);
    }), options, true, key);
  }

  public getInstantNodes(options?: CoreOptions): TreeNode[] {
    return this.treeNodes.getValue().filter(treeNode => {
      let visible = true;
      /* Filter the tree nodes if a filter is specified */
      if (!isNullOrUndefined(options) && !isNullOrUndefined(options.filters) && this.filterNodes([treeNode], options.filters, true, options).length === 0) {
        visible = false;
      }
      /* Return the tree nodes */
      return visible;
    }).toArray();
  }

  public getNodeDataAuditsBy(observable: Observable<TreeNode[]>, options?: CoreOptions): Observable<Map<string, CoreAudit[]>> {
    return this.getNodesBy(observable, options).mergeMap(treeNodes => {
      const ids = treeNodes.map(treeNode => treeNode.dataId);
      this.auditService.loadByIds('nodedata', ids);
      return this.auditService.all().map(audits => {
        let auditsMap = Map<string, CoreAudit[]>();
        audits.forEach(audit => {
          const coreAudit = this.coreTransformer.auditToCoreAudit(audit);
          const coreAudits = auditsMap.has('' + coreAudit.elementId) ? auditsMap.get('' + coreAudit.elementId) : [];
          coreAudits.push(coreAudit);
          auditsMap = auditsMap.set('' + coreAudit.elementId, coreAudits.sort((a, b) => b.updatedAt.timestamp - a.updatedAt.timestamp));
        });
        return auditsMap;
      });
    });
  }

  public getNodeDataAuditsByNode(treeNode: TreeNode) {
    return new Promise<Map<string, CoreAudit[]>>(resolve => {
      this.subscriptionService.add('audit-diff', this.auditService.diff.subscribe(diff => {
        if (!isNullOrUndefined(diff.payload) && diff.payload.query.nodedata.indexOf(treeNode.dataId) !== -1) {
          this.subscriptionService.remove('audit-diff');
          this.auditService.all().take(1).subscribe(audits => {
            let auditsMap = Map<string, CoreAudit[]>();
            audits.forEach(audit => {
              const coreAudit = this.coreTransformer.auditToCoreAudit(audit);
              const coreAudits = auditsMap.has('' + coreAudit.elementId) ? auditsMap.get('' + coreAudit.elementId) : [];
              coreAudits.push(coreAudit);
              auditsMap = auditsMap.set('' + coreAudit.elementId, coreAudits.sort((a, b) => b.updatedAt.timestamp - a.updatedAt.timestamp));
            });
            resolve(auditsMap);
          });
        }
      }));
      this.auditService.loadByIds('nodedata', [treeNode.dataId]);
    });
  }

  /**
   * Get nodes in hierarchical order with a set of options
   * @param options
   * @param key
   *    filter      (string)  a filter key either a field of TreeRelationship or a custom filter defined
   *    filterValue (any)     The value to check the filter against
   */
  public getHierarchy(options?: CoreOptions, key?: string): Observable<Array<TreeNode>> {
    return this.getNodesBy(this.hierarchy, options, true, key);
  }

  /**
   * Get the business area
   */
  public getBusinessArea(): Observable<Businessarea> {
    return this.businessArea;
  }

  /**
   * Get the business area instant
   */
  public getBusinessAreaInstant(): Businessarea {
    return this.businessArea.getValue();
  }

  /**
   * Get the business area id
   */
  public getBusinessAreaId(): string {
    return this.businessAreaId;
  }

  /**
   * Get instances by hierarchy service
   */
  public getInstancesByHierarchy(): Observable<any[]> {
    return this.hierarchyService.allLoaded(HierarchyAction.LOAD_SUCCESS).map((instances: List<Hierarchy>) => this.coreUtilities.flatArray(instances
      .filter(instance => instance.instanceType !== INSTANCE_TYPE_LIBRARY)
      .toArray()));
  }

  /**
   * Get business areas by hierarchy service
   */
  public getBusinessAreasByHierarchy(): Observable<any[]> {
    return this.hierarchyService.allLoaded(HierarchyAction.LOAD_SUCCESS).map((instances: List<Hierarchy>) => this.coreUtilities.flatArray(instances
      .filter(instance => instance.instanceType !== INSTANCE_TYPE_LIBRARY)
      .map(instance => {
        if (!isNullOrUndefined(instance.children)) {
          return <Hierarchy[]>(<any[]>instance.children);
        } else if (!isNullOrUndefined((<any> instance).attributes.children)) {
          return (<any> instance).attributes.children;
        } else {
          return [];
        }
      }).toArray()));
  }

  /**
   * Get nodes with a set of options
   * @param options
   *    filter      (string)  a filter key either a field of TreeRelationship or a custom filter defined
   *    filterValue (any)     The value to check the filter against
   */
  public getModels(options?: CoreOptions): Observable<Array<Model>> {
    return this.getModelsBy(this.models.map(models => {
      if (isNullOrUndefined(models)) {
        return models;
      }
      return <OrderedMap<string, Model>>models.filter(model => '' + model.relationships.businessarea === '' + this.businessAreaId);
    }), options);
  }

  public getVersions(model: Model): Observable<Array<CoreModel>> {
    return this.getModelsBy(this.models, { filters: [{ by: 'id', value: model.relationships.versions.map(id => '' + id).toArray() }] }).map(models => models.map(m => this.coreTransformer.modelToCoreModel(m)));
  }

  public createVersion(originalModel: CoreModel, name: string) {
    return new Promise(resolve => {
      this.versionService.diff.filter(diff => diff.action === VersionAction.CREATE_SUCCESS).take(1).subscribe(diff => resolve());
      this.versionService.create(originalModel.id, { id: '', data: new Version({ 'description': name, 'modelId': originalModel.id }) });
    });
  }

  /**
   * Get models
   * @param observable
   * @param options
   * @param deep
   */
  public getModelsBy(observable: Observable<OrderedMap<string, Model>>, options?: CoreOptions, deep = true): Observable<Array<Model>> {
    return observable.filter(d => !!d).map(modelsMap => {
      /* First of all convert map to array */
      let models = modelsMap.toArray();
      /* Filter the models if a filter is specified */
      if (!isNullOrUndefined(options) && !isNullOrUndefined(options.filters)) {
        models = this.filterModels(models, options.filters, deep);
      }
      /* Return the tree nodes */
      return models;
    });
  }

  /**
   * Get the legends
   */
  public getLegends(modelIds?: string[], key?: string): Observable<CoreLegend[]> {
    const options = !isNullOrUndefined(modelIds) ? { filters: [{ by: 'modelId', value: modelIds }] } : { filters: [] };
    return this.getNodes(options, key).map(nodes => this.getLegend(nodes));
  }

  /* Get the label by label node */
  public getLabelByLabelNode(labelFieldNode: TreeNode, treeNode: TreeNode) {
    let label = [];
    const labels = labelFieldNode.children.sort((a, b) => a.positionX - b.positionX);
    const count = labels.length;
    for (let i = 0; i < count; i++) {
      const labelNode = labels[i];
      label.push(this.formService.getReadableValue(labelNode, treeNode));
    }
    return label.join(labelFieldNode.formId === '' ? ' ' : labelFieldNode.formId);
  }

  /**
   * Get the color label provider
   */
  public getColorLabelProvider(): Observable<string> {
    return this.colorLabelProviderKey;
  }

  /**
   * Search by options in given scope
   * @param options
   * @param treeNodes
   * @param deep
   * @param childField
   * @param stopAtSuccess
   * @param doNotCross
   * @param result
   * @param ids
   */
  public searchBy(options: CoreOptions, treeNodes: TreeNode[], deep = true, childField = 'children', stopAtSuccess = false, doNotCross: number[] = [], result = [], ids = []): TreeNode[] {
    if (isNullOrUndefined(treeNodes)) {
      return result;
    }
    const count = treeNodes.length;
    for (let i = 0; i < count; i++) {
      const treeNode = treeNodes[i];
      if (doNotCross.indexOf(treeNode.nodeType) !== -1) {
        continue;
      }
      if (!isNullOrUndefined(treeNode) && ids.indexOf(treeNode.id) === -1) {
        ids.push(treeNode.id);
        let success = false;
        if (this.isNodeVisible(treeNode, options.filters)) {
          success = true;
          result.push(treeNode);
        }
        if (deep && treeNode[childField].length > 0 && treeNode.nodeType !== NODES_TYPE_NEXT && !(success && stopAtSuccess)) {
          result = this.searchBy(options, treeNode[childField], deep, childField, stopAtSuccess, doNotCross, result, ids);
        }
      }
    }
    return result;
  }

  /**
   * Search in tree
   * @param options
   * @param treeNode
   * @param direction
   */
  public searchInTree(options: CoreOptions, treeNode: TreeNode, direction = 'children'): TreeNode[] {
    const result: TreeNode[] = [];
    const tree = direction === 'children' || direction === 'unfilteredChildren' ? this.coreUtilities.getChildren(treeNode[direction]) : this.coreUtilities.getParents(treeNode[direction]);
    const count = tree.length;
    for (let i = 0; i < count; i++) {
      const t = tree[i];
      if (this.isNodeVisible(t, options.filters)) {
        result.push(t);
      }
    }
    return result;
  }

  /**
   * Get nodes in hierarchical order with a set of options
   * @param observable
   * @param options
   *    filter      (string)  a filter key either a field of TreeRelationship or a custom filter defined
   *    filterValue (any)     The value to check the filter against
   * @param deep
   */
  public getNodesBy(observable: Observable<OrderedMap<string, TreeNode> | TreeNode[]>, options?: CoreOptions, deep = true, key?: string): Observable<Array<TreeNode>> {
    return observable.filter(d => !!d).map(treeNodesMap => {
      let checksum: string;
      if (!isNullOrUndefined(key)) {
        /* Get checksum */
        checksum = this.coreUtilities.getOptionsCheckSum(key, options);
        if (!isNullOrUndefined(AppGlobal.filterNodesCache[checksum])) {
          return AppGlobal.filterNodesCache[checksum];
        }
      }
      /* First convert map to array */
      let treeNodes = isArray(treeNodesMap) ? treeNodesMap : treeNodesMap.toArray();
      /* Filter the tree nodes if a filter is specified */
      if (!isNullOrUndefined(options) && !isNullOrUndefined(options.filters)) {
        treeNodes = this.filterNodes(treeNodes, options.filters, deep, options);
      }
      if (!isNullOrUndefined(checksum)) {
        AppGlobal.filterNodesCache[checksum] = treeNodes;
      }
      /* Return the tree nodes */
      return treeNodes;
    });
  }

  /**
   * Get nodes in hierarchical order with a set of options filtered by model node
   * @param observable
   * @param modelNode
   * @param options
   *    filter      (string)  a filter key either a field of TreeRelationship or a custom filter defined
   *    filterValue (any)     The value to check the filter against
   * @param deep
   */
  public getNodesByModelNode(observable: Observable<TreeNode[]>, modelNode: TreeNode, options?: CoreOptions, deep = true): Observable<Array<TreeNode>> {
    return this.getModels({ filters: [{ by: 'type', value: modelNode.children.map(model => this.coreTransformer.nodeTypeToModelType(model.nodeType)) }] }).mergeMap(models => {
      if (isNullOrUndefined(options)) {
        options = { filters: [] };
      }
      options.filters.push({ by: 'modelId', value: models.map(model => model.id) });
      return this.getNodesBy(observable, options);
    });
  }

  /**
   * Get nodes in hierarchical order with a set of options
   * @param observable
   * @param options
   *    filter      (string)  a filter key either a field of TreeRelationship or a custom filter defined
   *    filterValue (any)     The value to check the filter against
   * @param deep
   */
  public getNodesByArray(observable: Observable<TreeNode[]>, options?: CoreOptions, deep = true): Observable<Array<TreeNode>> {
    return observable.filter(d => !!d).map(treeNodes => {
      /* Filter the tree nodes if a filter is specified */
      if (!isNullOrUndefined(options) && !isNullOrUndefined(options.filters)) {
        treeNodes = this.filterNodes(treeNodes, options.filters, deep);
      }
      /* Return the tree nodes */
      return treeNodes;
    });
  }

  /**
   * Filter an array of tree nodes via key and value combination
   * @param treeNodes
   * @param filters
   * @param options
   * @param deep
   */
  public filterNodes(treeNodes: TreeNode[], filters: CoreFilter[], deep = false, options?: CoreOptions) {
    if (filters.length === 0) {
      return treeNodes;
    }
    const filterKey = this.coreUtilities.getFilterKey(filters);
    return this.filterNodesByVisible(this.setVisibleNodes(treeNodes, filters, filterKey, options), filterKey);
  }

  /**
   * Filter nodes by key
   * @param treeNodes
   * @param filterKey
   */
  public filterNodesByVisible(treeNodes: TreeNode[], filterKey) {
    if (isNullOrUndefined(treeNodes)) {
      return [];
    }
    /* Filter the tree node */
    return treeNodes.filter(treeNode => !isNullOrUndefined(treeNode.visible) && treeNode.visible[filterKey]);
  }

  private setVisibleNodes(treeNodes: TreeNode[], filters: CoreFilter[], filterKey: string, options?: CoreOptions) {
    /* Stop processing because the filter is already known */
    if (AppGlobal.filterCache.indexOf(filterKey) !== -1) {
      return treeNodes;
    }
    AppGlobal.filterCache.push(filterKey);
    const filtersLength = filters.length;
    /* If filter is empty */
    if (filtersLength === 0 || isNullOrUndefined(treeNodes) || treeNodes.length === 0) {
      return treeNodes;
    }
    /* If filter is not empty */
    const count = treeNodes.length;
    for (let i = 0; i < count; i++) {
      const treeNode = treeNodes[i]
      /* Set the visible value */
      if (isNullOrUndefined(treeNode.visible)) {
        treeNode.visible = {};
      }
      if (!isNullOrUndefined(options) && !isNullOrUndefined(options.ignoreGlobalFilter) && options.ignoreGlobalFilter === true) {
        treeNode.visible['global'] = true;
      } else {
        treeNode.visible['global'] = isNullOrUndefined(this.globalFilterIds) || this.globalFilterIds.indexOf(treeNode.id) !== -1;
      }
      treeNode.visible[filterKey] = this.isNodeVisible(treeNode, filters, options);
    }
    return treeNodes;
  }

  /**
   * Filter a node by presets or key value combination
   * @param treeNode
   * @param filters
   * @param options
   */
  public isNodeVisible(treeNode: TreeNode, filters: CoreFilter[], options?: CoreOptions): boolean {
    let visible = true;
    const count = filters.length;
    for (let i = 0; i < count; i++) {
      const filter = filters[i];
      if (!isNullOrUndefined(filter.nodeType) && filter.nodeType !== treeNode.nodeType) {
        continue;
      }
      switch (filter.by) {
        case 'colorLabelProvider':
          visible = this.filterByColorLabelProvider(treeNode);
          break;
        case 'nodeTypes':
          visible = this.filterByNodeTypes(treeNode);
          break;
        case 'parent':
          visible = this.coreUtilities.getParents([treeNode]).filter(parent => filter.value.indexOf(parent.dataId) !== -1).length > 0;
          break;
        case 'child':
          visible = this.coreUtilities.getChildren([treeNode]).filter(child => filter.value.indexOf(child.dataId) !== -1).length > 0;
          break;
        case 'children':
        case 'parents':
          visible = treeNode[filter.by].length === filter.value;
          break;
        case 'global':
          if (!isNullOrUndefined(options) && options.ignoreGlobalFilter === true) {
            visible = true;
          } else {
            visible = isNullOrUndefined(this.globalFilterIds) || this.globalFilterIds.indexOf(treeNode.id) !== -1;
          }
          break;
        default:
          visible = isArray(filter.value) ? filter.value.filter(value => treeNode[filter.by] + '' === value + '').length > 0 : treeNode[filter.by] + '' === filter.value + '';
          break;
      }
      if (!visible) {
        break;
      }
    }
    return visible;
  }

  /**
   * Get relationships with a set of options
   * @param options
   *    filter      (string)  a filter key either a field of TreeRelationship or a custom filter defined
   *    filterValue (any)     The value to check the filter against
   */
  public getRelationships(options?: CoreOptions): Observable<Array<TreeRelationship>> {
    return this.getRelationshipsBy(this.treeRelationships, options);
  }

  /**
   * Get relationships with a set of options
   * @param options
   *    filter      (string)  a filter key either a field of TreeRelationship or a custom filter defined
   *    filterValue (any)     The value to check the filter against
   */
  public getInstantRelationships(options?: CoreOptions): Array<TreeRelationship> {
    /* First of all convert map to array */
    let treeRelationships = this.treeRelationships.getValue().toArray();
    /* Filter the tree nodes if a filter is specified */
    if (!isNullOrUndefined(options) && !isNullOrUndefined(options.filters)) {
      treeRelationships = this.filterRelationships(treeRelationships, options.filters);
    }
    /* Check relationships against hidden node ids */
    treeRelationships = treeRelationships.map(treeRelationship => {
      /* Reset connected relationships */
      if (treeRelationship.connected) {
        treeRelationship.parentId = treeRelationship.originalParentId;
        treeRelationship.originalParentId = undefined;
        treeRelationship.connected = false;
      }
      return treeRelationship;
    });
    /* Return the tree nodes */
    return treeRelationships;
  }

  /**
   * Get the library configurations to take over to ba module config map
   */
  public getLibraryConfiguration(load = false, businessAreaType = BUSINESSAREA_TYPE_MODULE, modelType = MODEL_TYPE_MCM) {
    if (load) {
      this.hierarchyService.loadActions();
    }
    /* Listen on hierarchy */
    return this.hierarchyService.all().filter(h => h.size > 0).mergeMap(instances => {
      /* Figure out library instance */
      const library = instances.filter(instance => instance.instanceType === INSTANCE_TYPE_LIBRARY).first();
      if (!isNullOrUndefined(library)) {
        /* Search for the business area */
        const businessArea = library.children.filter(ba => ba.businessAreaType === businessAreaType)[0];
        if (!isNullOrUndefined(businessArea)) {
          /* Search for the configuration model */
          const model = businessArea.children.filter(m => m.modelstype === modelType)[0];
          if (!isNullOrUndefined(model)) {
            this.modelService.mass(model.id);
            return Observable.combineLatest(
              this.nodeService.byModelIds(['' + model.id]),
              this.relationshipService.all()
            ).filter(d => d[0].size > 0).map(d => [[], d[0], d[1], [], []]);
          } else {
            return Observable.empty();
          }
        } else {
          return Observable.empty();
        }
      } else {
        return Observable.empty();
      }
    }).map(d => this.onDataLoaded(d, true));
  }

  /**
   * Get the library configurations to take over to ba module config map
   */
  public getLibraryModules(modelType = MODEL_TYPE_MCM) {
    /* Listen on hierarchy */
    return this.hierarchyService.all().filter(h => h.size > 0).map(instances => {
      let modules = [];
      /* Figure out library instance */
      const library = instances.filter(instance => instance.instanceType === INSTANCE_TYPE_LIBRARY).first();
      if (!isNullOrUndefined(library)) {
        const count = library.children.length;
        for (let i = 0; i < count; i++) {
          const businessArea = library.children[i];
          modules = modules.concat(businessArea.modules);
        }
      }
      return modules;
    });
  }

  /**
   * Filter the models
   * @param models
   * @param filters
   * @param deep
   */
  public filterModels(models: Model[], filters: CoreFilter[], deep = true) {
    return models.filter(model => !isNullOrUndefined(model) && this.filterModel(model, filters));
  }

  /**
   * Filter a model
   * @param model
   * @param filters
   */
  public filterModel(model: Model, filters: CoreFilter[]): boolean {
    let visible = true;
    const count = filters.length;
    for (let i = 0; i < count; i++) {
      const filter = filters[i];
      switch (filter.by) {
        default:
          visible = isArray(filter.value) ? filter.value.filter(value => model[filter.by] === value).length > 0 : model[filter.by] === filter.value;
          break;
      }
      if (!visible) {
        break;
      }
    }
    return visible;
  }

  public getModelTypeMap(nodeTypeNodes: TreeNode[]) {
    const nodeTypes = [];
    const listeners = [];

    if (!isNullOrUndefined(nodeTypeNodes)) {
      const count = nodeTypeNodes.length;
      for (let i = 0; i < count; i++) {
        const nodeTypeNode = nodeTypeNodes[i];
        const modelTypeNode = nodeTypeNode.unfilteredChildren.filter(child => child.nodeType === NODES_TYPE_MODEL)[0];
        if (!isNullOrUndefined(modelTypeNode)) {
          const count2 = modelTypeNode.unfilteredChildren.length;
          for (let i2 = 0; i2 < count2; i2++) {
            const modelType = this.coreTransformer.nodeTypeToModelType(modelTypeNode.unfilteredChildren[i2].nodeType);
            nodeTypes.push(nodeTypeNode.nodeType);
            listeners.push(this.getModels({ filters: [{ by: 'type', value: modelType }] }).map(models => models[0]));
          }
        }
      }
    }

    return combineLatest(listeners).map((data: any[]) => {
      let map = Map<number, string>();
      const dataCount = data.length;
      for (let i = 0; i < dataCount; i++) {
        if (!isNullOrUndefined(data[i])) {
          map = map.set(nodeTypes[i], data[i].id);
        }
      }
      return map;
    });
  }

  public getModelsByModelNode(modelNode: TreeNode) {
    const modelTypes = modelNode.unfilteredChildren.map(nodeTypeNode => this.coreTransformer.nodeTypeToModelType(nodeTypeNode.nodeType));
    return this.getModels({ filters: [{ by: 'type', value: modelTypes }] });
  }

  public getModelByModelNode(modelNode: TreeNode) {
    const nodeTypeNode = modelNode.unfilteredChildren[0];
    if (isNullOrUndefined(nodeTypeNode)) {
      return undefined;
    }
    const modelType = this.coreTransformer.nodeTypeToModelType(nodeTypeNode.nodeType);
    return this.getModels({ filters: [{ by: 'type', value: modelType }] }).map(models => models[0]);
  }

  public getInstantModelByModelNode(modelNode: TreeNode) {
    const nodeTypeNode = modelNode.unfilteredChildren[0];
    if (isNullOrUndefined(nodeTypeNode)) {
      return undefined;
    }
    const modelType = this.coreTransformer.nodeTypeToModelType(nodeTypeNode.nodeType);
    return this.filterModels(this.models.getValue().filter(model => '' + model.relationships.businessarea === '' + this.businessAreaId).toArray(), [{ by: 'type', value: modelType }], true);
  }

  /**
   * Generate a transfer object out of a tree
   * @param treeNodes
   * @param parentTreeNode
   * @param transfer
   */
  public generateTransferFromTree(treeNodes: TreeNode[], parentTreeNode?: TreeNode, transfer = <CoreTransfer>{ nodes: [], relationships: [], ids: [] }): CoreTransfer {
    let relMap = Map<string, TreeRelationship>();
    this.treeRelationships.getValue().forEach(treeRelationship => relMap = relMap.set(treeRelationship.parentId + ':' + treeRelationship.childId, treeRelationship));
    const count = treeNodes.length;
    for (let i = 0; i < count; i++) {
      const treeNode = this.duplicateTreeNode(treeNodes[i]);
      if (transfer.ids.indexOf(treeNode.id) === -1) {
        (<TreeNode[]>transfer.nodes).push(treeNode);
        transfer.ids.push(treeNode.id);
      }
      if (!isNullOrUndefined(parentTreeNode)) {
        const key = parentTreeNode.id + ':' + treeNode.id;
        if (relMap.has(key)) {
          (<TreeRelationship[]>transfer.relationships).push(relMap.get(key));
        }
      }
      transfer = this.generateTransferFromTree(treeNode.children, treeNode, transfer);
    }
    return transfer;
  }

  /**
   * Generate a transfer object out of a tree
   * @param treeNode
   * @param parentTreeNode
   * @param transfer
   */
  public generateTransferFromTreeNode(treeNode: TreeNode, parentTreeNode?: TreeNode, transfer = <CoreTransfer>{ nodes: [], relationships: [], ids: [] }): CoreTransfer {
    treeNode = this.duplicateTreeNode(treeNode);
    if (transfer.ids.indexOf(treeNode.id) === -1) {
      (<TreeNode[]>transfer.nodes).push(treeNode);
      transfer.ids.push(treeNode.id);
    }
    if (!isNullOrUndefined(parentTreeNode)) {
      (<TreeRelationship[]>transfer.relationships).push(<TreeRelationship>{ id: UUID.UUID(), parentId: parentTreeNode.id, childId: treeNode.id });
    }
    const count = treeNode.children.length;
    for (let i = 0; i < count; i++) {
      transfer = this.generateTransferFromTreeNode(treeNode.children[i], treeNode, transfer);
    }
    return transfer;
  }

  /**
   * Duplicate a tree node
   * @param treeNode
   * @param set
   */
  public duplicateTreeNode(treeNode: TreeNode, set: { key: string, value: any }[] = [{ key: 'businessarea', value: this.businessAreaId }, { key: 'modelId', value: undefined }]): TreeNode {
    let map = Map(treeNode);
    const count = set.length;
    for (let i = 0; i < count; i++) {
      const replace: { key: string, value: any } = set[i];
      map = map.set(replace.key, replace.value);
    }
    return <TreeNode>map.toJS();
  }

  /**
   * Get the core user
   * @param load
   */
  public getUser(load = false): Observable<CoreUser> {
    if (load) {
      this.userService.load(undefined, ['activities']);
    }
    return this.userService.find().filter(d => !!d).map((user: User) => {
      this.currentUser = this.coreTransformer.userToCoreUser(user);
      return this.currentUser;
    });
  }

  /**
   * Get current human resource
   */
  public getUserHumanResource(): Promise<CoreHumanResource> {
    return new Promise<CoreHumanResource>(resolve => {
      this.getUser().take(1).subscribe(coreUser => {
        this.getHumanResources({ filters: [{ by: 'id', value: coreUser.humanResources.map(id => parseInt(id)) }, { by: 'instanceId', value: this.getBusinessAreaInstant().relationships.instance }] }).take(1).subscribe(humanResources => {
          resolve(humanResources[0]);
        });
      });
    });
  }

  public getPermissions(load = false) {
    return this.getUser(load).map(user => user.permissions);
  }

  /**
   * Get relationships with a set of options
   * Options:
   *    filter      (string)  a filter key either a field of TreeNode or a custom filter defined
   *    filterValue (any)     The value to check the filter against
   * @param observable
   * @param options
   */
  public getRelationshipsBy(observable: Observable<OrderedMap<string, TreeRelationship>>, options?: CoreOptions): Observable<Array<TreeRelationship>> {
    return observable.filter(d => !!d).map(treeRelationshipsMap => {
      /* First of all convert map to array */
      let treeRelationships = treeRelationshipsMap.toArray();
      /* Filter the tree nodes if a filter is specified */
      if (!isNullOrUndefined(options) && !isNullOrUndefined(options.filters)) {
        treeRelationships = this.filterRelationships(treeRelationships, options.filters);
      }
      /* Check relationships against hidden node ids */
      treeRelationships = treeRelationships.map(treeRelationship => {
        /* Reset connected relationships */
        if (treeRelationship.connected) {
          treeRelationship.parentId = treeRelationship.originalParentId;
          treeRelationship.originalParentId = undefined;
          treeRelationship.connected = false;
        }
        return treeRelationship;
      });
      /* Return the tree nodes */
      return treeRelationships;
    });
  }

  /**
   * Get relationships with a set of options
   * Options:
   *    filter      (string)  a filter key either a field of TreeNode or a custom filter defined
   *    filterValue (any)     The value to check the filter against
   * @param observable
   * @param options
   */
  public getRelationshipsByArray(observable: Observable<TreeRelationship[]>, options?: CoreOptions): Observable<Array<TreeRelationship>> {
    return observable.filter(d => !!d).map(treeRelationships => {
      /* Filter the tree nodes if a filter is specified */
      if (!isNullOrUndefined(options) && !isNullOrUndefined(options.filters)) {
        treeRelationships = this.filterRelationships(treeRelationships, options.filters);
      }
      /* Check relationships against hidden node ids */
      treeRelationships = treeRelationships.map(treeRelationship => {
        /* Reset connected relationships */
        if (treeRelationship.connected) {
          treeRelationship.parentId = treeRelationship.originalParentId;
          treeRelationship.originalParentId = undefined;
          treeRelationship.connected = false;
        }
        return treeRelationship;
      });
      /* Return the tree nodes */
      return treeRelationships;
    });
  }

  /**
   * Filter an array of tree relationships via key and value combination
   * @param treeRelationships
   * @param filters
   */
  public filterRelationships(treeRelationships: TreeRelationship[], filters: CoreFilter[]) {
    return treeRelationships.filter(treeRelationship => {
      let visible = true;
      const count = filters.length;
      for (let i = 0; i < count; i++) {
        const filter = filters[i];
        switch (filter.by) {
          default:
            visible = isArray(filter.value) ? filter.value.filter(value => treeRelationship[filter.by] === value).length > 0 : treeRelationship[filter.by] === filter.value;
            break;
        }
        if (!visible) {
          break;
        }
      }
      return visible;
    });
  }

  /**
   * Get human resources
   * @param options
   */
  public getHumanResources(options?: CoreOptions): Observable<Array<CoreHumanResource>> {
    return this.getHumanResourcesBy(this.humanResources.filter(a => !!a).map(humanResources => <OrderedMap<string, CoreHumanResource>>humanResources.filter(humanResource => humanResource.instanceId === this.businessArea.getValue().relationships.instance)), options);
  }

  /**
   * Get instant human resources
   */
  public getInstantHumanResources(returnMap = false): OrderedMap<string, CoreHumanResource> | CoreHumanResource[] {
    const map = this.humanResources.getValue();
    return returnMap ? map : map.toArray();
  }

  /**
   * Merge nodes with human resources
   * @param observable
   */
  public mergeWithHumanResource(observable: Observable<TreeNode[]>) {
    return observable.mergeMap(nodes => this.getHumanResources().map(humanResources => nodes.map(node => {
      /* Add responsible data */
      if (!isNullOrUndefined(node.responsibleId) && node.nodeType === NODES_TYPE_HUMANRESOURCE) {
        const humanResource = humanResources.filter(h => '' + h.id === '' + node.responsibleId)[0];
        if (!isNullOrUndefined(humanResource)) {
          node.image = humanResource.image;
          node.name = humanResource.first_name + ' ' + humanResource.last_name;
          node.storypoints = humanResource.storypoints;
          node.email = humanResource.email;
        }
      }
      return node;
    })));
  }

  /**
   * Get the human resources
   * @param observable
   * @param options
   * @param deep
   */
  public getHumanResourcesBy(observable: Observable<OrderedMap<string, CoreHumanResource>>, options?: CoreOptions, deep = true): Observable<Array<CoreHumanResource>> {
    return observable.filter(d => !!d).map(humanResourcesMap => {
      /* First of all convert map to array */
      let humanResources = humanResourcesMap.toArray();
      /* Filter the human resources if a filter is specified */
      if (!isNullOrUndefined(options) && !isNullOrUndefined(options.filters)) {
        humanResources = this.filterHumanResources(humanResources, options.filters, deep);
      }
      /* Return the tree nodes */
      return humanResources;
    });
  }

  /**
   * Filter human resources
   * @param humanResources
   * @param filters
   * @param deep
   */
  public filterHumanResources(humanResources: CoreHumanResource[], filters: CoreFilter[], deep = true) {
    return humanResources.filter(humanResource => !!humanResource && this.filterHumanResource(humanResource, filters));
  }

  /**
   * Filter a human resource by presets or key value combination
   * @param humanResource
   * @param filters
   */
  public filterHumanResource(humanResource: CoreHumanResource, filters: CoreFilter[]): boolean {
    let visible = true;

    const count = filters.length;
    for (let i = 0; i < count; i++) {
      const filter = filters[i];
      switch (filter.by) {
        default:
          visible = isArray(filter.value) ? filter.value.filter(value => humanResource[filter.by] === value).length > 0 : humanResource[filter.by] === filter.value;
          break;
      }
      if (!visible) {
        break;
      }
    }
    return visible;
  }

  /**
   * Get human resources
   * @param options
   */
  public getGroups(options?: CoreOptions): Observable<Array<CoreGroup>> {
    return this.getGroupsBy(this.groups, options);
  }

  /**
   * Get the human resources
   * @param observable
   * @param options
   * @param deep
   */
  public getGroupsBy(observable: Observable<OrderedMap<string, CoreGroup>>, options?: CoreOptions, deep = true): Observable<Array<CoreGroup>> {
    return observable.filter(d => !!d).map(groupsMap => {
      /* First of all convert map to array */
      let groups = groupsMap.toArray();
      /* Filter the human resources if a filter is specified */
      if (!isNullOrUndefined(options) && !isNullOrUndefined(options.filters)) {
        groups = this.filterGroups(groups, options.filters, deep);
      }
      /* Return the tree nodes */
      return groups;
    });
  }

  /**
   * Filter human resources
   * @param groups
   * @param filters
   * @param deep
   */
  public filterGroups(groups: CoreGroup[], filters: CoreFilter[], deep = true) {
    return groups.filter(group => !!group && this.filterGroup(group, filters));
  }

  /**
   * Filter a human resource by presets or key value combination
   * @param group
   * @param filters
   */
  public filterGroup(group: CoreGroup, filters: CoreFilter[]): boolean {
    let visible = true;

    const count = filters.length;
    for (let i = 0; i < count; i++) {
      const filter = filters[i];
      switch (filter.by) {
        default:
          visible = isArray(filter.value) ? filter.value.filter(value => group[filter.by] === value).length > 0 : group[filter.by] === filter.value;
          break;
      }
      if (!visible) {
        break;
      }
    }
    return visible;
  }

  public redrawColorLabelProvider(nodeType: number = -1) {
    this.changeColorLabelProvider(this.colorLabelProviderKey.getValue(), nodeType);
  }

  /**
   * Change the color label provider
   * @param colorLabelProvider
   */
  public changeColorLabelProvider(colorLabelProvider: string, nodeType: number = -1) {
    this.nodeTypes = CoreNodeTypes;
    /* Store the provider */
    if (colorLabelProvider !== this.selectedColorLabelProvider) {
      this.coreUtilities.saveLocal('core-' + this.businessAreaId + '-color-label-provider', colorLabelProvider);
    }
    /* Set the provider */
    this.setColorLabelProvider(colorLabelProvider);
    /* Update the tree nodes */
    AppGlobal.treeNodes = <OrderedMap<string, TreeNode>> AppGlobal.treeNodes.map(treeNode => {
      let colors;
      if(isNullOrUndefined(nodeType) && colorLabelProvider !== 'coloredRelations'){
        colors = this.colorLabelProvider.color(treeNode);
      }else{
        colors = this.colorLabelProvider.color(treeNode, nodeType, this.mcm);
      }
      if(!isNullOrUndefined(colors)){
        treeNode.colors = colors;
      }
      return treeNode;
    });
    /* Update the observable */
    this.treeNodes.next(AppGlobal.treeNodes);
  }

  /**
   * Toggle the legends
   * @param legend
   */
  public onLegendToggled(legend: CoreLegend) {
    this.toggledLegends = this.coreUtilities.toggleInArray(this.toggledLegends, legend.key);
    this.treeNodes.next(this.treeNodes.getValue());
    this.treeRelationships.next(this.treeRelationships.getValue());
  }

  /**
   * Toggle the node types
   * @param nodeType
   */
  public onNodeTypeToggled(nodeType: number) {
    this.filteredNodeTypes = this.coreUtilities.toggleInArray(this.filteredNodeTypes, nodeType);
    this.treeNodes.next(this.treeNodes.getValue());
    this.treeRelationships.next(this.treeRelationships.getValue());
  }

  /**
   * Set the toggled node types
   * @param nodeTypes
   */
  public setFilteredNodeTypes(nodeTypes: number[]) {
    this.filteredNodeTypes = nodeTypes;
  }

  /**
   * Get activities with a set of options
   * @param options
   *    filter      (string)  a filter key either a field of TreeActivity or a custom filter defined
   *    filterValue (any)     The value to check the filter against
   */
  public getActivities(options?: CoreOptions): Observable<Array<TreeActivity>> {
    if (isNullOrUndefined(this.businessAreaId)) {
      this.subscriptionService.add('activities', this.activityService.all().subscribe(activities => {
        this.onDataLoaded([List(), List(), List(), activities, List()], false, true);
      }));
    }
    return this.getActivitiesBy(this.treeActivities.map(treeActivities => {
      if (isNullOrUndefined(treeActivities)) {
        return treeActivities;
      }
      return <OrderedMap<string, TreeActivity>>treeActivities.filter(treeActivity => !treeActivity.nodebucket);
    }), options);
  }

  /**
   * Get activities in hierarchical order with a set of options
   * @param observable
   * @param options
   *    filter      (string)  a filter key either a field of TreeActivity or a custom filter defined
   *    filterValue (any)     The value to check the filter against
   * @param deep
   */
  public getActivitiesBy(observable: Observable<OrderedMap<string, TreeActivity> | TreeActivity[]>, options?: CoreOptions, deep = true): Observable<Array<TreeActivity>> {
    return observable.filter(d => !!d).map(treeActivitiesMap => {
      /* First of all convert map to array */
      let treeActivities = isArray(treeActivitiesMap) ? treeActivitiesMap : treeActivitiesMap.toArray();
      /* Filter the tree nodes if a filter is specified */
      if (!isNullOrUndefined(options) && !isNullOrUndefined(options.filters)) {
        treeActivities = this.filterActivities(treeActivities, options.filters);
      }
      /* Return the tree nodes */
      return treeActivities;
    });
  }

  /**
   * Filter an array of tree activities via key and value combination
   * @param treeActivities
   * @param filters
   */
  public filterActivities(treeActivities: TreeActivity[], filters: CoreFilter[]) {
    return treeActivities.filter(treeActivity => !!treeActivity && this.filterActivity(treeActivity, filters));
  }

  /**
   * Filter a node by presets or key value combination
   * @param treeActivity
   * @param filters
   * @param store
   */
  public filterActivity(treeActivity: TreeActivity, filters: CoreFilter[], store = false): boolean {
    let visible = true;
    const count = filters.length;
    for (let i = 0; i < count; i++) {
      const filter = filters[i];
      switch (filter.by) {
        default:
          visible = isArray(filter.value) ? filter.value.filter(value => treeActivity[filter.by] === value).length > 0 : treeActivity[filter.by] === filter.value;
          break;
      }
      if (!visible) {
        break;
      }
    }
    return visible;
  }

  /**
   * Create business area, models, nodes and relationships
   * @param transfer
   * @param calls
   * @param callback
   */
  public create(transfer: CoreTransfer, callback?: Function) {
    if (!isNullOrUndefined(transfer.businessArea)) {
      this.createByBusinessArea(transfer, result => !!callback ? callback(result) : null);
    } else if (!isNullOrUndefined(transfer.models) && transfer.models.length > 0) {
      this.createByModels(transfer, result => !!callback ? callback(result) : null);
    } else if (!isNullOrUndefined(transfer.activities) && transfer.activities.length > 0) {
      this.createActivities(transfer, result => !!callback ? callback(result) : null);
    } else if (!isNullOrUndefined(transfer.humanResources) && transfer.humanResources.length > 0 && !isNullOrUndefined(transfer.groups) && transfer.groups.length > 0) {
      this.createHumanResourcesAndGroupsAndNodes(transfer, result => !!callback ? callback(result) : null);
    } else if (!isNullOrUndefined(transfer.humanResources) && transfer.humanResources.length > 0) {
      this.createHumanResourcesAndNodes(transfer, result => !!callback ? callback(result) : null);
    } else if (!isNullOrUndefined(transfer.groups) && transfer.groups.length > 0) {
      this.createGroupsAndNodes(transfer, result => !!callback ? callback(result) : null);
    } else if (!isNullOrUndefined(transfer.nodeStructures) && transfer.nodeStructures.length > 0) {
      this.createGrouping(transfer, result => !!callback ? callback(result) : null);
    } else if (!isNullOrUndefined(transfer.nodes) || !isNullOrUndefined(transfer.relationships)) {
      this.createNodesAndRelationshipsByModel(transfer, result => !!callback ? callback(result) : null);
    }
  }

  /**
   * Update nodes and relationships
   * @param data
   */
  public update(data: CoreTransfer): Promise<any> {
    return new Promise<any>(resolve => {

      /* Modify transfer */
      data = this.modifyTransferByType(data, NODES_TYPE_UPDATE);

      let dataUpdated = true;
      let structureUpdated = true;

      /* Update Nodes */
      if (!isNullOrUndefined(data.nodes) && data.nodes.length > 0) {
        dataUpdated = false;
        /* Register listener */
        this.nodeDataService.diff.filter(diff => this.requestWasSuccessful(diff, <IPayload[]>data.nodes, NodeDataAction.UPDATE_SUCCESS)).take(1).subscribe((diff) => {
          dataUpdated = true;
          if (isNullOrUndefined(data.nodeStructures) || data.nodeStructures.length === 0 || structureUpdated) {
            resolve();
          }
        });
        if (this.useGo && this.goUpdate) {
          this.nodeDataService.updateGo((<IPayload[]> data.nodes).map(payload => {
            payload.data = this.prepareDelta(payload.data);
            return payload;
          }));
        } else {
          this.nodeDataService.update(<IPayload[]>data.nodes);
        }
      }

      /* Update Node structures */
      if (!isNullOrUndefined(data.nodeStructures) && data.nodeStructures.length > 0) {
        structureUpdated = false;
        this.nodeStructureService.diff.filter(diff => this.requestWasSuccessful(diff, <IPayload[]>data.nodeStructures, NodeStructureAction.UPDATE_SUCCESS)).take(1).subscribe(() => {
          structureUpdated = true;
          if (isNullOrUndefined(data.nodes) || data.nodes.length === 0 || dataUpdated) {
            resolve();
          }
        });
        if (this.useGo && this.goUpdate) {
          this.nodeStructureService.updateGo(<IPayload[]>data.nodeStructures);
        } else {
          this.nodeStructureService.update(<IPayload[]>data.nodeStructures);
        }
      }

      /* Update Relationships */
      if (!isNullOrUndefined(data.relationships) && data.relationships.length > 0) {
        /* Register listener */
        this.relationshipService.diff.filter(diff => this.requestWasSuccessful(diff, <IPayload[]>data.relationships, RelationshipAction.UPDATE_SUCCESS)).take(1).subscribe(() => {
          resolve();
        });
        if (this.useGo && this.goUpdate) {
          this.relationshipService.updateGo(<IPayload[]>data.relationships);
        } else {
          this.relationshipService.update(<IPayload[]>data.relationships);
        }
      }

      /* Update Activities */
      if (!isNullOrUndefined(data.activities) && data.activities.length > 0) {
        /* Register listener */
        this.activityService.diff.filter(diff => this.requestWasSuccessful(diff, <IPayload[]>data.activities, ActivityAction.UPDATE_SUCCESS)).take(1).subscribe(() => {
          resolve();
        });
        this.activityService.update(<IPayload[]>data.activities);
      }

      /* Update Business area */
      if (!isNullOrUndefined(data.businessArea)) {
        /* Register listener */
        this.businessAreaService.diff.filter(diff => !!diff && diff.action === BusinessareaAction.UPDATE_SUCCESS).take(1).subscribe(() => {
          resolve();
        });
        if (this.useGo && this.goUpdate) {
          this.businessAreaService.updateGo(data.businessArea);
        } else {
          this.businessAreaService.update(data.businessArea);
        }
      }

      /* Update human resources */
      if (!isNullOrUndefined(data.humanResources)) {
        /* Register listener */
        this.humanResourceService.diff.filter(diff => this.requestWasSuccessful(diff, <IPayload[]>data.humanResources, HumanResourceAction.UPDATE_SUCCESS)).take(1).subscribe(() => {
          resolve();
        });
        if (this.useGo && this.goUpdate) {
          this.humanResourceService.updateGo(<IPayload[]>data.humanResources);
        } else {
          this.humanResourceService.update(<IPayload[]>data.humanResources);
        }
      }

      /* Update groups */
      if (!isNullOrUndefined(data.groups)) {
        /* Register listener */
        this.groupService.diff.filter(diff => {
          return !!diff && diff.action === GroupAction.UPDATE_SUCCESS;
        }).take(1).subscribe(diff => {
          resolve();
        });
        this.groupService.update(<IPayload[]>data.groups);
      }

    });
  }

  /**
   * Delete nodes and relationships
   * @param data
   */
  public delete(data: CoreTransfer) {
    return new Promise<any>(resolve => {

      if ((isNullOrUndefined(data.nodes) || data.nodes.length === 0) &&
        (isNullOrUndefined(data.relationships) || data.relationships.length === 0) &&
        (isNullOrUndefined(data.activities) || data.activities.length === 0) &&
        (isNullOrUndefined(data.humanResources) || data.humanResources.length === 0) &&
        (isNullOrUndefined(data.groups) || data.groups.length === 0)) {
        resolve();
        return;
      }

      /* Modify transfer */
      data = this.modifyTransferByType(data, NODES_TYPE_DELETE);

      /* Remove Nodes */
      if (!isNullOrUndefined(data.nodes) && data.nodes.length > 0) {
        /* Ids */
        const ids = (<TreeNode[]>data.nodes).map(node => node.id);
        /* Register listener */
        this.nodeStructureService.diff.filter(diff => this.requestWasSuccessful(diff, ids, NodeStructureAction.REMOVE_SUCCESS)).take(1).subscribe(() => {
          resolve();
        });
        if (this.useGo && this.goUpdate) {
          this.nodeStructureService.removeGo(ids);
        } else {
          this.nodeStructureService.remove(ids);
        }
      }

      /* Remove Relationships */
      if (!isNullOrUndefined(data.relationships) && data.relationships.length > 0) {
        const ids = (<TreeRelationship[]>data.relationships).map(relationship => isString(relationship) ? relationship : relationship.id);
        /* Register listener */
        this.relationshipService.diff.filter(diff => this.requestWasSuccessful(diff, ids, RelationshipAction.REMOVE_SUCCESS)).take(1).subscribe(() => {
          resolve();
        });
        if (this.useGo && this.goUpdate) {
          this.relationshipService.removeGo(ids);
        } else {
          this.relationshipService.remove(ids);
        }
      }

      /* Remove Activities */
      if (!isNullOrUndefined(data.activities) && data.activities.length > 0) {
        const ids = (<TreeActivity[]>data.activities).map(activity => activity.id);
        /* Register listener */
        this.activityService.diff.filter(diff => this.requestWasSuccessful(diff, ids, ActivityAction.REMOVE_SUCCESS)).take(1).subscribe(() => {
          resolve();
        });
        this.activityService.remove(ids);
      }

      /* Delete human resources */
      if (!isNullOrUndefined(data.humanResources)) {
        /* Register listener */
        this.humanResourceService.diff.filter(diff => this.requestWasSuccessful(diff, <IPayload[]>data.humanResources, HumanResourceAction.REMOVE_SUCCESS)).take(1).subscribe(() => {
          resolve();
        });
        if (this.useGo && this.goUpdate) {
          this.humanResourceService.removeGo(<string[]>data.humanResources);
        } else {
          this.humanResourceService.remove(<string[]>data.humanResources);
        }
      }

      /* Delete groups */
      if (!isNullOrUndefined(data.groups)) {
        /* Register listener */
        this.groupService.diff.filter(diff => this.requestWasSuccessful(diff, <IPayload[]>data.groups, GroupAction.REMOVE_SUCCESS)).take(1).subscribe(() => {
          resolve();
        });
        this.groupService.remove(<string[]>data.groups);
      }

    });
  }

  public transfer(transfers: CoreMultiTransfer) {
    return new Promise(resolve => {
      /* The response count */
      let responseCount = 0;
      /* The calls count */
      let callsCount = 0;

      /* Create */
      if (this.checkTransfers(transfers, 'create')) {
        callsCount++;
        this.create(transfers.create, () => {
          responseCount++;
          if (responseCount === callsCount) {
            resolve();
          }
        });
      }

      /* Update */
      if (this.checkTransfers(transfers, 'update')) {
        callsCount++;
        this.update(transfers.update).then(() => {
          responseCount++;
          if (responseCount === callsCount) {
            resolve();
          }
        });
      }

      /* Delete */
      if (this.checkTransfers(transfers, 'delete')) {
        callsCount++;
        this.delete(transfers.delete).then(() => {
          responseCount++;
          if (responseCount === callsCount) {
            resolve();
          }
        });
      }
      if (callsCount === 0) {
        resolve();
      }

    });
  }

  public checkTransfers(transfers: CoreMultiTransfer, type: string) {
    if (!isNullOrUndefined(transfers[type].nodes) && transfers[type].nodes.length > 0) {
      return true;
    }
    if (!isNullOrUndefined(transfers[type].nodeStructures) && transfers[type].nodeStructures.length > 0) {
      return true;
    }
    if (!isNullOrUndefined(transfers[type].activities) && transfers[type].activities.length > 0) {
      return true;
    }
    if (!isNullOrUndefined(transfers[type].relationships) && transfers[type].relationships.length > 0) {
      return true;
    }
    if (!isNullOrUndefined(transfers[type].humanResources) && transfers[type].humanResources.length > 0) {
      return true;
    }
    if (!isNullOrUndefined(transfers[type].groups) && transfers[type].groups.length > 0) {
      return true;
    }
    return false;
  }

  public grouping(transfer: CoreTransfer) {
    return new Promise(resolve => this.createNodesAndRelationships(transfer, () => resolve(), true));
  }

  public getWorkflow(workflowId: string, load = false) {
    if (load) {
      this.workflowService.load(workflowId);
    }
    return this.workflowService.all().filter(items => items.filter(item => item instanceof Node && item.workFlowId === workflowId).size > 0).map(items => {
      let nodes = List<Node>();
      let relationships = List<Relationship>();
      items.forEach(item => {
        if (item instanceof Node) {
          nodes = nodes.push(item);
        } else if (item instanceof Relationship) {
          relationships = relationships.push(item);
        }
      });
      return this.onDataLoaded([[], nodes, relationships, [], []], true).treeNodesMap.filter(treeNode => treeNode.nodeType === NODES_TYPE_WORKFLOW).first();
    });
  }

  /**
   * Get the form by id
   * @param formId
   * @param load
   */
  public getForm(formId: string, load = false) {
    if (load) {
      this.formApiService.load(formId);
    }
    return this.formApiService.all().map(items => {
      let nodes = List<Node>();
      let relationships = List<Relationship>();
      items.forEach(item => {
        if (item instanceof Node) {
          nodes = nodes.push(item);
        } else if (item instanceof Relationship) {
          relationships = relationships.push(item);
        }
      });
      const startNode = this.onDataLoaded([[], nodes, relationships, []], true).treeNodesMap.filter(treeNode => treeNode.nodeType === NODES_TYPE_FORM).first();
      const form = { tabs: [] };
      if (!isNullOrUndefined(startNode)) {
        let i = 1;
        startNode.children.sort((a, b) => a.positionX - b.positionX).forEach((tabTreeNode) => {
          if (tabTreeNode.nodeType === NODES_TYPE_FORM_TAB) {
            form.tabs.push({ entry: { key: 'header-' + i++, label: tabTreeNode.name }, children: this.formService.buildFormEntries(tabTreeNode) });
          }
        });
      }
      return form;
    });
  }

  /**
   * Get instances by hierarchy service
   * @param load
   */
  public getInstances(load = false) {
    if (load) {
      this.hierarchyService.loadActions();
    }
    return this.hierarchyService.all().map(hierarchies => hierarchies.filter((hierarchy: Hierarchy) => hierarchy.instanceType !== INSTANCE_TYPE_LIBRARY).map((hierarchy: Hierarchy) => this.coreTransformer.hierarchyToCoreInstance(hierarchy)));
  }

  /**
   * Load the hierarchy
   */
  public loadHierarchy() {
    this.hierarchyService.loadActions();
  }

  /**
   * Get a specific global modal
   * @param type
   */
  public getModal(type: string) {
    return this.modals.filter(modals => !!modals).map(modals => modals.filter((modal, key) => key === type).first());
  }

  /**
   * Set a specific global modal
   * @param type
   * @param modal
   */
  public setModal(type: string, modal: any) {
    let modals = this.modals.getValue();
    if (isNullOrUndefined(modals)) {
      modals = Map<string, any>();
    }
    this.modals.next(modals.set(type, modal));
  }

  /**
   * Add a modal to main component by lazy loading
   * @param type
   */
  public showModal(type: string) {
    this.openModal.emit(type);
  }

  /**
   * Open a modal dynamically
   * @param id
   */
  public modal(id: string, resolve) {
    this.subscriptionService.add('modal-' + id, this.getModal(id).filter(_ => !!_).subscribe((modal: any) => {
      this.subscriptionService.remove('modal-' + id);
      resolve(modal);
    }));
    this.showModal(id);
  }

  /**
   * Remove the modal from list
   * @param type
   */
  public removeModal(type: string) {
    let modals = this.modals.getValue();
    if (isNullOrUndefined(modals)) {
      modals = Map<string, any>();
    }
    this.modals.next(modals.remove(type));
  }

  /**
   * Get the color label providers
   */
  public getColorLabelProviders(): CoreLabelProvider[] {
    return this.colorLabelProviders;
  }

  /**
   * Screenshot method
   */
  public screenshot(widget: string, filename = 'valueminer-screenshot', options: CoreExportOptions = { screenCSS: true }) {
    return new Promise<any>(resolve => {
      const configuration = {
        url: window.location.href + '/print/' + widget,
        type: 'pdf',
        data: options
      };
      this.exportService.configuration(configuration).filename(filename).puppeteer('.pdf').then(success => resolve(success));
    });
  }

  /**
   * Screenshot method for gantt
   */
  public screenshotGantt(widget: string, filename = 'valueminer-screenshot', width: number, height: number) {
    return new Promise<any>(resolve => {
      const configuration = {
        url: window.location.href + '/print/' + widget,
        type: 'screenshot',
        data: {imageType: 'png', width: width, height: height}
      };
      this.exportService.configuration(configuration).filename(filename).puppeteer('.png').then(success => resolve(success));
    });
  }

  /**
   * Export to Excel method for gantt
   */
  public exportExcel(widget: string, filename: string = 'valueminer-excel', excelConfig?: ExportExcel | any) {
    return new Promise<any>(resolve => {
      this.exportService.configuration({ type: 'excel', }).html(JSON.stringify(excelConfig)).filename(filename).excel().then(success => resolve(success));
    });
  }

  public decodeHtml(html) {
    const txt = document.createElement('textarea');
    txt.innerHTML = html;
    return txt.value;
  }

  public isHTML(str: string): boolean {
    const doc = new DOMParser().parseFromString(str, 'text/html');
    return Array.from(doc.body.childNodes).some(node => node.nodeType === 1);
  }

  public generateOneTimeToken(id: string | string[], formNodeId: string, showModal = true, actionNodeId?: string, dueDate?: string) {
    return new Promise(resolve => {
      const _id = isArray(id) ? id[id.length - 1] : id;
      this.oneTimeTokenService.diff.filter(diff => !!diff && !!diff.action && (diff.action === OneTimeTokenAction.LOAD_SUCCESS || diff.action === OneTimeTokenAction.LOAD_FAIL) && diff.response.attributes.elementId === _id).subscribe(diff => {
        if (showModal) {
          /* Register listener */
          this.subscriptionService.add('modal', this.getModal('form-screen-modal').filter(_ => !!_).subscribe((modal: FormScreenModalComponent) => {
            this.subscriptionService.remove('modal');
            /* Register listener */
            this.subscriptionService.add('modal-form-screen', modal.cancel.subscribe(() => {
              this.subscriptionService.remove('modal-form-screen');
              resolve();
            }));
            /* Get the form node */
            modal.setFormNode(this.treeNodes.getValue().get(formNodeId));
            modal.show(diff.response);
          }));
          this.showModal('form-screen-modal');
        } else {
          resolve(diff.response);
        }
      });
      /* Call endpoint to get token */
      this.nodeStructureService.getOnetimeAccessToken(id, formNodeId, actionNodeId, dueDate);
    });
  }

  public sendOneTimeToken(documentId: string, email: string, name: string, url: string, subject: string, message: string) {
    return new Promise(resolve => {
      this.oneTimeTokenService.diff.filter(diff => !!diff && !!diff.action && (diff.action === OneTimeTokenAction.LOAD_SUCCESS || diff.action === OneTimeTokenAction.LOAD_FAIL) && diff.response.attributes.elementId === documentId).subscribe(diff => {
        const link = window.location.protocol + '//' + window.location.host + url + diff.response.attributes.token;
        subject = subject.replace(/%NAME%/, name).replace(/%LINK%/, link);
        message = message.replace(/%NAME%/, name).replace(/%LINK%/, link);
        this.sendEmail(name + '<' + email + '>', subject, message).then(() => resolve());
      });
      /* Call endpoint to get token */
      this.nodeStructureService.getOnetimeAccessToken(documentId, documentId);
    });
  }

  public loadByToken(token: string, diffCallback?: any) {
    this.subscriptionService.add('token-failed', this.oneTimeTokenService.diff.filter(d => !!d).subscribe(response => {
      if (!isNullOrUndefined(diffCallback)) {
        diffCallback(response);
      }
    }));
    this.subscriptionService.add('loadByToken', Observable.combineLatest(
      this.nodeService.all(),
      this.relationshipService.all()
    ).filter(d => d[0].size > 0).map(d => [[], d[0], d[1], [], []]).subscribe(data => this.onDataLoaded(data)));
    this.oneTimeTokenService.loadByToken(token);
  }

  public updateByToken(token: string, data: IPayload, callback?: any) {
    return new Promise(resolve => {
      this.subscriptionService.add('update-token-diff', this.oneTimeTokenService.diff.filter(d => !!d && (d.action === OneTimeTokenAction.UPDATE_BY_TOKEN_SUCCESS || d.action === OneTimeTokenAction.UPDATE_BY_TOKEN_FAIL)).subscribe(response => {
        if (!isNullOrUndefined(callback)) {
          callback(response);
        }
        resolve(response);
      }));
      this.oneTimeTokenService.updateByToken(token, data);
    });
  }

  /**
   * Send out an email
   *
   * @param recipient string email Id of the recipient or in the format - Name <emailId>
   * @param subject object containing the translation key and params for getting the subject of the email
   * @param body object containing the translation key and params for getting the body of the email
   * @param from string email Id of the sender or in the format - Name <emailId>
   */
  public sendEmail(recipient: string, subject: string, message: string, from?: string, cc?: string[]) {
    return new Promise(resolve => {
      /* Response */
      this.subscriptionService.add('send-email', this.emailService.diff.filter(diff => !!diff && (diff.action === EmailAction.SEND_EMAIL_SUCCESS || diff.action === EmailAction.SEND_EMAIL_FAIL)).subscribe(diff => {
        this.subscriptionService.remove('send-email');
        resolve();
      }));
      /* Send email */
      this.emailService.sendEmail([<Email>{
        recipient: recipient,
        message: message,
        subject: subject,
        from: from,
        cc: cc,
      }]);
    });
  }

  public socketNodeStructuresUpdated(data: CoreSocketData) {
    this.nodeDataService.autorefreshCreate({ request: {}, response: data.included.filter(datum => datum.type === 'nodedata') });
    this.relationshipService.autorefreshCreate({ request: {}, response: data.included.filter(datum => datum.type === 'relationships') });
    this.nodeStructureService.autorefreshCreate({ request: {}, response: data.data });
  }

  public showHumanResourceCard(formFields: TreeNode[], humanResource = <CoreHumanResource>{ first_name: '', last_name: '', email: '', image: '' }, editable = false) {
    return new Promise<FormResult>(resolve => {
      this.subscriptionService.add('human-resource-card-modal', this.getModal('card-modal').filter(_ => !!_).subscribe((cardModal: CardModalComponent) => {
        /* Settings */
        cardModal.showImage = true;
        cardModal.showForm = isNullOrUndefined(humanResource.id) || editable;
        cardModal.editMode = editable;
        /* Set form */
        cardModal.formInterface = { tabs: [{ entry: { key: 'header-1', label: '' }, children: this.formService.buildFormEntriesFromTreeNodes(formFields) }] };
        /* Set element */
        cardModal.humanResource = humanResource;

        /* Listen to event */
        this.subscriptionService.add('create-update', cardModal.update.subscribe((result: FormResult) => {
          result = this.coreUtilities.mapFormResult(result);
          // load the default HR image /assets/png/default-person.png
          const toDataURL = url => fetch(url)
            .then(response => response.blob())
            .then(blob => new Promise((resolve_, reject) => {
              const reader = new FileReader();
              reader.onloadend = () => resolve_(reader.result);
              reader.onerror = reject;
              reader.readAsDataURL(blob);
            }));
          const imageData = result.delta.get('image');
          if (!imageData) {
            toDataURL('/assets/png/default-person.png')
              .then(dataUrl => {
                if (dataUrl) {
                  result.delta = result.delta.set('image', dataUrl);
                }
                resolve(result);
                cardModal.dismiss();
              });
          } else {
            resolve(result);
            cardModal.dismiss();
          }
        }));

        /* Open modal */
        this.subscriptionService.remove('human-resource-card-modal');
        cardModal.show();
      }));
      this.openModal.emit('card-modal');
    });
  }

  public getDirectChain(treeNode: TreeNode, toArray = true, dataId = false, modelId?: string, directChainNode?: TreeNode) {
    let parents = [];
    let children = [];

    if (!isNullOrUndefined(directChainNode)) {
      const parentDirectChainNode = directChainNode.children.filter(child => child.nodeType === NODES_TYPE_PARENT)[0];
      if (!isNullOrUndefined(parentDirectChainNode)) {
        parents = this.coreUtilities.getTreeByTreeStructure(parentDirectChainNode, [treeNode], undefined, true).map(parent => parent.id);
      }
      const childDirectChainNode = directChainNode.children.filter(child => child.nodeType === NODES_TYPE_CHILD)[0];
      if (!isNullOrUndefined(childDirectChainNode)) {
        children = this.coreUtilities.getTreeByTreeStructure(childDirectChainNode, [treeNode], undefined, true).map(child => child.id);
      }
    } else {
      parents = this.getChainIds(treeNode, 'unfilteredParents', dataId, modelId);
      children = this.getChainIds(treeNode, 'unfilteredChildren', dataId, modelId);
    }
    const chain = Set(parents).merge(Set(children));
    return toArray ? chain.toArray() : chain;
  }

  /**
   * Get filters by tree node configuration
   * @param filterNodes
   * @param treeNodes
   */
  public getFilters(filterNodes: TreeNode[], treeNodes?: TreeNode[]): CoreWidgetFilter[] {
    const filters = [];
    const count = filterNodes.length;
    for (let i = 0; i < count; i++) {
      const filterNode = filterNodes[i];
      const filter = <CoreWidgetFilter> { id: filterNode.id, label: filterNode.name, field: '', checkboxes: [] };
      const ids = [];
      switch (filterNode.nodeType) {
        case NODES_TYPE_PARENT:
          filter.field = 'parents';
          const nodeTypes = filterNode.children.map(child => child.nodeType);
          if (!isNullOrUndefined(treeNodes)) {
            const count1 = treeNodes.length;
            for (let i1 = 0; i1 < count1; i1++) {
              const treeNode = treeNodes[i1];
              const count2 = treeNode.unfilteredParents.length;
              for (let i2 = 0; i2 < count2; i2++) {
                const parent = treeNode.unfilteredParents[i2];
                if (nodeTypes.indexOf(parent.nodeType) !== -1 && ids.indexOf(parent.id) === -1) {
                  filter.checkboxes.push(<CoreWidgetFilterCheckbox> { id: UUID.UUID(), label: parent.name, value: parent.id, checked: true, treeNode: parent });
                  ids.push(parent.id);
                }
              }
            }
          }
          break;
        case NODES_TYPE_FIELD:
          /* Get all possible values */
          /* Check if there is priority score field */
          if (filterNode.formFieldControlType === 'priority-score') {
            const fieldNodes = this.searchBy({ filters: [{ by: 'nodeType', value: NODES_TYPE_FIELD }] }, filterNode.children);
            if (fieldNodes.length > 0) {
              this.eisenhowerService.setMatrix(fieldNodes);
            }
            filter.field = 'sorting';
          }
          Set(treeNodes.map(treeNode => this.formService.getReadableValue(filterNode, treeNode))).forEach(value => {
            filter.checkboxes.push(<CoreWidgetFilterCheckbox> { id: UUID.UUID(), label: value, value: value, checked: true, treeNode: value });
          });
          break;
      }
      filters.push(filter);
    }
    return filters;
  }

  public loadGlobalFilter() {
    /* Load the global filters */
    this.loadFilters = this.coreUtilities.loadLocal('core-' + this.businessAreaId + '-global-filters', this.loadFilters);
    this.appGlobal.globalFilterChainedFilters = this.coreUtilities.loadLocal('filter-chained', this.appGlobal.globalFilterChainedFilters);

    if (!isNullOrUndefined(this.loadFilters)) {
      for (const [key, value] of Object.entries(this.loadFilters)) {
        this.globalFilters = this.globalFilters.set(key, <CoreGlobalFilter>value);
        this.globalFilterKeys = this.globalFilterKeys.add(key);
      }
      /* Update global filters */
      this.updateGlobalFilters();
    }
  }

  public addGlobalFilter(filters: CoreGlobalFilter | CoreGlobalFilter[], eventEmitter: EventEmitter<any> = null) {
    if (isNullOrUndefined(this.clearFilter) && !isNullOrUndefined(eventEmitter)) {
      this.clearFilter = eventEmitter;
    }
    if (!isArray(filters)) {
      filters = [filters];
    }
    const count = filters.length;
    for (let i = 0; i < count; i++) {
      /* Filter */
      const filter = filters[i];
      /* Set the global filter */
      this.globalFilters = this.globalFilters.set(filter.id, filter);
      /* Update keys */
      this.globalFilterKeys = this.globalFilterKeys.add(filter.id);
    }
    /* Update global filters */
    this.updateGlobalFilters();
  }

  public removeGlobalFilter(id: string) {
    /* Set the global filter */
    this.globalFilters = this.globalFilters.remove(id);
    /* Update keys */
    this.globalFilterKeys = this.globalFilterKeys.remove(id);
    /* Update global filters */
    this.updateGlobalFilters();
  }

  public removeAllGlobalFilter() {
    /* Set the global filter */
    if (!isNullOrUndefined(this.clearFilter)){
      this.clearFilter.emit(null);
    }
      this.globalFilters = this.globalFilters.clear();
      /* Update keys */
      this.globalFilterKeys = this.globalFilterKeys.clear();
      /* Update global filters */
      this.updateGlobalFilters();
      /* Save */
      this.coreUtilities.clearLocal('core-' + this.businessAreaId + '-global-filters', 'core-' + this.businessAreaId + '-global-filter-keys', 'global-filter-selected');

  }

  public getGlobalFilterIds() {
    return this.globalFilterIds;
  }

  public getGlobalFilter() {
    return this.globalFilterIdsEmitter;
  }

  public isElementAssigned(dataId: string, treeNode: TreeNode, connectNodeType = NODES_TYPE_CONNECT) {
    return !isNullOrUndefined(this.getElementAssignedNode(dataId, treeNode, connectNodeType));
  }

  public getElementAssignedNode(dataId: string, treeNode: TreeNode, connectNodeType = NODES_TYPE_CONNECT) {
    let result: TreeNode;
    const connectNodes = treeNode.unfilteredChildren.filter(child => child.nodeType === connectNodeType);
    const count = connectNodes.length;
    for (let i = 0; i < count; i++) {
      const connectNode = connectNodes[i];
      if (connectNode.unfilteredParents.filter(parent => parent.dataId === dataId).length > 0) {
        result = connectNode;
        break;
      }
    }
    return result;
  }

  public isCreated(treeNodeId: string, callback: Function) {
    this.isCreatedMap = this.isCreatedMap.set(treeNodeId, callback);
    return this;
  }

  public updateGlobalFilters(callback?: Function) {
    this.clearCache(true);
    /* Filter tree nodes */
    if (this.globalFilters.size === 0) {
      this.globalFilterIds = undefined;
    } else {
      this.globalFilterIds = this.getTreeNodesByGlobalFilters(this.appGlobal.globalFilterHierarchy.getValue());
    }
    this.globalFilterIdsEmitter.next(this.globalFilterIds);
    /* Update Subjects */
    this.treeNodes.next(this.treeNodes.getValue());
    this.treeRelationships.next(this.treeRelationships.getValue());
    /* Save */
    this.coreUtilities.saveLocal('core-' + this.businessAreaId + '-global-filters', this.globalFilters.toJS()).then(() => {
      this.coreUtilities.saveLocal('core-' + this.businessAreaId + '-global-filter-keys', this.globalFilterKeys.toJS()).then(() => !!callback ? callback() : {});
    });
  }

  public guardian(guardianData: GuardianData) {
    return new Promise<GuardianResponseData | HttpErrorResponse>(resolve => {
      this.subscriptionService.add('guardian-find', this.guardianService.diff.filter(d => !!d && (d.action === GuardianAction.FIND_SUCCESS || d.action === GuardianAction.FIND_FAIL)).subscribe(diff => {
        this.subscriptionService.remove('guardian-find');
        resolve(diff.response);
      }));
      this.guardianService.findInfractions(guardianData);
    });
  }

  public findMyNodeIds() {
    return new Promise<string[] | HttpErrorResponse>(resolve => {
      this.subscriptionService.add('my-find', this.myService.diff.filter(d => !!d && (d.action === MyAction.FIND_SUCCESS || d.action === MyAction.FIND_FAIL)).subscribe(diff => {
        this.subscriptionService.remove('my-find');
        resolve(diff.response);
      }));
      this.myService.findMyNodes(this.businessAreaId);
    });
  }

  public aiGetSimilarity(input: string[], limit = 0) {
    return new Promise<Similarity[] | boolean>(resolve => {
      /* Define the request id */
      const requestId = UUID.UUID();
      /* Register listener */
      this.subscriptionService.add('aiSimilarityDiff', this.aiService.diff.filter(d => !!d && !!d.action && d.payload === requestId).subscribe(diff => {
        resolve(diff.action === AiAction.SIMILARITY_SUCCESS ? diff.response : false);
      }));
      /* Do the call */
      this.aiService.getSimilarityIndices(requestId, input, limit);
    })
  }

  private getTreeNodesByGlobalFilters(hierarchy: boolean, chainCache = Map<string, Set<string>>()) {
    /* The result tree nodes */
    let chainIds: Set<string>;
    /* Get the tree nodes and filter out the mcm nodes */
    let treeNodes = <OrderedMap<string, TreeNode>> this.treeNodes.getValue();
    if (!isNullOrUndefined(treeNodes)) {
      /* Filter tree nodes */
      treeNodes = <OrderedMap<string, TreeNode>> treeNodes.filter(treeNode => treeNode.modelId !== this.mcm);
      const sortedFilters = this.globalFilters.sort((a, b) => a.order - b.order);
      /* Iterate over filters */
      sortedFilters.forEach(globalFilter => {
        if (globalFilter.type === 'my') {
          chainIds = treeNodes.filter(treeNode => {
            return globalFilter.value.indexOf(treeNode.id) !== -1 || treeNode.children.filter(child => globalFilter.value.indexOf(child.id) !== -1).length > 0;
          }).map(treeNode => treeNode.id).toSet();
        } else if (globalFilter.type === 'byField' && this.appGlobal.globalFilterChainedFilters.indexOf(globalFilter.widgetId) === -1) {
          if (isNullOrUndefined(chainIds)) {
            chainIds = treeNodes.filter(treeNode => {
              const index = globalFilter.value.map(v => '' + v).indexOf('' + treeNode[globalFilter.key.split(':')[0]]);
              return this.appGlobal.globalFilterUseSubtract.getValue().indexOf(globalFilter.widgetId) !== -1 ? index === -1 : index !== -1;
            }).map(treeNode => treeNode.id).toSet();
          } else {
            if (!hierarchy) {
              chainIds = chainIds.concat(treeNodes.filter(treeNode => {
                const index = globalFilter.value.map(v => '' + v).indexOf('' + treeNode[globalFilter.key.split(':')[0]]);
                return this.appGlobal.globalFilterUseSubtract.getValue().indexOf(globalFilter.widgetId) !== -1 ? index === -1 : index !== -1;
              }).map(treeNode => treeNode.id)).toSet();
            } else {
              chainIds = treeNodes.filter(treeNode => {
                if (chainIds.has(treeNode.id)) {
                  const index = globalFilter.value.map(v => '' + v).indexOf('' + treeNode[globalFilter.key.split(':')[0]]);
                  return this.appGlobal.globalFilterUseSubtract.getValue().indexOf(globalFilter.widgetId) !== -1 ? index === -1 : index !== -1;
                }
              }).map(treeNode => treeNode.id).toSet();
            }
          }
        } else if (globalFilter.type === 'byField' && this.appGlobal.globalFilterChainedFilters.indexOf(globalFilter.widgetId) !== -1) {
          if (isNullOrUndefined(chainIds)) {
            const filteredNodes = treeNodes.filter(treeNode => {
              const index = globalFilter.value.map(v => '' + v).indexOf('' + treeNode[globalFilter.key.split(':')[0]]);
              return this.appGlobal.globalFilterUseSubtract.getValue().indexOf(globalFilter.widgetId) !== -1 ? index === -1 : index !== -1;
            }).toArray()
            chainIds = Set(this.coreUtilities.getDirectChain(filteredNodes).ids);
          } else {
            if (!hierarchy) {
              const filteredNodes = treeNodes.filter(treeNode => {
                const index = globalFilter.value.map(v => '' + v).indexOf('' + treeNode[globalFilter.key.split(':')[0]]);
                return this.appGlobal.globalFilterUseSubtract.getValue().indexOf(globalFilter.widgetId) !== -1 ? index === -1 : index !== -1;
              }).toArray()
              chainIds = Set(chainIds.concat(this.coreUtilities.getDirectChain(filteredNodes).ids));
            } else {
              const filteredNodes = treeNodes.filter(treeNode => {
                if (chainIds.has(treeNode.id)) {
                  const index = globalFilter.value.map(v => '' + v).indexOf('' + treeNode[globalFilter.key.split(':')[0]]);
                  return this.appGlobal.globalFilterUseSubtract.getValue().indexOf(globalFilter.widgetId) !== -1 ? index === -1 : index !== -1;
                }
              }).toArray()
              chainIds = Set(this.coreUtilities.getDirectChain(filteredNodes).ids.filter(e => chainIds.has(e)));

            }
          }
        } else {
          let filteredIds: string[];
          const filteredTreeNodes = treeNodes.filter(treeNode => !hierarchy || isNullOrUndefined(chainIds) || chainIds.has(treeNode.id));
          if (hierarchy) {
            if (!isNullOrUndefined(chainIds)) {
              filteredIds = chainIds.toArray();
            }
            chainIds = undefined;
          }
          filteredTreeNodes.forEach(treeNode => {
            /* Get values */
            const result = this.getChainIdsByGlobalFilter(treeNode, globalFilter, chainCache);
            /* Set values */
            chainIds = isNullOrUndefined(chainIds) ? result.chainIds : <Set<string>> chainIds.merge(result.chainIds).filter(id => isNullOrUndefined(filteredIds) || filteredIds.indexOf(id) !== -1);
            chainCache = result.chainCache;
          });
        }
      });
    }
    /* Return result */
    return isNullOrUndefined(chainIds) ? [] : chainIds.toArray();
  }

  private getChainIdsByGlobalFilter(treeNode: TreeNode, globalFilter: CoreGlobalFilter, chainCache: Map<string, Set<string>>) {
    let chainIds = Set<string>();
    let visible = false;
    switch (globalFilter.type) {
      case 'byElement':
        visible = treeNode.id === globalFilter.value;
        break;
      case 'byField':
        visible = globalFilter.value.indexOf(treeNode[globalFilter.key]) !== -1;
        break;
      case 'byNodeType':
        visible = treeNode.nodeType === globalFilter.value;
        break;
      case 'bar-date-range':
        /* Bar data range */
        const data = <BarDataRangeInterface> globalFilter.value;
        const start = treeNode[data.startField];
        const end = treeNode[data.endField];
        const m1 = isNullOrUndefined(data.startValue) ? undefined : moment(data.startValue);
        const m2 = isNullOrUndefined(start) || start === '' ? undefined : moment(start);
        const m3 = isNullOrUndefined(data.endValue) ? undefined : moment(data.endValue);
        const m4 = isNullOrUndefined(end) || end === '' ? undefined : moment(end);

        if (!isNullOrUndefined(m1) && !isNullOrUndefined(m3)) {
          /* Start & End Range */
          visible = !isNullOrUndefined(m2) && !isNullOrUndefined(m4) && (m2.unix() <= m3.unix() || m4.unix() >= m1.unix());
        } else if (!isNullOrUndefined(m1)) {
          /* Start Range */
          visible = !isNullOrUndefined(m2) && m2.unix() > m1.unix();
        } else if (!isNullOrUndefined(m3)) {
          /* End Range */
          visible = !isNullOrUndefined(m4) && m4.unix() <= m3.unix();
        } else {
          visible = false;
        }
        break;
    }
    if (visible) {
      if (chainCache.has(treeNode.id)) {
        chainIds = chainCache.get(treeNode.id);
      } else {
        chainIds = isNullOrUndefined(globalFilter.directChain) || globalFilter.directChain === true ? <Set<string>> this.getDirectChain(treeNode, false) : Set([treeNode.id]);
        chainCache = chainCache.set(treeNode.id, chainIds);
      }
    }
    return { chainIds: chainIds, chainCache: chainCache };
  }

  private getChainIds(treeNode: TreeNode, direction: string, dataId = false, modelId?: string, ids = []) {
    ids.push(dataId ? treeNode.dataId : treeNode.id);
    const treeNodes = treeNode[direction];
    const count = treeNodes.length;
    for (let i = 0; i < count; i++) {
      const treeNode = treeNodes[i];
      if (isNullOrUndefined(modelId) || treeNode.modelId === modelId) {
        ids = this.getChainIds(treeNode, direction, dataId, modelId, ids);
      }
    }
    return ids;
  }

  private isVisibleByGlobalFilter(treeNode: TreeNode, globalFilter: CoreGlobalFilter) {
    let visible = false;
    switch (globalFilter.type) {
      case 'byElement':
        visible = treeNode.id === globalFilter.value;
        break;
      case 'byField':
        visible = globalFilter.value.indexOf(treeNode[globalFilter.key]) !== -1;
        break;
      case 'byNodeType':
        visible = treeNode.nodeType === globalFilter.value;
        break;
      case 'bar-date-range':
        /* Bar data range */
        const data = <BarDataRangeInterface> globalFilter.value;
        const start = treeNode[data.startField];
        const end = treeNode[data.endField];
        const m1 = isNullOrUndefined(data.startValue) ? undefined : moment(data.startValue);
        const m2 = isNullOrUndefined(start) || start === '' ? undefined : moment(start);
        const m3 = isNullOrUndefined(data.endValue) ? undefined : moment(data.endValue);
        const m4 = isNullOrUndefined(end) || end === '' ? undefined : moment(end);

        if (!isNullOrUndefined(m1) && !isNullOrUndefined(m3)) {
          /* Start & End Range */
          visible = !isNullOrUndefined(m2) && !isNullOrUndefined(m4) && (m2.unix() <= m3.unix() || m4.unix() >= m1.unix());
        } else if (!isNullOrUndefined(m1)) {
          /* Start Range */
          visible = !isNullOrUndefined(m2) && m2.unix() > m1.unix();
        } else if (!isNullOrUndefined(m3)) {
          /* End Range */
          visible = !isNullOrUndefined(m4) && m4.unix() <= m3.unix();
        } else {
          visible = false;
        }
        break;
    }
    return visible;
  }

  /**
   * Initialise the core service
   */
  private initialise() {
    /* Initialise the color label provider */
    this.selectedColorLabelProvider = this.coreUtilities.loadLocal('core-' + this.businessAreaId + '-color-label-provider', '');
    this.setColorLabelProvider(this.selectedColorLabelProvider);
  }

  private bindTreeNodesListener() {
    /* Listen to node structure update */
    this.subscriptionService.add('tree-node-structures', this.nodeStructureService.all().subscribe(nodeStructures => this.onTreeNodeStructuresLoaded(nodeStructures)));
    /* Listen to node data update */
    this.subscriptionService.add('tree-node-data', this.nodeDataService.all().subscribe(nodeData => this.onTreeNodeDataLoaded(nodeData)));
    /* Listen relationship updates */
    this.subscriptionService.add('relationships-data', this.relationshipService.all().subscribe(relationships => this.onTreeRelationshipsLoaded(relationships)));
    /* Listen to node structure delete */
    this.subscriptionService.add('tree-node-structures-delete', this.nodeStructureService.diff.filter(d => d.action === NodeStructureAction.REMOVE_SUCCESS).subscribe(diff => this.onTreeDeleteNodeStructures(diff)));
    /* Listen to relationship delete */
    this.subscriptionService.add('tree-relationship-delete', this.relationshipService.diff.filter(d => d.action === RelationshipAction.REMOVE_SUCCESS).subscribe(diff => this.onTreeDeleteRelationship(diff)));
    /* Listen to activity update */
    this.subscriptionService.add('tree-activities', this.activityService.all().subscribe(activities => this.onTreeActivitiesLoaded(activities)));
    /* Listen to human resource update */
    this.subscriptionService.add('tree-human-resource', this.humanResourceService.all().subscribe(humanResources => this.onTreeHumanResourcesLoaded(humanResources)));
    /* Load all data */
    this.subscriptionService.add('load-tree', this.treeService.getAllByBusinessArea(parseInt(this.businessAreaId)).subscribe((treeData: any[]) => this.onTreeDataLoaded(treeData)));
  }

  private onTreeDeleteNodeStructures(diff: RequestDiffRecord) {
    this.clearCache(true);
    const count = diff.payload.data.length;
    for (let i = 0; i < count; i++) {
      AppGlobal.treeNodes = AppGlobal.treeNodes.remove(diff.payload.data[i]);
    }
    if (count > 0) {
      this.treeNodes.next(AppGlobal.treeNodes);
    }
  }

  private onTreeDeleteRelationship(diff: RequestDiffRecord) {
    this.clearCache(true);
    const count = diff.response.length;
    let event = false;
    let treeRelationships = this.treeRelationships.getValue();
    for (let i = 0; i < count; i++) {
      const relationship = diff.response[i];
      treeRelationships = treeRelationships.remove(<any> parseInt(relationship.id));

      const parentId = relationship.relationships.parent.data.id;
      const childId = relationship.relationships.child.data.id;

      const parent = AppGlobal.treeNodes.get(parentId);
      if (!isNullOrUndefined(parent)) {
        parent.childIds = parent.childIds.filter(c => '' + c !== '' + childId);
        if (!isNullOrUndefined(parent.unfilteredChildIds)) {
          parent.unfilteredChildIds = parent.unfilteredChildIds.filter(c => '' + c !== '' + childId);
        }
        AppGlobal.treeNodes = AppGlobal.treeNodes.set(parent.id, parent);
        event = true;
      }

      let child = AppGlobal.treeNodes.get(childId);
      if (!isNullOrUndefined(child)) {
        child.parentIds = child.parentIds.filter(p => '' + p !== '' + parentId);
        if (!isNullOrUndefined(child.unfilteredParentIds)) {
          child.unfilteredParentIds = child.unfilteredParentIds.filter(p => '' + p !== '' + parentId);
        }
        child = this.onTreeRelationshipsSubLevel(child);
        AppGlobal.treeNodes = AppGlobal.treeNodes.set(child.id, child);
        event = true;
      }
    }
    this.treeRelationships.next(treeRelationships);
    if (event) {
      this.treeNodes.next(AppGlobal.treeNodes);
    }
  }

  private onTreeActivitiesLoaded(activities: List<Activity>) {
    activities.forEach(activity => {
      let treeActivity: TreeActivity = AppGlobal.treeActivities.get(activity.id);
      if (isNullOrUndefined(treeActivity)) {
        treeActivity = this.coreTransformer.treeActivityToTreeActivity(activity);
      } else {
        treeActivity = Object.assign(treeActivity, this.coreTransformer.treeActivityToTreeActivity(activity));
      }
      AppGlobal.treeActivities = AppGlobal.treeActivities.set(treeActivity.id, treeActivity);
      const treeNode = AppGlobal.treeNodes.filter(treeNode => '' + treeNode.dataId === '' + treeActivity.activitable_id).first();
      if (!isNullOrUndefined(treeNode) && isNullOrUndefined(treeNode.activityIds)) {
        treeNode.activityIds = [parseInt(treeActivity.id)];
        AppGlobal.treeNodes = AppGlobal.treeNodes.set(treeNode.id, treeNode);
      } else if (!isNullOrUndefined(treeNode) && treeNode.activityIds.filter(a => '' + a === '' + treeActivity.id).length === 0) {
        treeNode.activityIds.push(parseInt(treeActivity.id));
        AppGlobal.treeNodes = AppGlobal.treeNodes.set(treeNode.id, treeNode);
      }
    });
    this.treeActivities.next(AppGlobal.treeActivities);
    this.treeNodes.next(AppGlobal.treeNodes);
  }

  private onTreeHumanResourcesLoaded(humanResources: List<HumanResource>) {
    let humanResourceMap = this.humanResources.getValue();
    humanResources.forEach(humanResource => {
      humanResourceMap = humanResourceMap.set('' + humanResource.id, this.coreTransformer.humanResourceToCoreHumanResource(humanResource));
    });
    this.humanResources.next(humanResourceMap);
  }

  private onTreeNodeStructuresLoaded(nodeStructures: List<NodeStructure>) {
    this.clearCache(true);
    let event = false;
    nodeStructures.forEach(nodeStructure => {
      let treeNode: TreeNode = AppGlobal.treeNodes.get('' + nodeStructure.id);
      if (isNullOrUndefined(treeNode)) {
        treeNode = this.coreTransformer.nodeStructureToTreeNode(nodeStructure);
      } else {
        treeNode = Object.assign(treeNode, this.coreTransformer.nodeStructureToTreeNode(nodeStructure), { childIds: treeNode.childIds, parentIds: treeNode.parentIds, unfilteredChildIds: treeNode.unfilteredChildIds, unfilteredParentIds: treeNode.unfilteredParentIds });
        treeNode.reference = isNullOrUndefined(treeNode.reference) || treeNode.reference === '0' || treeNode.reference === '' ? treeNode.id : treeNode.reference;
      }

      if (isString(treeNode.modelId)) {
        treeNode.modelId = parseInt(treeNode.modelId);
      }
      if (isSet(treeNode.models)) {
        treeNode.models = (<any> treeNode.models).toArray();
      }
      if (isSet(treeNode.nodestructures)) {
        treeNode.nodestructures = (<any> treeNode.nodestructures).toArray();
      }
      AppGlobal.treeNodes = AppGlobal.treeNodes.set(treeNode.id, treeNode);
      if (!event && !isNullOrUndefined(treeNode.dataId)) {
        event = true;
      }
    });
    if (event) {
      this.treeNodes.next(AppGlobal.treeNodes);
    }
  }

  private onTreeNodeDataLoaded(nodeData: List<NodeData>) {
    this.clearCache(true);
    nodeData.forEach(nodeDatum => {
      const nodeStructures = !isNullOrUndefined((<any> nodeDatum.relationships.nodestructures).data) ? (<any> nodeDatum.relationships.nodestructures).data : (<any> nodeDatum.relationships.nodestructures).toArray();
      const count = nodeStructures.length;
      for (let i = 0; i < count; i++) {
        const nodeStructure = isObject(nodeStructures[i]) ? nodeStructures[i].id.split(':')[1] : nodeStructures[i].split(':')[1];
        let treeNode: TreeNode = AppGlobal.treeNodes.get(nodeStructure);
        if (isNullOrUndefined(treeNode)) {
          treeNode = this.coreTransformer.nodeDataToTreeNode(nodeDatum, true);
          treeNode.id = nodeStructure;
        } else {
          const structureId = treeNode.id;
          treeNode = Object.assign(treeNode, this.coreTransformer.nodeDataToTreeNode(nodeDatum), { dataId: nodeDatum.id, id: structureId, createdAt: treeNode.createdAt, childIds: treeNode.childIds, parentIds: treeNode.parentIds, unfilteredChildIds: treeNode.unfilteredChildIds, unfilteredParentIds: treeNode.unfilteredParentIds, reference: treeNode.reference });
        }
        if (!isNullOrUndefined(this.colorLabelProvider)) {
          treeNode.colors = this.colorLabelProvider.color(treeNode);
          treeNode.invertedColors = treeNode.colors.map(color => this.coreUtilities.invertColor(color, true));
        }
        if (isString(treeNode.nodeType)) {
          treeNode.nodeType = parseInt(treeNode.nodeType);
        }
        if (treeNode.nodestructures instanceof Set) {
          treeNode.nodestructures = (<any> treeNode.nodestructures).toArray();
        }
        AppGlobal.treeNodes = AppGlobal.treeNodes.set(treeNode.id, treeNode);
      }
    });
    this.treeNodes.next(AppGlobal.treeNodes);
    /* Update global filters */
    if (this.globalFilters.size > 0) {
      this.updateGlobalFilters();
    } else {
      this.treeNodes.next(AppGlobal.treeNodes);
    }
  }

  private onTreeRelationshipsLoaded(relationships: List<Relationship>) {
    this.clearCache(true);
    let event = false;
    let treeRelationships = this.treeRelationships.getValue();
    relationships.forEach(relationship => {
      /* Parent */
      const parent = AppGlobal.treeNodes.get(relationship.relationships.parent);
      let child = AppGlobal.treeNodes.get(relationship.relationships.child);

      /* Mapping */
      if (!isNullOrUndefined(parent)) {
        if (isNullOrUndefined(parent.childIds)) {
          parent.childIds = [];
        }
        (<any> parent.childIds).push(parseInt(relationship.relationships.child));
        if (isNullOrUndefined(parent.unfilteredChildIds)) {
          parent.unfilteredChildIds = [];
        }
        (<any> parent.unfilteredChildIds).push(parseInt(relationship.relationships.child));
        AppGlobal.treeNodes.get(parent.id, parent);
        event = true;
      }
      if (!isNullOrUndefined(child)) {
        if (isNullOrUndefined(child.parentIds)) {
          child.parentIds = [];
        }
        (<any> child.parentIds).push(parseInt(relationship.relationships.parent));
        if (isNullOrUndefined(child.unfilteredParentIds)) {
          child.unfilteredParentIds = [];
        }
        (<any> child.unfilteredParentIds).push(parseInt(relationship.relationships.parent));
        if (!isNullOrUndefined(parent) && child.subLevel <= parent.subLevel) {
          child.subLevel = parent.subLevel + 1;
          child = this.onTreeRelationshipsSubLevel(child);
        }
        AppGlobal.treeNodes.get(child.id, child);
        event = true;
      }
      /* Add relationship */
      const treeRelationship = this.coreTransformer.relationshipToTreeRelationship(relationship);
      treeRelationship.modelId = <any> parseInt(treeRelationship.modelId);
      treeRelationships = treeRelationships.set(relationship.id, treeRelationship);
    });

    if (!isNullOrUndefined(treeRelationships) && event) {
      this.treeRelationships.next(treeRelationships);
      this.treeNodes.next(AppGlobal.treeNodes);
    }
    /* Update global filters */
    if (this.globalFilters.size > 0) {
      this.updateGlobalFilters();
    }
  }

  private onTreeRelationshipsSubLevel(treeNode: TreeNode) {
    treeNode.unfilteredChildren.map(unfilteredChild => {
      if (unfilteredChild.subLevel <= treeNode.subLevel) {
        unfilteredChild.subLevel = treeNode.subLevel + 1;
        if (!isNullOrUndefined(treeNode.unfilteredChildren) && treeNode.unfilteredChildren.length > 0) {
          unfilteredChild = this.onTreeRelationshipsSubLevel(unfilteredChild);
        }
      }
      return unfilteredChild;
    });
    return treeNode;
  }

  /**
   * Called as the business area has been loaded
   * @param businessArea
   */
  private onBusinessAreaLoaded(businessArea: Businessarea) {
    /* Checksum */
    if (this.businessAreaCheckSum !== this.coreUtilities.getCheckSum(businessArea)) {

      /* Update the unfiltered business area */
      this.businessArea.next(businessArea);
    }
  }

  /**
   * Called as the model has been loaded
   * @param models
   */
  private onModelsLoaded(models: Model[]): string[] {
    const ids = [];
    let map = this.models.getValue();
    if (isNullOrUndefined(map)) {
      map = OrderedMap<string, Model>();
    }
    /* Iterate over the models */
    const count = models.length;
    let updated = count === 0;
    for (let i = 0; i < count; i++) {
      /* Get the model */
      const model = models[i];
      /* Set the mcm */
      if (model.type === MODEL_TYPE_MCM) {
        this.mcm = model.id;
      }
      /* Add the id to array */
      ids.push(model.id);
      /* Checksum */
      if (this.modelsCheckSum.get(model.id) !== this.coreUtilities.getCheckSum(model)) {
        /* Set updated to true */
        updated = true;
        /* Update unfiltered map */
        map = map.set(model.id, model);
      }
    }
    if (updated) {
      /* Update the unfiltered models */
      this.models.next(map);
    }
    /* Return the ids */
    return ids;
  }

  /**
   * Called as the data (humanResource, nodes, relationships and activities) have been loaded
   * @param data
   */
  private onTreeDataLoaded(data: any[]) {
    /* Tree nodes map */
    let treeNodes = OrderedMap<string, TreeNode>();

    /* Tree relationships map */
    let treeRelationships = OrderedMap<string, TreeRelationship>();

    /* Tree models */
    let treeModels = OrderedMap<string, Model>();

    /* Tree human resources */
    let treeHumanResources = OrderedMap<string, CoreHumanResource>();

    /* Tree groups */
    let treeGroups = OrderedMap<string, CoreGroup>();

    /* Tree activities */
    let treeActivities = OrderedMap<string, TreeActivity>();

    const treeNodeInfo: TreeNode[] = [];
    const count = data.length;
    for (let i = 0; i < count; i++) {

      const datum = data[i];
      switch (datum.internalType) {
        case 'treeBusinessArea':
          this.businessArea.next(this.coreTransformer.treeToBusinessArea(datum));
          break;
        case 'treeModel':
          /* Transform to Model */
          const treeModel = this.coreTransformer.treeToModel(datum);
          /* Add to node map */
          treeModels = treeModels.set(treeModel.id, treeModel);
          break;
        case 'treeHumanResource':
          /* Transform to Human resource */
          const treeHumanResource = this.coreTransformer.treeToHumanResource(datum);
          /* Add to node map */
          treeHumanResources = treeHumanResources.set('' + treeHumanResource.id, treeHumanResource);
          break;
        case 'treeGroup':
          /* Transform to Group */
          const treeGroup = this.coreTransformer.treeToGroup(datum);
          /* Add to node map */
          treeGroups = treeGroups.set('' + treeGroup.id, treeGroup);
          break;
        case 'treeActivity':
          /* Transform to Activity */
          const treeActivity = this.coreTransformer.treeActivityToTreeActivity(datum);
          /* Add to activity map */
          treeActivities = treeActivities.set('' + treeActivity.id, treeActivity);
          break;
        case 'treeNode':
          /* Transform to TreeNode */
          const treeNode = this.coreTransformer.treeToTreeNode(datum);
          /* Add color from color label provider */
          const colors = this.colorLabelProvider.color(treeNode);
          if(!isNullOrUndefined(colors)) {
            treeNode.colors = colors;
            treeNode.invertedColors = treeNode.colors.map(color => this.coreUtilities.invertColor(color, true));
          }
          treeNodeInfo.push(treeNode);
          /* Add to node map */
          treeNodes = treeNodes.set(treeNode.id, treeNode);
          break;
        case 'treeRelationship':
          /* Transform to TreeRelationship */
          const treeRelationship = this.coreTransformer.treeToTreeRelationship(datum);
          /* Add to node map */
          treeRelationships = treeRelationships.set(treeRelationship.id, treeRelationship);
          break;
      }

    }

    AppGlobal.treeNodes = treeNodes;
    AppGlobal.treeActivities = treeActivities;

    const models = treeModels.toArray();
    if (models.length > 0) {
      this.onModelsLoaded(models)
    }

    /* Inform the others */
    this.models.next(treeModels);
    this.humanResources.next(treeHumanResources);
    this.groups.next(treeGroups);
    this.treeNodes.next(treeNodes);
    this.clearCache(true);
    this.treeRelationships.next(treeRelationships);
    this.treeActivities.next(treeActivities);

    /* Load the global filter */
    this.loadGlobalFilter();

    /* Set custom color label provider */
    this.setCustomColorLabelProviders();
  }

  /**
   * Called as the data (humanResource, nodes, relationships and activities) have been loaded
   * @param data
   * @param standalone
   * @param force
   */
  private onDataLoaded(data: any[], standalone = false, force = false) {

    /* Set data */
    const humanResources: List<HumanResource> = data[0];
    const nodes: List<Node> = data[1];
    const relationships: List<Relationship> = data[2];
    const activities: List<Activity> = data[3];
    const groups: List<Group> = data[4];

    /* Set maps for hierarchy & buckets */
    let parentChildMap = OrderedMap<string, OrderedMap<string, string>>();
    let childParentMap = OrderedMap<string, OrderedMap<string, string>>();
    let bucketMap = Map<string, Map<string, TreeActivity>>();

    let nodesCheckSum = '';
    let nodeTypes = Set<number>();
    let nodesMap = OrderedMap<string, Node>();
    let treeNodesMap = OrderedMap<string, TreeNode>();

    let activitiesCheckSum = '';
    let activitiesMap = OrderedMap<string, Activity>();
    let treeActivitiesMap = OrderedMap<string, TreeActivity>();

    let relationshipsCheckSum = '';
    let relationshipsMap = OrderedMap<string, Relationship>();
    let treeRelationshipsMap = OrderedMap<string, TreeRelationship>();

    let humanResourcesCheckSum = '';
    let humanResourceMap = OrderedMap<string, CoreHumanResource>();

    let groupsCheckSum = '';
    let groupsMap = OrderedMap<string, CoreGroup>();

    /* Activities */
    activities.forEach(activity => {
      const treeActivity = this.coreTransformer.activityToTreeActivity(activity);
      activitiesCheckSum += this.coreUtilities.getCheckSum(activity);
      activitiesMap = activitiesMap.set('' + activity.id, activity);
      treeActivitiesMap = treeActivitiesMap.set('' + activity.id, treeActivity);
      /* Sort into map */
      if (activity.nodebucket) {
        bucketMap = bucketMap.set(activity.relationships.nodedata, (bucketMap.has(activity.relationships.nodedata) ? bucketMap.get(activity.relationships.nodedata) : Map<string, TreeActivity>()).set(activity.id, treeActivity));
      }
    });

    /* Nodes */
    const nodesArray = nodes.toArray();
    let count = nodesArray.length;

    this.selectedColorLabelProvider = this.colorLabelProviderKey.getValue();
    /* Now set the used node types */
    if (this.selectedColorLabelProvider === 'nodetypes') {
      for (let i = 0; i < count; i++) {
        const node = nodesArray[i];
        nodeTypes = nodeTypes.add(node.nodeType);
      }
      this.setColorLabelProvider(this.selectedColorLabelProvider);
    }
    this.nodeTypes = CoreNodeTypes.filter(nodeType => nodeTypes.has(nodeType.key));

    for (let i = 0; i < count; i++) {
      /* Node */
      const node = nodesArray[i];

      /* Add node to map */
      nodesMap = nodesMap.set(node.id, node);

      /* Transform to TreeNode */
      const treeNode = this.coreTransformer.nodeToTreeNode(node);

      /* Add color from color label provider */
      if (!isNullOrUndefined(this.colorLabelProvider)) {
        treeNode.colors = this.colorLabelProvider.color(treeNode);
        treeNode.invertedColors = treeNode.colors.map(color => this.coreUtilities.invertColor(color, true));
      }

      /* Checksum */
      nodesCheckSum += this.coreUtilities.getCheckSum(node);

      /* Add node buckets if related */
      if (bucketMap.has(node.relationships.nodedata)) {
        treeNode.activities = bucketMap.get(node.relationships.nodedata).toArray();
      }
      /* Add TreeNode to map */
      treeNodesMap = treeNodesMap.set(node.id, treeNode);
      /* Set used node type */
      nodeTypes = nodeTypes.add(treeNode.nodeType);
    }

    /* Relationships */
    const relationshipsArray = relationships.toArray();
    count = relationshipsArray.length;

    for (let i = 0; i < count; i++) {

      /* Relationship */
      const relationship = relationshipsArray[i];
      if (!relationship || !treeNodesMap.has(relationship.relationships.parent) || !treeNodesMap.has(relationship.relationships.child)) {
        continue;
      }

      relationshipsCheckSum += this.coreUtilities.getCheckSum(relationship);

      /* Map */
      relationshipsMap = relationshipsMap.set('' + relationship.id, relationship);
      treeRelationshipsMap = treeRelationshipsMap.set('' + relationship.id, this.coreTransformer.relationshipToTreeRelationship(relationship));

      /* Sort into map */
      if (relationship.type === RELATIONSHIP_TYPE_DEFAULT) {
        const parentId = relationship.relationships.parent;
        const childId = relationship.relationships.child;
        parentChildMap = parentChildMap.set(parentId, (parentChildMap.has(parentId) ? parentChildMap.get(parentId) : OrderedMap<string, string>()).set(childId, childId));
        childParentMap = childParentMap.set(childId, (childParentMap.has(childId) ? childParentMap.get(childId) : OrderedMap<string, string>()).set(parentId, parentId));
      }
    }

    /* Human resources */
    humanResources.forEach(humanResource => {
      humanResourceMap = humanResourceMap.set('' + humanResource.id, this.coreTransformer.humanResourceToCoreHumanResource(humanResource));
      humanResourcesCheckSum += this.coreUtilities.getCheckSum(humanResource);
    });

    /* Groups */
    if (!isNullOrUndefined(groups)) {
      groups.forEach(group => {
        groupsMap = groupsMap.set('' + group.id, this.coreTransformer.groupToCoreGroup(group));
        groupsCheckSum += this.coreUtilities.getCheckSum(group);
      });
    }

    /* Hierarchy */
    const filteredTreeNodesMap = <OrderedMap<string, TreeNode>> treeNodesMap.filter(treeNode => {
      return !childParentMap.has(treeNode.id);
    });
    const result = this.setChildren(filteredTreeNodesMap, parentChildMap, treeNodesMap);

    /* If it's a standalone task quit here */
    if (standalone) {
      return result;
    }

    /* Diffs */
    const diffHumanResources = force || humanResourcesCheckSum !== this.humanResourcesCheckSum;
    const diffGroup = force || groupsCheckSum !== this.groupsCheckSum;
    const diffRelationships = force || relationshipsCheckSum !== this.relationshipsCheckSum;
    const diffActivities = force || activitiesCheckSum !== this.activitiesCheckSum;
    const diffNodes = force || nodesCheckSum !== this.nodesCheckSum;

    /* Trigger the events */
    if (diffHumanResources) {
      this.humanResources.next(humanResourceMap);
      this.formService.setHumanResources(humanResourceMap);
    }
    if (diffGroup) {
      this.groups.next(groupsMap);
    }
    if (diffRelationships) {
      this.relationships.next(relationshipsMap);
      this.treeRelationships.next(treeRelationshipsMap);
    }
    if (diffActivities) {
      this.activities.next(activitiesMap);
      this.treeActivities.next(treeActivitiesMap);
    }
    if (diffNodes) {
      this.nodes.next(nodesMap);
    }
    if (diffNodes || diffRelationships || diffActivities) {
      /* Set the color label provider again */
      const updateClp = !isNullOrUndefined(this.colorLabelProvider) ? this.colorLabelProvider.complex : false;
      this.treeNodes.next(result.treeNodesMap);
      this.hierarchy.next(updateClp ? <OrderedMap<string, TreeNode>> result.treeNodes.map(treeNode => {
        treeNode.colors = this.colorLabelProvider.color(treeNode);
        treeNode.invertedColors = treeNode.colors.map(color => this.coreUtilities.invertColor(color, true));
        return treeNode;
      }) : result.treeNodes);
      /* Update global filters */
      if (this.globalFilters.size > 0) {
        this.updateGlobalFilters();
      }
    }

    /* Set the checksums */
    this.humanResourcesCheckSum = humanResourcesCheckSum;
    this.relationshipsCheckSum = relationshipsCheckSum;
    this.activitiesCheckSum = activitiesCheckSum;
    this.nodesCheckSum = nodesCheckSum;

  }

  /**
   * Set the children for hierarchy
   * @param treeNodes
   * @param parentChildMap
   * @param treeNodesMap
   * @param subLevelMap
   * @param parentTreeNode
   */
  private setChildren(treeNodes: OrderedMap<string, TreeNode>,
                      parentChildMap: OrderedMap<string, OrderedMap<string, string>>,
                      treeNodesMap: OrderedMap<string, TreeNode>,
                      subLevelMap = Map<string, number>(),
                      parentTreeNode?: TreeNode): { treeNodes: OrderedMap<string, TreeNode>, treeNodesMap: OrderedMap<string, TreeNode>, subLevelMap: Map<string, number> } {

    const treeNodesArray = treeNodes.toArray();

    const count = treeNodesArray.length;
    for (let i = 0; i < count; i++) {

      /* Get tree node */
      const treeNode = treeNodesArray[i];

      /* Parent */
      if (!isNullOrUndefined(parentTreeNode) && treeNode.parentIds.indexOf(parentTreeNode.id) === -1) {
        treeNode.parents.push(parentTreeNode);
        treeNode.unfilteredParents.push(parentTreeNode);
        treeNode.parentIds.push(parentTreeNode.id);
      }

      /* Set sub level */
      let subLevel = treeNode.subLevel;
      if (!isNullOrUndefined(parentTreeNode)) {
        let _subLevel = parentTreeNode.subLevel + 1;
        const storedSubLevel = subLevelMap.get(treeNode.id);
        if (!isNullOrUndefined(storedSubLevel) && storedSubLevel > _subLevel) {
          _subLevel = storedSubLevel;
        }
        subLevel = _subLevel;
      }
      subLevelMap = subLevelMap.set(treeNode.id, subLevel);
      treeNode.subLevel = subLevel;

      /* Continue with children */
      if (parentChildMap.has(treeNode.id)) {
        const childrenResult = this.setChildren(<OrderedMap<string, TreeNode>>parentChildMap.get(treeNode.id).map(id => treeNodesMap.get(id)), parentChildMap, treeNodesMap, subLevelMap, treeNode);

        /* Merge the tree nodes map */
        treeNodesMap = treeNodesMap.merge(childrenResult.treeNodesMap);
        treeNode.children = childrenResult.treeNodes.toArray();
        treeNode.unfilteredChildren = childrenResult.treeNodes.toArray();
        subLevelMap = childrenResult.subLevelMap;
      }

      /* Add the node to map */
      treeNodes = treeNodes.set(treeNode.id, treeNode);
      treeNodesMap = treeNodesMap.set(treeNode.id, treeNode);
    }
    /* Return map */
    return { treeNodes: treeNodes, treeNodesMap: treeNodesMap, subLevelMap: subLevelMap };
  }

  /**
   * Set the color label provider
   * @param colorLabelProvider
   */
  private setColorLabelProvider(colorLabelProvider: string) {
    /* Store the key */
    this.colorLabelProviderKey.next(colorLabelProvider);
    /* Set the color label provider */
    this.colorLabelProvider = this.getColorLabelProviderInstance(colorLabelProvider);
  }

  /**
   * Get color label provider instance
   * @param colorLabelProviderKey
   * @param colorLabelProviderNode
   */
  public getColorLabelProviderInstance(colorLabelProviderKey: string, colorLabelProviderNode?: TreeNode) {
    const treeNodes = this.treeNodes.getValue();
    if (!isNullOrUndefined(treeNodes)) {
      const configurationNode = treeNodes.filter(treeNode => treeNode.nodeType === NODES_TYPE_MODULECONFIGURATION && treeNode.businessarea === this.businessAreaId).first();
      if (!isNullOrUndefined(configurationNode)) {
        const colorLabelProviderNode = configurationNode.children.filter(child => child.nodeType === NODES_TYPE_COLORLABELPROVIDER)[0];
        if (!isNullOrUndefined(colorLabelProviderNode)) {
          this.colorLabelProviderService.setConfigurationNode(colorLabelProviderNode);
        }
      }
    } else if (!isNullOrUndefined(colorLabelProviderNode)) {
      this.colorLabelProviderService.setConfigurationNode(colorLabelProviderNode);
    }
    /* Set the color label provider */
    switch (colorLabelProviderKey) {
      case 'models':
        return this.colorLabelProviderService.models(this.models.getValue());
      case 'submodels':
        return this.colorLabelProviderService.submodels(this.subModels.getValue());
      case 'subsets':
        return this.colorLabelProviderService.subsets(this.subSets.getValue());
      case 'targetDate':
        return this.colorLabelProviderService.targetDate();
      case 'status':
        return this.colorLabelProviderService.status();
      case 'sidestep':
        return this.colorLabelProviderService.sidestep();
      case 'responsible':
        return this.colorLabelProviderService.responsible(this.humanResources.getValue());
      case 'difference':
        return this.colorLabelProviderService.difference();
      case 'importance':
        return this.colorLabelProviderService.importance();
      case 'risk':
        return this.colorLabelProviderService.risk();
      case 'qstatus.q1':
        return this.colorLabelProviderService.qstatus('executive');
      case 'qstatus.q2':
        return this.colorLabelProviderService.qstatus('program');
      case 'qstatus.q3':
        return this.colorLabelProviderService.qstatus('functional');
      case 'qstatus.q4':
        return this.colorLabelProviderService.qstatus('resource');
      case 'relatedstatus':
        return this.colorLabelProviderService.relatedstatus(this.treeNodes.getValue());
      case 'nodestype':
        return this.colorLabelProviderService.basic();
        // const businessArea = this.businessArea.getValue();
        // return this.colorLabelProviderService.nodestype(this.nodeTypes, this.models.getValue(), this.subModels.getValue(), this.subSets.getValue(),
        //   <OrderedMap<string, CoreHumanResource>>this.humanResources.getValue().filter(humanResource => humanResource.instanceId === businessArea.relationships.instance), this.treeNodes.getValue());
      case 'nodetypes':
        return this.colorLabelProviderService.nodetypes(CoreNodeTypes);
      case 'levels':
        return this.colorLabelProviderService.levels();
      case 'planned':
        return this.colorLabelProviderService.planned();
      case 'projectsavailable':
        return this.colorLabelProviderService.projectsAvailable(this.formService);
      case 'validated':
        return this.colorLabelProviderService.validated();
      case 'positive':
        return this.colorLabelProviderService.positive();
      case 'heatmap':
        return this.colorLabelProviderService.heatmap();
      case 'coloredRelations':
        return this.colorLabelProviderService.coloredRelation();
      case 'statusField':
        return this.colorLabelProviderService.statusField();
      default:
        /* Custom color label providers */
        if (colorLabelProviderKey.indexOf('custom') !== -1) {
          this.colorLabelProviderService.addConfigurationDispatcher(colorLabelProviderKey);
          return this.colorLabelProviderService.custom(colorLabelProviderKey);
        }
        return this.colorLabelProviderService.basic();
    }
  }

  private getLegend(nodes = this.treeNodes.getValue().toArray()) {
    this.legend = this.colorLabelProvider.legend(nodes).map((legend: CoreLegend) => {
      legend.selected = this.toggledLegends.indexOf(legend.key) === -1;
      return legend;
    });
    return this.legend;
  }

  private createByBusinessArea(transfer: CoreTransfer, finished?: Function, result?: CoreTransferResult) {
    /* Business area */
    this.businessAreaService.diff.filter(d => !!d && d.action === BusinessareaAction.CREATE_SUCCESS && d.payload.data.id === transfer.businessArea.id).take(1).subscribe(diff => {
      transfer.businessAreaId = diff.response.id;
      this.createByModels(transfer, () => {
        if (isNullOrUndefined(result)) {
          result = { businessAreaId: transfer.businessAreaId, modelIds: [], nodeIds: [], relationshipIds: [] };
        } else {
          result.businessAreaId = transfer.businessAreaId;
        }
        if (!isNullOrUndefined(finished)) {
          finished(result);
        }
      }, result);
    });
    if (this.useGo && this.goUpdate) {
      this.businessAreaService.createGo(transfer.instanceId, <IPayload>{ id: transfer.businessArea.id, data: transfer.businessArea });
    } else {
      this.businessAreaService.create(transfer.instanceId, <IPayload>{id: transfer.businessArea.id, data: transfer.businessArea});
    }
  }

  private createByModels(transfer: CoreTransfer, finished?: Function, result?: CoreTransferResult) {
    let finishedCount = 0;
    const count = transfer.models.length;
    /* If it's empty */
    if (count === 0) {
      finished();
    }
    const ids = [];
    /* Subscription */
    this.subscriptionService.add('model', this.modelService.diff.filter(d => !!d && d.action === ModelAction.CREATE_SUCCESS && ids.indexOf(d.payload.data.id) !== -1).subscribe(diff => {
      const modelId = diff.response.id;
      (<TreeNode[]>transfer.nodes) = (<TreeNode[]>transfer.nodes).map(treeNode => {
        if (treeNode.workFlowModel === diff.payload.data.id) {
          treeNode.workFlowModel = modelId;
        }
        return treeNode;
      });
      const nodes = (<TreeNode[]>transfer.nodes).filter(node => node.modelId === diff.payload.data.id);
      const relationships = (<TreeRelationship[]>transfer.relationships).filter(relationship => relationship.modelId === diff.payload.data.id);
      /* Call the next action */
      this.createNodesAndRelationships(<CoreTransfer>{ nodes: nodes, relationships: relationships, modelId: modelId }, () => {
        if (++finishedCount === count) {
          if (!isNullOrUndefined(finished)) {
            finished();
          }
        }
      });
    }));

    /* Iterate over models */
    for (let i = 0; i < count; i++) {
      const model = transfer.models[i];
      ids.push(model.id);
      this.modelService.createOnBusinessarea(transfer.businessAreaId, <IPayload>{ id: model.id, data: model });
    }
  }

  private createActivities(transfer: CoreTransfer, finished?: Function) {
    /* Register listener */
    let createCounts = transfer.activities.length;
    this.activityService.diff.filter(diff => !!diff && diff.action === ActivityAction.CREATE_SUCCESS).subscribe(diff => {
      if (--createCounts === 0) {
        if (transfer.nodes.length > 0 || transfer.relationships.length > 0) {
          this.createNodesAndRelationshipsByModel(transfer, finished);
        } else {
          finished();
        }
      }
    });
    /* Run all create methods */
    const count = transfer.activities.length;
    for (let i = 0; i < count; i++) {
      const activityPayload: any = transfer.activities[i];
      switch (activityPayload.data.activitable_type) {
        case 'ValueMiner\\Modeling\\Models\\Instance':
          this.activityService.createOnInstance(activityPayload.id, activityPayload);
          break;
        default:
          this.activityService.createOnNodeData(activityPayload.id, activityPayload);
      }
    }
  }

  private createHumanResourcesAndNodes(transfer: CoreTransfer, finished?: Function, continueFn?: Function) {
    /* Register listener */
    let createCounts = transfer.humanResources.length;
    this.subscriptionService.add('create-human-resources-and-nodes-human-resources', this.humanResourceService.diff.filter(diff => this.requestWasSuccessful(diff, <IPayload[]>transfer.humanResources, HumanResourceAction.CREATE_SUCCESS)).subscribe(diff => {
      if (--createCounts === 0) {
        this.subscriptionService.remove('create-human-resources-and-nodes-human-resources');
        const nodesCount = transfer.nodes.length;
        if (nodesCount > 0) {
          transfer.nodes = (<any[]>transfer.nodes).map((node: NodeCreate | TreeNode) => {
            if (node.id === diff.payload.id) {
              if (node instanceof NodeCreate) {
                node = <NodeCreate>node.set('responsibleId', parseInt(diff.response[0].id));
              } else {
                node.responsibleId = parseInt(diff.response[0].id);
              }
            }
            return node;
          });
          if (!isNullOrUndefined(continueFn)) {
            continueFn(transfer, finished);
          } else {
            this.createNodesAndRelationshipsByModel(transfer, finished);
          }
        } else {
          finished();
        }
      }
    }));
    /* Run all create methods */
    const instanceId = this.businessArea.getValue().relationships.instance;
    const count = transfer.humanResources.length;
    for (let i = 0; i < count; i++) {
      let humanResource: any = transfer.humanResources[i];
      if (isNullOrUndefined(humanResource['permissions'])) {
        humanResource = humanResource.set('permissions', []);
      }
      this.humanResourceService.createOnInstance(instanceId, <IPayload>{ id: humanResource.id, data: humanResource });
    }
  }

  private createGroupsAndNodes(transfer: CoreTransfer, finished?: Function, continueFn?: Function) {
    /* Register listener */
    let createCounts = transfer.groups.length;
    this.subscriptionService.add('create-groups-and-nodes-groups', this.groupService.diff.filter(diff => this.requestWasSuccessful(diff, <IPayload[]>transfer.groups, GroupAction.CREATE_SUCCESS)).subscribe(diff => {
      if (--createCounts === 0) {
        this.subscriptionService.remove('create-groups-and-nodes-groups');
        const nodesCount = transfer.nodes.length;
        if (nodesCount > 0) {
          transfer.nodes = (<TreeNode[]>transfer.nodes).map((node: TreeNode) => {
            if (node.id === diff.payload.id) {
              node.groupId = diff.response.id;
            }
            return node;
          });
          if (!isNullOrUndefined(continueFn)) {
            continueFn(transfer, finished);
          } else {
            this.createNodesAndRelationshipsByModel(transfer, finished);
          }
        } else {
          finished();
        }
      }
    }));
    /* Run all create methods */
    const instanceId = this.businessArea.getValue().relationships.instance;
    const count = transfer.groups.length;
    for (let i = 0; i < count; i++) {
      const group = new Group(<CoreGroup>transfer.groups[i]);
      this.groupService.createOnInstance(instanceId, <IPayload>{ id: '' + group.id, data: group });
    }
  }

  private createHumanResourcesAndGroupsAndNodes(transfer: CoreTransfer, finished?: Function) {
    this.createHumanResourcesAndNodes(transfer, finished, (transfer1, finished1) => {
      this.createGroupsAndNodes(transfer1, finished1, (transfer2, finished2) => {
        this.createNodesAndRelationshipsByModel(transfer2, finished2);
      });
    });
  }

  private createGrouping(transfer: CoreTransfer, finished?: Function, continueFn?: Function) {
    /* Register listener */
    this.subscriptionService.add('create-node-structures', this.nodeStructureService.diff.filter(diff => this.requestWasSuccessful(diff, <IPayload[]>transfer.nodeStructures, NodeStructureAction.CREATE_SUCCESS)).subscribe(diff => {
      this.subscriptionService.remove('create-node-structures');
      const count = diff.response.length;
      for (let i = 0; i < count; i++) {
        const response = diff.response[i];
        /* Check if there is a relationship where we should convert the ids */
        transfer.relationships = (<TreeRelationship[]> transfer.relationships).map(treeRelationship => {
          if (treeRelationship.parentId === response.creationId) {
            treeRelationship.parentId = response.id;
          }
          if (treeRelationship.childId === response.creationId) {
            treeRelationship.childId = response.id;
          }
          return treeRelationship;
        });
      }
      if (transfer.nodes.length > 0 || transfer.relationships.length > 0) {
        if (!isNullOrUndefined(continueFn)) {
          continueFn(transfer, finished);
        } else {
          this.createNodesAndRelationshipsByModel(transfer, finished);
        }
      } else {
        finished();
      }
    }));
    /* Prepare */
    transfer.nodeStructures = (<TreeNode[]> transfer.nodeStructures).map(treeNode => {
      const nodeGrouping = this.coreTransformer.treeNodeToNodeGrouping(<TreeNode>treeNode);
      transfer.ids.push(treeNode.id);
      return nodeGrouping;
    });
    /* Run all create methods */
    this.nodeStructureService.create(transfer.modelId, PayloadFactory.fromArray(transfer.nodeStructures));
  }

  private createNodesAndRelationshipsByModel(transfer: CoreTransfer, finished?: Function) {
    /* Map the nodes and relationships by model */
    let modelMap = Map<string, CoreTransfer>();
    let count = transfer.nodes.length;
    for (let i = 0; i < count; i++) {
      const nodeCreate = <NodeCreate>(transfer.nodes[i] instanceof NodeCreate ? transfer.nodes[i] : this.coreTransformer.treeNodeToNodeCreate(<TreeNode>transfer.nodes[i]));
      let modelId = transfer.modelId;
      if (!isNullOrUndefined(nodeCreate.modelId) && nodeCreate.modelId !== '0') {
        modelId = nodeCreate.modelId;
      }
      if (!isNullOrUndefined(modelId)) {
        const modelTransfer = modelMap.has(modelId) ? modelMap.get(modelId) : { nodes: [], relationships: [], modelId: modelId };
        (<NodeCreate[]>modelTransfer.nodes).push(nodeCreate);
        modelMap = modelMap.set(modelId, modelTransfer);
      }
    }
    count = transfer.relationships.length;
    for (let i = 0; i < count; i++) {
      const relationshipCreate = <RelationshipCreate>(transfer.relationships[i] instanceof RelationshipCreate ? transfer.relationships[i] : this.coreTransformer.treeRelationshipToRelationshipCreate(<TreeRelationship>transfer.relationships[i]));
      let modelId = transfer.modelId;
      if (!isNullOrUndefined(relationshipCreate.model) && relationshipCreate.model !== '0') {
        modelId = relationshipCreate.model;
      }
      if (!isNullOrUndefined(modelId)) {
        const modelTransfer = modelMap.has(modelId) ? modelMap.get(modelId) : { nodes: [], relationships: [], modelId: modelId };
        (<RelationshipCreate[]>modelTransfer.relationships).push(relationshipCreate);
        modelMap = modelMap.set(modelId, modelTransfer);
      }
    }

    const ids = modelMap.keySeq().toArray();
    /* If it's empty */
    if (ids.length === 0) {
      finished();
    }
    const call = () => {
      /* Get the id */
      const modelId = ids.shift();
      /* Do the call */
      this.createNodesAndRelationships(modelMap.get(modelId), () => {
        if (ids.length === 0) {
          finished();
        } else {
          call();
        }
      });
    };
    call();
  }

  private createNodesAndRelationships(transfer: CoreTransfer, finished?: Function, grouping = false) {
    if (isNullOrUndefined(transfer)) {
      if (!isNullOrUndefined(finished)) {
        finished();
      }
      return;
    }
    if (this.useGo && this.goUpdate) {
      this.createNodesAndRelationsByGo(transfer, finished, grouping);
    } else {
      this.createNodesAndRelationshipsByPhp(transfer, finished, grouping);
    }
  }

  private createNodesAndRelationshipsByPhp(transfer: CoreTransfer, finished?: Function, grouping = false) {
    /* Prepare the data for calls */
    const prepared = this.prepareDataForApi(transfer, grouping);
    /* Created nodes */
    const createdNodes = [];
    /* Set a unique id to identify the events and clear them properly */
    const uuid = UUID.UUID();

    /* Register the listener for relationships */
    this.subscriptionService.add(uuid + '-relationships', this.relationshipService.diff.filter(diff => diff.action === RelationshipAction.CREATE_SUCCESS && diff.payload.id === transfer.modelId).subscribe(_ => {
      this.subscriptionService.remove(uuid + '-relationships');
      this.subscriptionService.remove(uuid + '-nodes');
      this.relationshipService.cancel();
      if (!isNullOrUndefined(finished)) {
        finished();
      }
    }));

    /* Register the listener for nodes to be created */
    this.subscriptionService.add(uuid + '-nodes', this.nodeStructureService.diff.filter(diff => diff.action === NodeStructureAction.CREATE_SUCCESS && diff.payload.id === transfer.modelId).subscribe(diff => {
      /* The nodes are created now replace the temp ids with proper ones */
      let idTouched = false;

      diff.response.forEach(response => {
        /* Call function if in created */
        if (this.isCreatedMap.has(response.creationId)) {
          this.isCreatedMap.get(response.creationId)(response);
        }
        /* Check if response matches any nodes to be created */
        if (prepared.ids.indexOf(response.creationId) !== -1) {
          idTouched = true;
          createdNodes.push(response.id);

          /* Check if there is a relationship where we should convert the ids */
          prepared.relationships = prepared.relationships.map(relationship => {
            if (relationship.parent === response.creationId) {
              relationship = <RelationshipCreate>relationship.set('parent', response.id);
            }
            if (relationship.child === response.creationId) {
              relationship = <RelationshipCreate>relationship.set('child', response.id);
            }
            return relationship;
          });
        }
      });

      if (idTouched) {
        /* Filter the relationships where somehow the id did not get changed */
        const relationships = prepared.relationships.filter(relationship => relationship.parent.indexOf('-') === -1 && relationship.child.indexOf('-') === -1);
        /* In case we have to create a more complex structure with models we do not directly create the nodes but collect them and return them */
        if (relationships.length === 0) {
          this.subscriptionService.remove(uuid + '-relationships');
          this.subscriptionService.remove(uuid + '-nodes');
          if (!isNullOrUndefined(finished)) {
            finished();
          }
        } else {
          /* We are standalone so we create the relationships */
          this.relationshipService.create(transfer.modelId, PayloadFactory.fromArray(relationships));
        }
      }
    }));
    if (prepared.nodes.length === 0 && prepared.relationships.length > 0) {
      /* In case only relationships get sent in we skip everything and just create the relationships */
      this.subscriptionService.remove(uuid + '-nodes');
      this.relationshipService.create(transfer.modelId, PayloadFactory.fromArray(prepared.relationships));
    } else if (prepared.nodes.length > 0) {
      const nodes = (<NodeCreate[]>prepared.nodes).map(node => {
        if (isString(node.responsibleId)) {
          node = <NodeCreate>node.set('responsibleId', null);
        }
        return node;
      });
      /* Create the nodes as the first step */
      if (grouping) {
        this.nodeStructureService.create(transfer.modelId, PayloadFactory.fromArray(nodes));
      } else {
        this.nodeService.create(transfer.modelId, PayloadFactory.fromArray(nodes));
      }
    } else {
      finished();
    }
  }

  private createNodesAndRelationsByGo(transfer: CoreTransfer, finished?: Function, grouping = false) {
    /* Set a unique id to identify the events and clear them properly */
    const uuid = UUID.UUID();
    /* Prepare the data for calls */
    const prepared = this.prepareDataForGoApi(transfer, grouping);

    /* Create */
    if (prepared.relationships.length > 0) {
      if (prepared.nodes.length > 0) {
        this.subscriptionService.add(uuid + '-nodes', this.nodeStructureService.diff.filter(diff => diff.action === NodeStructureAction.CREATE_SUCCESS && diff.payload.id === transfer.modelId).subscribe(diff => {
          this.subscriptionService.remove(uuid + '-nodes');
          if (!isNullOrUndefined(finished)) {
            finished();
          }
        }));
        this.nodesAndRelationshipService.create({ nodes: prepared.nodes, relationships: prepared.relationships, modelId: transfer.modelId });
      } else {
        this.subscriptionService.add(uuid + '-relationships', this.relationshipService.diff.filter(diff => diff.action === RelationshipAction.CREATE_SUCCESS && diff.payload.id === transfer.modelId).subscribe(_ => {
          this.subscriptionService.remove(uuid + '-relationships');
          if (!isNullOrUndefined(finished)) {
            finished();
          }
        }));
        this.relationshipService.createGo(transfer.modelId, PayloadFactory.fromArray(prepared.relationships));
      }
    } else {
      /* Register the listener for nodes to be created */
      this.subscriptionService.add(uuid + '-nodes', this.nodeStructureService.diff.filter(diff => diff.action === NodeStructureAction.CREATE_SUCCESS && diff.payload.id === transfer.modelId).subscribe(diff => {
        this.subscriptionService.remove(uuid + '-nodes');
        if (!isNullOrUndefined(finished)) {
          finished();
        }
      }));
      /* Create the nodes as the first step */
      if (grouping) {
        this.nodeStructureService.create(transfer.modelId, PayloadFactory.fromArray(prepared.nodes));
      } else {
        this.nodeService.createGo(transfer.modelId, PayloadFactory.fromArray(prepared.nodes));
      }
    }
  }

  /**
   * Prepare data for API call
   * @param data
   * @param grouping
   */
  private prepareDataForApi(data: CoreTransfer, grouping = false): { nodes: NodeCreate[], relationships: RelationshipCreate[], ids: string[] } {
    const prepared = { nodes: [], relationships: [], ids: [] };

    if (isNullOrUndefined(data)) {
      return prepared;
    }

    /* Get the tree nodes */
    const treeNodes = this.treeNodes.getValue();

    /* Set the position map as we iterate over the relationships */
    let positionMap = Map<string, number>();

    let count = data.relationships.length;
    for (let i = 0; i < count; i++) {
      const treeRelationship = data.relationships[i];
      if (treeRelationship instanceof RelationshipCreate) {
        prepared.relationships.push(treeRelationship);
      } else {
        /* Check if the parent node is known so we can get the perfect position for a potential child */
        if (!isNullOrUndefined(treeNodes) && treeNodes.has((<TreeRelationship>treeRelationship).parentId)) {
          let positionX: number;
          const parentTreeNode = treeNodes.get((<TreeRelationship>treeRelationship).parentId);
          const childrenCount = parentTreeNode.children.length;
          if (childrenCount > 0) {
            /* If the parent has children search for the node with the highest x position */
            for (let j = 0; j < childrenCount; j++) {
              const child = parentTreeNode.children[0];
              if (isNullOrUndefined(positionX) || child.positionX > positionX) {
                positionX = child.positionX;
              }
            }
          } else {
            /* The parent has no children so we take the node's x position */
            positionX = parentTreeNode.positionX;
          }
          if (!isNullOrUndefined(positionX)) {
            positionMap = positionMap.set((<TreeRelationship>treeRelationship).childId, positionX);
          }
        }

        /* Convert the tree relationship to relationship create */
        prepared.relationships.push(this.coreTransformer.treeRelationshipToRelationshipCreate((<TreeRelationship>treeRelationship)));
      }
    }

    /* Now prepare nodes */
    count = data.nodes.length;
    for (let i = 0; i < count; i++) {
      const treeNode: any = data.nodes[i];
      if (treeNode instanceof NodeCreate) {
        prepared.ids.push(treeNode.id);
        prepared.nodes.push(treeNode);
      } else {
        /* Store the id */
        prepared.ids.push(treeNode.id);

        /* Get the x position */
        if (isNullOrUndefined((<TreeNode>treeNode).positionX) || (<TreeNode>treeNode).positionX === 0) {
          if (positionMap.has(treeNode.id)) {
            (<TreeNode>treeNode).positionX = positionMap.get(treeNode.id);
          } else if (this.positions.has((<TreeNode>treeNode).level)) {
            (<TreeNode>treeNode).positionX = this.positions.get((<TreeNode>treeNode).level).max + this.nodeMargin;
          } else {
            (<TreeNode>treeNode).positionX = this.minX;
          }
        }

        /* Transform the node and add it to array */
        prepared.nodes.push(grouping ? this.coreTransformer.treeNodeToNodeGrouping(<TreeNode>treeNode) : this.coreTransformer.treeNodeToNodeCreate((<TreeNode>treeNode)));
      }
    }

    /* Return */
    return prepared;
  }

  /**
   * Prepare the data for an GO API call
   * @param data
   * @param grouping
   */
  private prepareDataForGoApi(data: CoreTransfer, grouping = false): { nodes: NodeCreate[], relationships: RelationshipCreate[], ids: string[] } {
    const prepared = { nodes: [], relationships: [], ids: [] };

    if (isNullOrUndefined(data)) {
      return prepared;
    }

    let count = data.relationships.length;
    for (let i = 0; i < count; i++) {
      let treeRelationship = data.relationships[i];
      if (treeRelationship instanceof RelationshipCreate) {
        if (!isNullOrUndefined(data.modelId)) {
          treeRelationship = <RelationshipCreate> (<RelationshipCreate> treeRelationship).set('version_id', parseInt(data.modelId));
        } else if (!isNullOrUndefined(treeRelationship.model)) {
          treeRelationship = <RelationshipCreate> (<RelationshipCreate> treeRelationship).set('version_id', parseInt(treeRelationship.model));
        }
        prepared.relationships.push(treeRelationship);
      } else {
        if (!isNullOrUndefined(data.modelId)) {
          (<TreeRelationship> treeRelationship).version_id = parseInt(data.modelId);
        } else if (!isNullOrUndefined((<TreeRelationship> treeRelationship).modelId)) {
          (<TreeRelationship> treeRelationship).version_id = parseInt((<TreeRelationship> treeRelationship).modelId);
        }
        /* Convert the tree relationship to relationship create */
        prepared.relationships.push(this.coreTransformer.treeRelationshipToRelationshipCreate((<TreeRelationship>treeRelationship)));
      }
    }

    /* Now prepare nodes */
    count = data.nodes.length;
    for (let i = 0; i < count; i++) {
      let treeNode: any = data.nodes[i];
      if (treeNode instanceof NodeCreate) {
        if (!isNullOrUndefined(data.modelId)) {
          treeNode = treeNode.set('version_id', parseInt(data.modelId));
        } else if (!isNullOrUndefined(treeNode.modelId)) {
          treeNode = treeNode.set('version_id', parseInt(treeNode.modelId));
        }
        if (treeNode.responsibleId === '' || treeNode.responsibleId === '0' || treeNode.responsibleId === 0) {
          treeNode = treeNode.set('responsibleId', null);
        }
        /* Get the x position */
        if (isNullOrUndefined(treeNode.positionX) || treeNode.positionX === 0) {
          treeNode = treeNode.set('positionX', this.minX);
        }
        /* Get the delta */
        let delta = this.coreUtilities.getDelta(treeNode, new NodeCreate());
        /* Set the level */
        if (!delta.has('level') || isNullOrUndefined(delta.get('level'))) {
          delta = delta.set('level', 0);
        }
        prepared.ids.push(treeNode.id);
        prepared.nodes.push(this.prepareDelta(delta));
      } else {
        if (!isNullOrUndefined(data.modelId)) {
          treeNode.version_id = parseInt(data.modelId);
        } else if (!isNullOrUndefined(treeNode.modelId)) {
          treeNode.version_id = parseInt(treeNode.modelId);
        }
        if (treeNode.responsibleId === '' || treeNode.responsibleId === '0' || treeNode.responsibleId === 0) {
          treeNode.responsibleId = null;
        }
        /* Store the id */
        prepared.ids.push(treeNode.id);
        /* Get the x position */
        if (isNullOrUndefined((<TreeNode>treeNode).positionX) || (<TreeNode>treeNode).positionX === 0) {
          (<TreeNode>treeNode).positionX = this.minX;
        }

        const transformed = grouping ? this.coreTransformer.treeNodeToNodeGrouping(<TreeNode>treeNode) : this.coreTransformer.treeNodeToNodeCreate((<TreeNode>treeNode));
        let delta = grouping ? this.coreUtilities.getDelta(transformed, new NodeGrouping()) : this.coreUtilities.getDelta(transformed, new NodeCreate());
        /* Set the level */
        if (!delta.has('level') || isNullOrUndefined(delta.get('level'))) {
          delta = delta.set('level', 0);
        }

        /* Transform the node and add it to array */
        prepared.nodes.push(this.prepareDelta(delta));
      }
    }

    /* Return */
    return prepared;
  }

  /**
   * Prepare delta to convert values if needed
   * @param delta
   * @private
   */
  private prepareDelta(delta: Map<string, any>) {
    if (!(delta instanceof Map)) {
      delta = Map(delta);
    }
    return <Map<string, any>> delta.map((value: any, key: string) => {
      switch (key) {
        case 'targetDate':
        case 'actualDate':
        case 'startDate':
        case 'actualStartDate':
          value = new Datum(value).toGoLang();
          break;
        case 'storypoints':
          value = parseFloat(value);
          break;
      }

      return value;
    });
  }

  private filterByColorLabelProvider(treeNode: TreeNode) {
    let visible = true;
    const legends = this.legend.filter(legend => !legend.selected);
    const count = legends.length;
    for (let i = 0; i < count; i++) {
      const legend = legends[i];
      if (visible) {
        switch (legend.field) {
          case 'difference':
            visible = !('' + this.colorLabelProviderService.difference().calculate(treeNode) === '' + legend.value);
            break;
          case 'sidestep':
            visible = !('' + this.colorLabelProviderService.sidestep().calculate(treeNode) === '' + legend.value);
            break;
          case 'targetDate':
            visible = !('' + this.colorLabelProviderService.targetDate().calculate(treeNode) === '' + legend.value);
            break;
          case 'model':
          case 'subModel':
            visible = treeNode.models.indexOf(legend.value) === -1;
            break;
          case 'subSet':
            visible = treeNode.subsets.indexOf(legend.value) === -1;
            break;
          default:
            visible = !(treeNode[legend.field] === legend.value);
        }
      } else {
        break;
      }
    }
    return visible;
  }

  private filterByNodeTypes(treeNode: TreeNode) {
    return this.filteredNodeTypes.indexOf(treeNode.nodeType) === -1;
  }

  private findTopMostNotFiltered(treeNode: TreeNode, filterKey?: string, result?: TreeNode): TreeNode {
    if (!!filterKey ? treeNode.visible[filterKey] : treeNode.visible) {
      result = treeNode;
    }
    if (isNullOrUndefined(result)) {
      const count = treeNode.parents.length;
      for (let i = 0; i < count; i++) {
        result = this.findTopMostNotFiltered(treeNode.parents[i], filterKey, result);
        if (!isNullOrUndefined(result)) {
          break;
        }
      }
    }
    return result;
  }

  private requestWasSuccessful(diff: RequestDiffRecord, payloads: IPayload[] | string[], successAction: string) {
    if (isNullOrUndefined(diff.action) || diff.action !== successAction) {
      return false;
    }
    if (!!this.knownRequests[diff.id]) {
      return false;
    }
    this.knownRequests[diff.id] = true;
    const outputIds = [];
    if (!isNullOrUndefined(diff.payload.data)) {
      if (isArray(diff.payload.data)) {
        const count = isNullOrUndefined(diff.payload.data) ? 0 : diff.payload.data.length;
        for (let i = 0; i < count; i++) {
          const datum = diff.payload.data[i];
          outputIds.push(isNullOrUndefined(datum.id) ? datum : datum.id);
        }
      } else {
        outputIds.push(isNullOrUndefined(diff.payload.data.id) ? diff.payload.data : diff.payload.data.id);
      }
    }
    let inputIds = [];
    if (isString(payloads[0])) {
      inputIds = payloads;
    } else {
      const count = payloads.length;
      for (let i = 0; i < count; i++) {
        const datum: any = payloads[i];
        inputIds.push(isNullOrUndefined(datum.id) ? datum : datum.id);
      }
    }
    return outputIds.sort((a, b) => a - b).join('-') === inputIds.sort((a, b) => a - b).join('-');
  }

  private modifyTransferByType(transfer: CoreTransfer, type: number) {
    /* Iterate over nodes */
    const count = transfer.nodes.length;
    for (let i = 0; i < count; i++) {
      const treeNode = (<TreeNode[]>transfer.nodes)[i];
      /* Switch by type of action */
      switch (type) {
        case NODES_TYPE_ADD:
          break;
        case NODES_TYPE_UPDATE:
          transfer = this.modifyTransferByUpdate(treeNode, transfer);
          break;
        case NODES_TYPE_DELETE:
          transfer = this.modifyTransferByDelete(treeNode, transfer);
          break;
        case NODES_TYPE_CONNECT:
          break;
        case NODES_TYPE_DISCONNECT:
          break;
      }
    }
    /* Return transfer */
    return transfer;
  }

  private modifyTransferByUpdate(payload: IPayload, transfer: CoreTransfer) {
    if (this.useTreeNodes) {
      return transfer;
    }
    const nodeData = this.nodes.getValue().filter(node => node.relationships.nodedata === payload.id).first();
    if (!isNullOrUndefined(nodeData)) {
      const nodeType = nodeData.nodeType;
      switch (nodeType) {
        case NODES_TYPE_GROUP:
          const group = this.groups.getValue().get('' + nodeData.groupId);
          if (!isNullOrUndefined(group)) {
            let groupDelta = Map<string, any>();
            payload.data.forEach((value, key) => {
              if (!isNullOrUndefined(group[key]) && group[key] !== value) {
                groupDelta = groupDelta.set(key, value);
              }
            });
            if (groupDelta.size > 0) {
              if (isNullOrUndefined(transfer.groups)) {
                transfer.groups = [];
              }
              (<IPayload[]>transfer.groups).push(<IPayload>{id: '' + group.id, data: groupDelta});
            }
          }
          break;
        case NODES_TYPE_HUMANRESOURCE:
          const humanResource = this.humanResources.getValue().get('' + nodeData.responsibleId);
          if (!isNullOrUndefined(humanResource)) {
            let humanResourceDelta = Map<string, any>();
            payload.data.forEach((value, key) => {
              if (!isNullOrUndefined(humanResource[key]) && humanResource[key] !== value) {
                humanResourceDelta = humanResourceDelta.set(key, value);
                if (key === 'name') {
                  const nameSplit = value.split(' ');
                  const firstName = nameSplit[0];
                  nameSplit.shift();
                  const lastName = nameSplit.join(' ');
                  humanResourceDelta = humanResourceDelta.set('first_name', firstName);
                  humanResourceDelta = humanResourceDelta.set('last_name', lastName);
                }
              }
            });
            if (humanResourceDelta.size > 0) {
              if (isNullOrUndefined(transfer.humanResources)) {
                transfer.humanResources = [];
              }
              (<IPayload[]> transfer.humanResources).push(<IPayload> { id: '' + humanResource.id, data: humanResourceDelta });
            }
          }
          break;
      }
    }
    return transfer;
  }

  private modifyTransferByDelete(treeNode: TreeNode, transfer: CoreTransfer) {
    return transfer;
  }

  private setCustomColorLabelProviders() {
    /* 1. Get the module node */
    const moduleNode = AppGlobal.treeNodes.filter(treeNode => treeNode.nodeType === NODES_TYPE_MODULECONFIGURATION).first();
    if (!isNullOrUndefined(moduleNode)) {
      /* 2. Get the color label provider node */
      const colorLabelProviderNode = moduleNode.unfilteredChildren.filter(child => child.nodeType === NODES_TYPE_COLORLABELPROVIDER)[0];
      if (!isNullOrUndefined(colorLabelProviderNode)) {
        /* 3. Get the custom color label providers */
        const colorLabelProviders = colorLabelProviderNode.unfilteredChildren.filter(child => child.formId === '');
        /* 4. Iterate over providers */
        const count = colorLabelProviders.length;
        for (let i = 0; i < count; i++) {
          const colorLabelProvider = colorLabelProviders[i];
          /* Get the key */
          const key = 'custom-' + colorLabelProvider.id;
          /* 5. Add to the list of color label providers */
          if (this.colorLabelProviders.filter(c => c.key === key).length === 0) {
            this.colorLabelProviders.push(<{ key: string, label: string }> { key: key, label: colorLabelProvider.name });
          }
          /* Add to dispatchers */
          this.colorLabelProviderService.updateConfigurationNode(key, colorLabelProvider);
          /* Update if it's already a custom clp selected */
          const active = this.colorLabelProviderKey.getValue();
          if (!isNullOrUndefined(active) && active.indexOf('custom') !== -1) {
            this.redrawColorLabelProvider();
          }
        }
      }
    }
  }

}

import { CoreWidget, CoreWidgetMessage, TreeActivity, TreeNode } from './core/interface/core.interface';
import { BehaviorSubject } from 'rxjs/Rx';
import { EventEmitter } from '@angular/core';
import { Map, OrderedMap } from 'immutable';

export class AppGlobal {

  public static treeNodes = OrderedMap<string, TreeNode>();
  public static treeActivities = OrderedMap<string, TreeActivity>();
  public static filterCache = [];
  public static filterNodesCache = {};

  public static sourceTreeNode = Map<string, TreeNode>();

  /* Debug */
  public debug = false;

  /* Dashboard */
  public dashboardHidden = new BehaviorSubject<string[]>([]);
  public dashboardFullscreen = new BehaviorSubject<string>(undefined);

  /* Table button trigger */
  public tableButtonClickedEvent: string;
  public tableButtonClickedTrigger = new EventEmitter<{ id: string, treeNode: TreeNode }>();

  /* Table hide messages */
  public tableHideMessages: CoreWidgetMessage[];

  /* Global filter hierarchy */
  public globalFilterHierarchy = new BehaviorSubject<boolean>(true);

  /* Global filter hierarchy tree nodes */
  public globalFilterHierarchyIds = new BehaviorSubject<string[]>([]);

  /* Global filter hierarchy tree nodes */
  public globalFilterSelectedIds = new BehaviorSubject<string[]>([]);

  /* Global filter chained settings */
  public globalFilterChainedFilters: string[] = [];
  public filterByFieldWidgets: string[] = [];

  /* Global filter use subtract */
  public globalFilterUseSubtract = new BehaviorSubject<string[]>([]);

  /* Selected file */
  public existingFileSelected: TreeNode;

  /* Model cache */
  public modelCache = {};

  /* Widget map */
  public widgetMap: Map<string, CoreWidget>;
}
